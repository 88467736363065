import React, { useEffect, useState } from "react";
import { Anchor, Box, Button, Header, Text } from "grommet";
import { Next } from "grommet-icons";

import getMasterDB from "../../database/getMasterDB";
import { BackBox, NotificationsBox } from "../../components/Header/Header";
import SearchComponent from "../../components/Search/Search";
import NavFooter from "../../components/navFooter/navFooter";

const CompaniesCard = ({ companyData }) => {
  console.log(companyData);
  return (
    <Anchor href={`/edit-client-company/${companyData.code}`}>
      <Box
        pad="medium"
        direction="row"
        justify="between"
        fill="horizontal"
        elevation="xsmall"
      >
        <Box>{companyData.name}</Box>
        <Box>
          <Next margin="auto" size="medium" />
        </Box>
      </Box>
    </Anchor>
  );
};

const MyClientList = (props) => {
  const [allClients, setAllClients] = useState();
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  console.log(allClients);

  useEffect(async () => {
    console.log("Inside useEffect");
    if (!allClients) {
      let masterDB = await getMasterDB();

      console.log("Inside getting complete data");

      let tempAllClients = await masterDB.find({
        selector: {
          type: { $eq: "clientCompany" },
          status: { $ne: "Deleted" },
        },
      });
      tempAllClients = tempAllClients.docs;
      console.log(tempAllClients);

      setAllClients(tempAllClients);
    }
    console.log(allClients);
  }, []);

  return (
    <Box>
      <Header background="brand" pad="small">
        <BackBox />
        <Box>
          <Text>My Clients</Text>
        </Box>
        <SearchComponent
          open={searchOpen}
          setOpen={(value) => setSearchOpen(value)}
          setSearchInput={(value) => setSearchInput(value)}
          searchInput={searchInput}
        />
      </Header>

      {allClients &&
        allClients.map((companyObj, companyIndex) => {
          // The line below escapes regular expression special characters:
          // [ \ ^ $ . | ? * + ( )
          const escapedText = searchInput.replace(
            /[-\\^$*+?.()|[\]{}]/g,
            "\\$&"
          );

          // Create the regular expression with modified value which
          // handles escaping special characters. Without escaping special
          // characters, errors will appear in the console
          const exp = new RegExp(escapedText, "i");

          if (exp.test(companyObj.code) || exp.test(companyObj.name)) {
            return (
              <CompaniesCard key={companyObj.code} companyData={companyObj} />
            );
          }
        })}

      <Button
        primary
        margin="90px"
        href="/add-client-company"
        label="Add Client Company"
        alignSelf="center"
      />
      <NavFooter />
    </Box>
  );
};

export default MyClientList;
