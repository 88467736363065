import React, { useState, useEffect } from "react";
import {
  Box,
  Header,
  Text,
  DropButton,
  CheckBoxGroup,
  DateInput,
  FormField,
  Select,
  InfiniteScroll,
} from "grommet";
import NavFooter from "../../components/navFooter/navFooter";
import { UserAvatarBox, SyncButton } from "../../components/Header/Header";
import getMasterDB from "../../database/getMasterDB";
import { FormDown } from "grommet-icons";
import updateObjProp from "../../components/formComponents/updateObjProp/updateObjProp";
import getRecords from "../../database/getRecords";
import CommissionContainer from "../../components/Commission/CommissionCard";
import SearchComponent from "../../components/Search/Search";

const formatter = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
console.log("hello checking if formatter is working or not");
console.log(formatter.format(1323123.12312312313123123));
function J01Commission() {
  let ownerGroupID = localStorage.getItem("ownerid");
  const [masterDB, setMasterDB] = useState();
  const [originalData, setOriginalData] = useState({
    allLists: {},
    data: {},
  });
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchInput, setSearchInput] = useState();
  const now = new Date();
  const [formData, setFormData] = useState({
    sellerDetails: {},
    buyerDetails: {},
    invStatus: [
      "Payment Pending",
      "PDC received",
      "Payment Complete",
      "Cancelled",
    ],
    dateRange: [
      new Date(
        now.getFullYear() - 1,
        now.getMonth(),
        now.getDate()
      ).toISOString(),
      new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 1
      ).toISOString(),
    ],
  });
  const [changeLog, setChangeLog] = useState({});
  const [invData, setInvData] = useState([]);
  const [ownerGroupData, setOwnerGroupData] = useState([]);
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("");
  }

  const invStatusOption = [
    "Payment Pending",
    "PDC received",
    "Payment Complete",
    "Cancelled",
  ];
  const dateFormat = new Intl.DateTimeFormat(undefined, {
    month: "short",
    day: "numeric",
  });

  useEffect(async () => {
    console.log("inside useEffect");
    console.log({ originalData, formData });
    if (!masterDB) {
      console.log("getting masterDB");
      const tempMasterDB = await getMasterDB();
      setMasterDB(tempMasterDB);
    }
    if (masterDB) {
      const tempOriginalData = await getRecords({
        db: masterDB,
        allLists: true,
      });

      //Create originalData.sellerCodes array
      tempOriginalData.allLists.sellerCodes = (
        tempOriginalData.allLists.scgList || []
      ).concat(
        (tempOriginalData.allLists.bscgList || []).concat(
          (tempOriginalData.allLists.sccList || []).concat(
            tempOriginalData.allLists.bsccList || []
          )
        )
      );

      //Create originalData.buyerCodes array
      tempOriginalData.allLists.buyerCodes = (
        tempOriginalData.allLists.bcgList || []
      ).concat(
        (tempOriginalData.allLists.bscgList || []).concat(
          (tempOriginalData.allLists.bccList || []).concat(
            tempOriginalData.allLists.bsccList || []
          )
        )
      );

      setOriginalData(tempOriginalData);
      //querying the ownergroup data

      await masterDB
        .find({
          selector: {
            _id: {
              $gte: `ownerGroup:${ownerGroupID}_OG_`,
              $lte: `ownerGroup:${ownerGroupID}_OG_z`,
            },
          },
        })
        .then((groupData) => {
          console.log("-------this is owner Group Data------------");
          let tempOwnerGRoupData = groupData.docs;
          // for now taking first company whichever is availble from multiple companies
          console.log(tempOwnerGRoupData);
          console.log(Object.values(tempOwnerGRoupData[0].companiesList)[0]);
          let groupDetails =
            tempOwnerGRoupData[0].companies[
              Object.values(tempOwnerGRoupData[0].companiesList)[0]
            ];
          setOwnerGroupData(groupDetails);
        });

      // query part
      await masterDB
        .find({
          selector: {
            _id: {
              $gte: `invoice:${ownerGroupID}_${formatDate(
                formData.dateRange[0]
              )}`,
              $lte: `invoice:${ownerGroupID}_${formatDate(
                formData.dateRange[1]
              )}`,
            },
            status: {
              $in: [formData.invStatus],
            },
            status: {
              $nin: ["Incomplete"],
            },
          },
        })
        .then((invoiceData) => {
          let tempInvoiceData = invoiceData.docs;
          // console.log(tempInvoiceData);
          if (searchInput && searchInput.length > 0) {
            tempInvoiceData = tempInvoiceData.filter((data) => {
              if (
                RegExp(searchInput, "i").test(
                  data.commission.collectFrom == "Buyer"
                    ? data.buyerDetails && data.buyerDetails.name
                    : data.sellerDetails && data.sellerDetails.name
                )
              ) {
                return data;
              }
            });
          }

          //if buyer code or seller code is selected then it should filter the invoice docs
          if (formData.sellerDetails.code) {
            // console.log("hey you selected seller");
            tempInvoiceData = tempInvoiceData.filter((data) => {
              return data.sellerDetails.code === formData.sellerDetails.code;
            });
          }
          if (formData.buyerDetails.code) {
            // console.log("hey you selected buyer");
            tempInvoiceData = tempInvoiceData.filter((data) => {
              return data.buyerDetails.code === formData.buyerDetails.code;
            });
          }
          //grouping the doc according to the seller

          let tableDetails = {};
          for (let i = 0; i < tempInvoiceData.length; i++) {
            let key =
              tempInvoiceData[i].commission.collectFrom == "Buyer"
                ? tempInvoiceData[i].buyerDetails &&
                  tempInvoiceData[i].buyerDetails.name
                : tempInvoiceData[i].sellerDetails &&
                  tempInvoiceData[i].sellerDetails.name;
            if (key) {
              tableDetails[key] = tableDetails[key] || [];
              tableDetails[key].push(tempInvoiceData[i]);
            } else {
              tableDetails[tempInvoiceData[i].sellerDetails.name] =
                tempInvoiceData[i];
            }
          }
          console.log(tableDetails);
          setInvData([tableDetails]);
        });
    }
  }, [
    masterDB,
    formData.invStatus,
    formData.dateRange,
    formData.buyerDetails.code,
    formData.sellerDetails.code,
    searchInput,
  ]);

  const handleChange = async (inputValue, field) => {
    let newValue = inputValue.value || inputValue.target.value;
    let fieldName =
      (inputValue.currentTarget && inputValue.currentTarget.name) ||
      (inputValue.target && inputValue.target.name) ||
      field;

    console.log({ inputValue, newValue, fieldName });

    let changedData = updateObjProp(
      changeLog,
      formData,
      newValue,
      fieldName,
      originalData
    );

    console.log({ changedData });

    let newFieldName = fieldName.split(".");

    if (fieldName === "invStatus") {
      setFormData({ ...formData, invStatus: newValue });

      setChangeLog({
        ...changedData.changeLog,
        invStatus: newValue,
      });
    } else if (fieldName === "dateRange") {
      setFormData({ ...formData, dateRange: newValue });
      setChangeLog({
        ...changedData.changeLog,
        dateRange: newValue,
      });
    }
    //Handle default
    else {
      setFormData(changedData.formTemp);
      setChangeLog({
        ...changedData.changeLog,
      });
    }
    console.log({ originalData, formData });
  };
  return (
    <Box>
      <Header pad="small" background="brand">
        <UserAvatarBox />
        <Text size="large">Commission</Text>
        {/* <SyncButton /> */}
        <SearchComponent
          open={searchOpen}
          setOpen={(value) => setSearchOpen(value)}
          setSearchInput={(value) => setSearchInput(value)}
          searchInput={searchInput}
        />
      </Header>
      <Box margin="small" elevation="medium" pad="small">
        {/* filters */}
        {/* invoice status */}
        <Box flex direction="row">
          <FormField name="invStatus" margin="small">
            <DropButton
              label="Inv Status"
              dropAlign={{ right: "right", top: "bottom" }}
              dropContent={
                <CheckBoxGroup
                  value={formData.invStatus}
                  onChange={(e) => {
                    handleChange(e, "invStatus");
                  }}
                  options={invStatusOption}
                  pad="small"
                  background={"white"}
                  border={{
                    color: "dark",
                    size: "small",
                    style: "solid",
                  }}
                />
              }
            />
          </FormField>
          {/* invoice Date range */}
          <FormField name="dateRange" margin="small">
            <DateInput
              name="dateRange"
              value={formData.dateRange}
              buttonProps={{
                label: `${dateFormat.format(
                  new Date(formData.dateRange[0])
                )} - ${dateFormat.format(new Date(formData.dateRange[1]))}`,
              }}
              onChange={(e) => {
                handleChange(e, "dateRange");
              }}
              dropProps={{ width: "small  " }}
            />
          </FormField>
        </Box>
      </Box>
      {/* cards */}
      <Box margin={{ bottom: "large" }}>
        {invData[0] ? (
          <Box margin={{ top: "medium", bottom: "xlarge" }}>
            <InfiniteScroll items={invData}>
              {(items, i) => (
                <CommissionContainer
                  key={i}
                  invData={items}
                  ownerGroupData={ownerGroupData}
                />
              )}
            </InfiniteScroll>
          </Box>
        ) : (
          ""
        )}
      </Box>
      <NavFooter />
    </Box>
  );
}

export default J01Commission;
