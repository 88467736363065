import React, { useState, useRef } from "react";
import { Box, Text, InfiniteScroll, Button } from "grommet";
import { FormUp, FormDown, Add } from "grommet-icons";
import ReactToPrint from "react-to-print";
import CommissionPdf from "./CommissionPdf";

function CommissionContainer(props) {
  const { invData, ownerGroupData } = props;
  let sellerArray = Object.keys(invData);
  //console.log({ invData, sellerArray });
  return (
    <InfiniteScroll items={sellerArray}>
      {(supplierName, i) =>
        invData[supplierName] ? (
          <CommissionCard
            key={i}
            supplierName={supplierName}
            invoiceDoc={invData[supplierName]}
            ownerGroupData={ownerGroupData}
          />
        ) : null
      }
    </InfiniteScroll>
  );
}

const CommissionCard = (props) => {
  const { supplierName, invoiceDoc, ownerGroupData } = props;
  const tableData = [];
  let statementDate = [];
  let dataObj = {
    commissionAmount: 0,
    tdsAmt: 0,
    finalAmt: 0,
    totalSales: "As per Attachment",
  };
  // console.log("====ownergroup Details======");
  // console.log(ownerGroupData);
  const [moreDetails, setMoreDetails] = useState(false);
  const componentRef = useRef();
  console.log("this is invoice Data");
  console.log({ invoiceDoc });

  invoiceDoc.map((invoice, i) => {
    console.log("this is invoice Data");
    console.log(invoice);
    let tempParsedData = {
      Date: invoice.invoiceDate,
      InvoiceId: invoice._id,
      InvoiceNumber: invoice.invoiceNumber,
      totalInvAmt: invoice.grandTotals.grandTotalInvoiceAmount,
      Days: invoice.invoiceDate,
      commissionPercentage:
        (invoice.commission && invoice.commission.percentage) ||
        invoice.commissionPercentage ||
        2,
      commissionAmtOption: "Gross Amt",

      CommissionAmt: Math.round(
        parseFloat(
          (invoice.commission && invoice.commission.amount) ||
            invoice.commissionAmt
        )
      ),
      collectFrom: invoice.commission.collectFrom || "Seller",
      sellerDetails: invoice.sellerDetails,
      doc: invoice,
    };
    //console.log(tempParsedData.CommissionAmt);
    dataObj.commissionAmount += tempParsedData.CommissionAmt;
    dataObj.finalAmt += tempParsedData.CommissionAmt;

    tableData.push(tempParsedData);
    let tempObj = {};
    tempObj = {
      key: i + 1,
      invoiceNumber: invoice.invoiceNumber,
      invoiceDate: new Date(invoice.invoiceDate).toLocaleDateString("en-IN"),
      buyerName: invoice.buyerDetails && invoice.buyerDetails.name,
      invTotalAmt: parseFloat(
        invoice.grandTotals && invoice.grandTotals.grandTotalInvoiceAmount
      ),
      receivedAmt:
        parseFloat(invoice.grandTotals.grandTotalInvoiceAmount) -
          parseFloat(invoice.pendingPayment) || 0,
      paymentDate: invoice.paymentDate
        ? new Date(invoice.paymentDate).toLocaleDateString("en-IN")
        : new Date().toLocaleDateString("en-IN"),
      paymentNumber: invoice.paymentNumbers && invoice.paymentNumbers.length,
      commissionPer: invoice.commission.percentage || 0,
      commissionAmt:
        parseFloat(
          (invoice.commission && invoice.commission.amount) ||
            invoice.commissionAmt
        ) || 0,
    };
    statementDate.push(tempObj);
  });
  //console.log(tableData);
  //console.log(dataObj);
  //console.log(statementDate);
  return (
    <Box elevation="small" margin="small">
      <Box
        direction="row"
        round="5px"
        onClick={() => setMoreDetails((moreDetails) => !moreDetails)}
      >
        <Text margin="small" alignSelf="start">
          {supplierName}
        </Text>

        <Box flex direction="row" margin="small" justify="end">
          <ReactToPrint
            trigger={() => (
              <Button
                margin="small"
                alignSelf="end"
                flex
                direction="row"
                color="brand"
              >
                <Add size="small" /> Invoice
              </Button>
            )}
            content={() => componentRef.current}
          />
          <div style={{ display: "none" }}>
            <CommissionPdf
              tableData={tableData}
              ref={componentRef}
              dataObj={dataObj}
              statementDate={statementDate}
              ownerGroupData={ownerGroupData}
            />
          </div>
          {!moreDetails && <FormDown />}
          {moreDetails && <FormUp />}
        </Box>
      </Box>
      {moreDetails && (
        <InfiniteScroll items={tableData}>
          {(data, i) => (
            <Box key={i} flex direction="column" pad="medium" border="top">
              <Box flex direction="row">
                <Box flex direction="column">
                  <Text>
                    Inv:
                    {data.InvoiceNumber}
                  </Text>
                  <Text margin={{ vertical: "3px" }}>
                    Date:
                    {[
                      ("0" + new Date(data.Date).getDate()).slice(-2),
                      ("0" + (new Date(data.Date).getMonth() + 1)).slice(-2),
                      ("0" + new Date(data.Date).getFullYear()).slice(-2),
                    ].join("/")}
                  </Text>
                </Box>
                <Text>
                  {data.CommissionAmt ? (
                    <Text>₹{Math.round(data.CommissionAmt)}</Text>
                  ) : (
                    // `Commsn Amt: ${data.CommissionAmt.toLocaleString(
                    //     "en-IN",
                    //     {
                    //       style: "currency",
                    //       currency: "INR",
                    //     }
                    //   )}`
                    ""
                  )}
                </Text>
              </Box>
              <Box flex direction="row">
                <Box flex direction="column">
                  <Text>
                    Inv Amt:
                    {Math.round(data.totalInvAmt)}
                    {/* {(
                      data.totalInvAmt 
                    ).toLocaleString("en-IN", {
                      style: "currency",
                      currency: "INR",
                    })} */}
                  </Text>
                </Box>
                <Text>
                  {data.commissionPercentage
                    ? `${data.commissionPercentage}% on ${data.commissionAmtOption} `
                    : ""}
                </Text>
              </Box>
            </Box>
          )}
        </InfiniteScroll>
      )}
    </Box>
  );
};

export default CommissionContainer;
