import React from "react";
import { Box, FormField, Select, TextInput } from "grommet";
import { currencyOptions, uomOptions } from "../../../constants";
import { decimalNumberValidation } from "../../../database/inputValidations";
const RateInput = (props) => {
  //Inputs --> name, currencyValue, numeralValue, uomValue, handleChange, numberLabel

  return (
    <Box direction="row">
      <FormField name={props.name + ".currency"} label="Currency">
        <Select
          name={props.name + ".currency"}
          options={currencyOptions}
          value={props.currencyValue}
          onChange={props.handleChange}
          margin={{ right: "small" }}
        />
      </FormField>
      <FormField
        name={props.name + ".numerals"}
        label={props.numberLabel || "Qty"}
        validate={(fieldInput, formInputs) => {
          return decimalNumberValidation(fieldInput, formInputs, 10);
        }}
      >
        <TextInput
          type="number"
          name={props.name + ".numerals"}
          value={props.numeralValue}
          onChange={props.handleChange}
        />
      </FormField>
      <FormField name={props.name + ".uom"} label="per">
        <Select
          name={props.name + ".uom"}
          options={uomOptions}
          value={props.uomValue}
          onChange={props.handleChange}
          margin={{ left: "small" }}
        />
      </FormField>
    </Box>
  );
};

export default RateInput;
