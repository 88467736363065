import React from "react";
import { Box, FormField, Text, TextInput } from "grommet";
import { numStringValidation } from "../../database/inputValidations";
//Takes Inputs --> name, onChange, nameValue, branchValue, ifscCode.value, accountNumberValue

const BankDetails = (props) => {
  return (
    <Box>
      <Text>
        <strong>Bank Details</strong>
      </Text>
      <Box>
        <FormField name={`${props.name}.name`} label="Bank Name">
          <TextInput
            name={`${props.name}.name`}
            value={props.nameValue}
            onChange={props.onChange}
          />
        </FormField>
        <FormField name={`${props.name}.branch`} label="Bank Branch">
          <TextInput
            name={`${props.name}.branch`}
            value={props.branchValue}
            onChange={props.onChange}
          />
        </FormField>
        <FormField
          name={`${props.name}.ifscCode`}
          label="IFSC Code"
          validate={[
            {
              regexp: /^[A-Z]{4}0[A-Z0-9]{6}$/,
            },
          ]}
        >
          <TextInput
            name={`${props.name}.ifscCode`}
            value={props.ifscCodeValue}
            onChange={props.onChange}
          />
        </FormField>
        <FormField
          name={`${props.name}.accountNumber`}
          label="Account Number"
          validate={(fieldInputs, formInputs) => {
            return numStringValidation(fieldInputs, formInputs);
          }}
        >
          <TextInput
            name={`${props.name}.accountNumber`}
            value={props.accountNumberValue}
            onChange={props.onChange}
          />
        </FormField>
      </Box>
    </Box>
  );
};

export default BankDetails;
