import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CheckBoxGroup,
  DropButton,
  Text,
  Stack,
  Avatar,
} from "grommet";
import { Filter, LinkPrevious, Notification, UserManager } from "grommet-icons";
import { navigate } from "@reach/router";
import ProfileLayer from "../../pages/d00profile/D01ProfileImage";

import bannerLogo from "../../logo_banner_png_192.png";
import getMasterDB from "../../database/getMasterDB";

import {
  Achievement,
  ContactInfo,
  Checkmark,
  Home,
  LineChart,
  Sync,
} from "grommet-icons";
import * as Styled from "./styles";

export const LogoBox = () => (
  <Styled.LogoImage fill="vertical" src={bannerLogo} />
);

export const UserAvatarBox = (props) => {
  const [profileLayerState, setProfileLayerState] = useState();

  return (
    <Box round="full" background="white" overflow="hidden" elevation="small">
      {profileLayerState ? (
        <ProfileLayer onClose={() => setProfileLayerState(null)} />
      ) : null}
      <Button
        plain
        margin="xsmall"
        icon={<UserManager margin="small" />}
        onClick={() => {
          setProfileLayerState((profileLayerState) =>
            profileLayerState ? null : "active"
          );
        }}
      />
    </Box>
  );
};
export const NotificationsBox = (props) => {
  const [masterDB, setMasterDB] = useState();
  const [notificationDB, setNotificationDB] = useState();
  const [notifiCount, setNotifiCount] = useState();

  useEffect(async () => {
    if (!masterDB) {
      let tempMasterDB = await getMasterDB();
      setMasterDB(tempMasterDB);
    }
    if (masterDB) {
      let tempNotificationDB = await masterDB.find({
        selector: {
          type: {
            $eq: "notification",
          },
        },
      });
      tempNotificationDB = tempNotificationDB.docs[0];
      console.log(tempNotificationDB);
      if (!tempNotificationDB.lastSeenOn) {
        tempNotificationDB.lastSeenOn = [];
      }
      let tempNotification = [];
      tempNotificationDB.notification.map((data) => {
        if (data.timestamp >= tempNotificationDB.lastSeenOn[0]) {
          tempNotification.push(data);
        }
      });

      console.log(tempNotification.length);
      setNotifiCount(tempNotification.length);
      setNotificationDB(tempNotificationDB);
      await masterDB
        .changes({ since: "now", live: true, include_docs: true })
        .on("change", async (change) => {
          console.log(change);
          await getMasterDB("sync");
        });
    }
  }, [masterDB]);
  const lastSeenUpdate = async () => {
    let now = new Date().toISOString();
    if (notificationDB) {
      notificationDB.lastSeenOn.unshift(now);
      await masterDB.put(notificationDB);
    }
  };
  return (
    <Stack
      anchor="top-right"
      onClick={() => {
        lastSeenUpdate();
        navigate("/my-notification");
      }}
    >
      <Avatar>
        <Notification margin="small" color="white" />
      </Avatar>
      <Box pad="xsmall" round="full" background="red" responsive={false}>
        <Text size="xsmall">{notifiCount}</Text>
      </Box>
    </Stack>
  );
};

export const BackBox = (props) => (
  <Box round="full" background="brand" overflow="hidden" elevation="small">
    <Button
      plain
      margin="xsmall"
      icon={<LinkPrevious margin="small" />}
      onClick={() => navigate(-1)}
    />
  </Box>
);

export const NavBox = ({ navIconBox }) => {
  switch (navIconBox) {
    case "logoBox":
      return <LogoBox />;
      break;
    case "userAvatarBox":
      return <UserAvatarBox />;
      break;
    case "notificationsBox":
      return <NotificationsBox />;
      break;
    case "backBox":
      return <BackBox />;
      break;
  }
};

export const Header = (props) => {
  // console.log({navigate});

  return (
    <Box
      background={{ color: "brand", dark: false }}
      pad="small"
      elevation="small"
      fill="horizontal"
      justify="between"
      responsive
      direction="row"
      tag="header"
      align="center"
    >
      <NavBox navIconBox={props.navLeft} />
      {props.title && (
        <Box>
          <Text color="white">{props.title}</Text>
        </Box>
      )}
      <NavBox navIconBox={props.navRight} />
    </Box>
  );
};

export const SyncButton = () => {
  const handleSync = async () => {
    const masterDB = await getMasterDB("sync");
  };

  return (
    <Box round="full" background="brand" overflow="hidden" elevation="small">
      <Button
        plain
        margin="xsmall"
        icon={<Sync margin="small" onClick={handleSync} />}
      />
    </Box>
  );
};

export const HeaderFilter = (props) => {
  console.log({ props });
  const { handleFilter, checkBoxValue, filterOptions } = props;

  return (
    <DropButton
      label={<Filter />}
      dropAlign={{ right: "right", top: "bottom" }}
      dropContent={
        <CheckBoxGroup
          value={checkBoxValue}
          onChange={(event) => {
            console.log(event);
            handleFilter(event.value);
          }}
          options={filterOptions}
          pad="small"
          background={"white"}
          border={{
            color: "dark",
            size: "small",
            style: "solid",
          }}
        />
      }
    />
  );
};
