import React, { useState } from "react";
import { Box, Button, Keyboard, TextInput, Select } from "grommet";
import { Search } from "grommet-icons";

function SearchComponent({ open, setOpen, setSearchInput, searchInput }) {
  const inputRef = React.createRef();

  React.useEffect(() => {
    if (inputRef.current && open) {
      inputRef.current.focus();
    }
  }, [open]);

  if (open) {
    return (
      <Select
        ref={inputRef}
        name="search-components"
        // dropHeight="medium"
        dropAlign={{ top: "bottom", right: "right" }}
        placeholder="search..."
        options={[]}
        onSearch={onchange}
        // onChange={onChange}
        onBlur={() => {
          //   setSearchInput();
          setOpen(false);
        }}
        // onSuggestionsOpen={() => {
        //   setOpen(true);
        // }}
        // onSuggestionsClose={() => {
        //   setOpen(false);
        // }}
      />
    );
  }
  return (
    <Box width="xxsmall">
      <Select
        plain
        ref={inputRef}
        name="search-components"
        dropAlign={{ top: "bottom", right: "right" }}
        options={[]}
        value={searchInput}
        onSearch={(value) => {
          setSearchInput(value);
        }}
        emptySearchMessage={<Box height="1px" margin="0px" pad="0px"></Box>}
        dropHeight="xsmall"
        margin="0px"
        onBlur={() => {
          setOpen(false);
        }}
        icon={<Search />}
      />
    </Box>
  );
}

export default SearchComponent;
