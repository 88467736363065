import React from "react";
import { Box, FormField, Select, Text, TextInput } from "grommet";
import { numberValidation } from "../../database/inputValidations";

const PaymentPeriod = (props) => {
  //Takes inputs --> numeralsValue, unitValue, onChange, name, label

  return (
    <Box>
      <Text>{props.label}</Text>
      <Box direction="row">
        <FormField
          name={`${props.name}.numerals`}
          // validate={(fieldInputs, formInputs) => {
          //     console.log(fieldInputs, formInputs)
          //     return numberValidation(fieldInputs, formInputs)
          // }}
        >
          <TextInput
            type="number"
            name={`${props.name}.numerals`}
            value={props.numeralsValue}
            onChange={props.onChange}
            margin={{
              right: "small",
            }}
          />
        </FormField>
        <FormField
          name={`${props.name}.unit`}
          margin={{
            left: "10px",
          }}
        >
          <Select
            name={`${props.name}.unit`}
            options={["Days", "Weeks", "Months"]}
            value={props.unitValue}
            onChange={props.onChange}
          />
        </FormField>
      </Box>
    </Box>
  );
};

export default PaymentPeriod;
