import React from "react";
import { Box, Text } from "grommet";
import { Alert, Close, Notes, StatusCritical } from "grommet-icons";
import { navigate } from "@reach/router";

const InvoiceCard = (props) => {
  // console.log(props);
  const { invoiceData } = props;

  return invoiceData.status == "OCR failed" ? (
    <Box flex direction="row" align="center" pad="small" elevation="large">
      <Box pad="xxsmall">
        <StatusCritical size="large" color="red" />
      </Box>
      <Text>File {invoiceData.uploadedFileName} failed to upload</Text>
    </Box>
  ) : (
    <Box flex direction="column" pad="small" elevation="large">
      <Box flex direction="row" margin="small">
        <Box flex direction="column">
          <Text>Seller</Text>
          <Text>
            {invoiceData.buyerDetails && invoiceData.sellerDetails.name}
          </Text>
        </Box>

        <Box flex direction="column" align="end">
          <Text>Buyer</Text>
          <Text>
            {invoiceData.buyerDetails && invoiceData.buyerDetails.name}
          </Text>
        </Box>
      </Box>

      <Box flex direction="row" justify="between" align="center">
        <Box flex direction="row" align="center">
          {invoiceData.status == "incomplete" && (
            <Box>
              <Alert size="large" color="red" />
            </Box>
          )}

          <Box margin="small">
            <Text>
              Inv. No.: {invoiceData.invoiceNumber} <br />
              Inv. Date:{" "}
              {new Date(invoiceData.invoiceDate).toLocaleDateString("default")}
            </Text>
          </Box>
        </Box>
        <Box
          align="center"
          pad="small"
          round="small"
          justify="center"
          style={{ background: "linear-gradient(136.68deg, #9FC9EB, #6699D2)" }}
          onClick={() => {
            navigate("/invoice/edit-invoice/", {
              state: { invoiceId: invoiceData._id },
            });
          }}
        >
          <Notes size="medium" color="white" />
          <Text color="white">Edit</Text>
        </Box>
      </Box>
    </Box>
  );
};

export default InvoiceCard;
