import PouchDB from "pouchdb-browser";
import { COUCH_URL } from "../constants";

import pouchAuth from "pouchdb-authentication";
import pouchFind from "pouchdb-find";
PouchDB.plugin(pouchAuth);
PouchDB.plugin(pouchFind); //So we can use the find functionality everywhere.

// export default async function getUserDB(input) {

// import publicIp from "public-ip";

// export const getClientIp = async () => await publicIp.v4({
//   fallbackUrls: [ "https://ifconfig.co/ip" ]
// });

const remoteDB = new PouchDB(`${COUCH_URL}/_users`, { skip_setup: true });

console.log(remoteDB);

const getUserDB = async (input) => {
  try {
    if (input && input.userEmail && input.userPass) {
      let output;

      await remoteDB
        .login(input.userEmail, input.userPass)
        .then((response) => {
          console.log(response);
          output = response;
        })
        .catch((err) => {
          output = err;
        });

      await remoteDB.get(
        `org.couchdb.user:${output.name}`,
        { include_docs: true },
        function (err, doc) {
          console.log(doc);
          console.log(err);
          output = doc;
        }
      );

      console.log({ output, input });
      if (output.name === input.userEmail) {
        localStorage.setItem("user", output.name);
        // console.log("getUserDBbefore user")
        const user = await remoteDB.getUser(output.name);
        // console.log("getUserDB after user")
        localStorage.setItem("ownerid", user.ownerGroupID);
        localStorage.setItem("username", user.userName);
        localStorage.setItem("userid", user.userID);
        localStorage.setItem("usercode", user.userCode);
        // console.log({localStorage});
      }
      // console.log("getuserdb before return")
      return output;
    } else {
      // console.log("else window", remoteDB)
      return remoteDB;
    }
  } catch (err) {
    console.error(err);
    return err;
  }
};

export default getUserDB;
