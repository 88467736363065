import React, { useEffect, useState } from "react";
import { Anchor, Box, Button, Header, Text } from "grommet";

import { FormView, Next } from "grommet-icons";

import { BackBox, NotificationsBox } from "../Header/Header";
import getMasterDB from "../../database/getMasterDB";
import getRecords from "../../database/getRecords";
import { navigate } from "@reach/router";
const OtherChargesCard = (props) => {
  // console.log(props);
  return (
    <Box
      pad="medium"
      direction="row"
      justify="between"
      fill="horizontal"
      elevation="xsmall"
      onClick={() => {
        navigate("/settings/edit-other-charge/", {
          state: { data: props.otherChargesData, index:props.index },
        });
      }}
    >
      <Box>
        <Text>{props.otherChargesData.chargeLabel}</Text>
      </Box>
      <Box justify="center">
        <Next margin="auto" size="medium" />
      </Box>
    </Box>
  );
};

export const OtherCharges = (props) => {
  try {
    const [groupData, setGroupData] = useState();

    useEffect(async () => {
      if (!groupData) {
        let tempMasterDB = await getMasterDB();
        let tempGroupData = await getRecords({
          db: tempMasterDB,
          allLists: false,
          type: "ownerGroup",
        });
        console.log({ tempGroupData });
        setGroupData(tempGroupData.data.groupSettings);
      }
    });

    return (
      <Box fill>
        <Box overflow="auto" fill>
          <Header background="brand" pad="small">
            <BackBox />
            <Box>
              <Text color="white">Add Other Charges</Text>
            </Box>
            <NotificationsBox />
          </Header>
          {groupData &&
            groupData.invoiceOtherCharges &&
            groupData.invoiceOtherCharges.map((data, i) => {
              console.log(data);
              return <OtherChargesCard key={i} otherChargesData={data} index={i} />;
            })}
          <Anchor href="/settings/add-other-charge" bottom="0">
            <Button
              margin={{ top: "small" }}
              primary
              fill="horizontal"
              label="Add New Charge"
              bottom="0"
            />
          </Anchor>
        </Box>
      </Box>
    );
  } catch (err) {
    console.error(err);
    return err;
  }
};

export default OtherCharges;
