import React, { useState } from "react";
import { Box, Form, FormField, Select, Text, TextInput } from "grommet";

import { Phone } from "grommet-icons";

import {
  isdCodeValidation,
  numberValidation,
} from "../../database/inputValidations";
import { countryCodes } from "../../constants";

const PhoneNumber = (props) => {
  //inputs needed label, name, required, onChange, isdCodeValue, phoneNumberValue, disabled
  // console.log(props);
  return (
    <Box>
      <Text>{props.label}</Text>

      <Box direction="row">
        <Box width="small">
          <Select
            options={countryCodes}
            name={props.name + ".isdCode"}
            margin={{ right: "small" }}
            value={props.isdCodeValue || "+91"}
            onChange={props.onChange}
            disabled={props.disabled}
          />
        </Box>
        <FormField
          name={props.name + ".phoneNumber"}
          validate={[
            // regex for checking phone numbers

            (number) => {
              if (number && (number.length > 10 || number.length < 10)) {
                return "Please Enter Valid Phone number";
              }
            },
          ]}
        >
          <TextInput
            icon={<Phone />}
            type="number"
            name={props.name + ".phoneNumber"}
            required={props.required}
            value={props.phoneNumberValue}
            onChange={props.onChange}
            disabled={props.disabled}
          />
        </FormField>
      </Box>
    </Box>
  );
};

export default PhoneNumber;
