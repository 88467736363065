import React, { useEffect, useState } from "react";
import getMasterDB from "../../database/getMasterDB";
import {
  Box,
  Button,
  Diagram,
  Footer,
  Grid,
  Grommet,
  Header,
  Nav,
  Stack,
  DataChart,
  Text,
  Select,
} from "grommet";

export const SummaryGraph = () => {
  let ownerGroupID = localStorage.getItem("ownerid");

  // ////console.log("Hello Karan");
  const [graphData, setGraphData] = useState([]);
  // ////console.log(graphData);
  //sample data

  function formatDate(monthCount) {
    var today = new Date();
    var pastDate = new Date().setDate(today.getDate() - 30 * monthCount);
    pastDate = new Date(pastDate);

    // ////console.log(pastDate);
    let pastMonth = "" + (pastDate.getMonth() + 1);
    const pastYear = pastDate.getFullYear();

    if (pastMonth.length < 2) pastMonth = "0" + pastMonth;

    // ////console.log({pastMonth});

    const monthNumeral = [pastYear, pastMonth].join("");
    const monthName = pastDate
      .toLocaleString("default", { month: "long" })
      .substr(0, 3);
    return { monthNumeral, monthName };
  }

  useEffect(async () => {
    let masterDB = await getMasterDB();

    let tempGraphData = [];
    for (let i = 4; i >= 0; i--) {
      let invoiceSum = 0;
      let paymentSum = 0;
      // ////console.log(i);
      const dateCalc = formatDate(i);
      // ////console.log(dateCalc);

      const pastDate = dateCalc.monthNumeral;
      const monthName = dateCalc.monthName;
      //console.log(parseInt(pastDate) + 1, monthName);

      //for invoice
      await masterDB
        .find({
          selector: {
            _id: {
              $gte: `invoice:${ownerGroupID}_${pastDate}`,
              $lte: `invoice:${ownerGroupID}_${parseInt(pastDate) + 1}`,
            },
            status: {
              $nin: ["Incomplete"],
            },
          },
          fields: ["grandTotals.grandTotalInvoiceAmount"],
        })
        .then((invData) => {
          //console.log("------------Invoice data------------");
          //console.log({ invData });
          // ////console.log(temp.docs[0]);
          invData.docs.map((data) => {
            invoiceSum += parseInt(
              isNaN(
                data.grandTotals && data.grandTotals.grandTotalInvoiceAmount
              )
                ? 0
                : data.grandTotals.grandTotalInvoiceAmount
            );
          });
          //  ////console.log(invoiceSum);
        });

      //for payment
      await masterDB
        .find({
          selector: {
            _id: {
              $gte: `payment:${ownerGroupID}_${pastDate}`,
              $lt: `payment:${ownerGroupID}_${parseInt(pastDate) + 1}`,
            },
          },
          fields: ["grandTotalPaymentAmount"],
        })
        .then((pytData) => {
          //console.log("------------Payment Sum------------");
          //console.log({ pytData });
          // ////console.log(temp.docs[0]);
          pytData.docs.map((data) => {
            paymentSum += parseInt(data.grandTotalPaymentAmount);
          });
          //  ////console.log(paymentSum);
        });

      tempGraphData.push({
        month: monthName,
        Invoices: invoiceSum,
        Payments: paymentSum,
      });
    } //end of for
    //console.log("---------graph data--------");
    //console.log(tempGraphData);
    setGraphData(tempGraphData);
    // ////console.log(graphData);
  }, []);

  const data = [];
  for (let i = 0; i < 7; i += 1) {
    data.push({
      date: `2020-07-${((i % 31) + 1).toString().padStart(2, 0)}`,
      usage: Math.floor(Math.abs(Math.sin(i / 2.0) * 100)),
      bonus: Math.floor(Math.abs(Math.cos(i / 2.0) * 100)),
      over: Math.floor(Math.abs(Math.sin(i / 2.0) * 50)),
    });
  }

  return (
    <Box align="center" justify="start" pad="small">
      <Text alignSelf="center" color="brand" size="medium" margin="small">
        Monthly Biz Summary
      </Text>

      {!graphData[0] ? null : (
        // <DataChart
        //   data={graphData}
        //   style={{ overflowX: "hidden", overflowY: "hidden" }}
        //   series={[
        //     {
        //       property: "month",
        //       render: (date) => (
        //         <Text margin={{ horizontal: "xsmall" }}>{date}</Text>
        //       ),
        //     },
        //     "Invoices",
        //     "Payments",
        //   ]}
        //   chart={[
        //     {
        //       property: "Invoices",
        //       type: "bar",
        //       thickness: "xsmall",
        //       // margin: { left: "-6px" },
        //     },
        //     {
        //       property: "Payments",
        //       type: "bar",
        //       thickness: "xsmall",
        //     },
        //   ]}
        //   bounds="align"
        //   axis={{ x: { property: "month", granularity: "medium" }, y: true }}
        //   guide={{ y: true }}
        //   overflow="false"
        //   offset={{ gap: "xxsmall" }}
        //   legend
        // />
        <DataChart
          data={graphData}
          style={{ overflowX: "hidden", overflowY: "hidden" }}
          series={[
            {
              property: "month",
              render: (date) => (
                <Text margin={{ horizontal: "xsmall" }}>{date}</Text>
              ),
            },
            "Invoices",
            "Payments",
          ]}
          chart={[
            {
              property: "Invoices",
              type: "bar",
              thickness: "xsmall",
            },
            {
              property: "Payments",
              type: "bar",
              thickness: "xsmall",
            },
          ]}
          offset={{ gap: "xxsmall" }}
          axis={{ x: { property: "month", granularity: "fine" }, y: true }}
          guide={{ y: true }}
          legend
        />
      )}
    </Box>
  );
};

export default SummaryGraph;
