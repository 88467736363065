import { Box, Header, Form, FormField, CheckBox, Button, Text } from "grommet";
import React, { useState, useRef } from "react";
import CompanyWiseReportPDF from "../../components/reports/CompanyWiseReportPDF";
import GroupWiseReportPdf from "../../components/reports/GroupWiseReportPdf";
import ReactToPrint from "react-to-print";
import {
  BackBox,
  NotificationsBox,
  SyncButton,
} from "../../components/Header/Header";
import NavFooter from "../../components/navFooter/navFooter";
function ReportPdfGenreation(props) {
  const { type, data, groupName } = props.location.state;
  console.log({ type, data });
  const [detailedPdf, setDetailedPdf] = useState(false);
  const [onAccountPdf, setOnAccountPdf] = useState(false);
  const componentRef = useRef();

  return (
    <Box>
      <Header pad="small" background="brand">
        <BackBox />
        <Text size="large">PDF Generation </Text>

        <SyncButton />
      </Header>
      <Box margin={{ vertical: "large", horizontal: "small" }}>
        <Form>
          <FormField margin={{ top: "small" }}>
            <CheckBox
              checked={detailedPdf}
              label="Detailed PDF?"
              onChange={(event) => setDetailedPdf(event.target.checked)}
              disabled
            />
          </FormField>
          <FormField margin={{ top: "medium" }}>
            <CheckBox
              checked={onAccountPdf}
              label="On Account PDF?"
              onChange={(event) => setOnAccountPdf(event.target.checked)}
              disabled
            />
          </FormField>
          <Box align="center">
            <ReactToPrint
              trigger={() => (
                <Button
                  type="submit"
                  align="center"
                  margin="small"
                  label="Generate PDF"
                />
              )}
              content={() => componentRef.current}
            />
          </Box>
        </Form>

        <div style={{ display: "none" }}>
          <Box
            ref={componentRef}
            width={"100%"}
            background="white"
            height={"100%"}
          >
            {type === "group" ? (
              <GroupWiseReportPdf
                detailedPdf={detailedPdf}
                onAccountPdf={onAccountPdf}
                data={data}
                groupName={groupName}
              />
            ) : (
              <CompanyWiseReportPDF
                detailedPdf={detailedPdf}
                onAccountPdf={onAccountPdf}
                data={data}
                from="company"
              />
            )}
          </Box>
        </div>
      </Box>
      <NavFooter />
    </Box>
  );
}

export default ReportPdfGenreation;
