import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Form,
  FormField,
  Header,
  Select,
  Text,
  TextArea,
  TextInput,
} from "grommet";

import { FormUp, FormDown, MailOption, Trash } from "grommet-icons";
import { EmailInput } from "grommet-controls";

import { BackBox, NotificationsBox } from "../../components/Header/Header";
import getMasterDB from "../../database/getMasterDB";
import getRecords from "../../database/getRecords";
import PhoneNumber from "../../components/PhoneNumber/PhoneNumber";
import {
  panValidation,
  userCodeValidation,
  gstNumberValidation,
  numberValidation,
  numStringValidation,
} from "../../database/inputValidations";
import GstnInput from "../../components/formComponents/GstnInput/GstnInput";
import PanInput from "../../components/formComponents/PanInput/PanInput";
import { countryNames } from "../../constants";
import { navigate } from "@reach/router";
import {
  generatePanFromGst,
  generateUserCode,
} from "../../database/generateCodes";

const AddMyCompany = (props) => {
  try {
    const [masterDB, setMasterDB] = useState();
    const [formData, setFormData] = useState({});
    const [originalData, setOriginalData] = useState();
    const [changeLog, setChangeLog] = useState({});
    const [companyID, setCompanyID] = useState(
      "OC" +
        Math.random().toString(36).substr(2, 3) +
        Math.random().toString(36).substr(2, 3)
    );
    const [moreDetails, setMoreDetails] = useState(false);

    useEffect(async () => {
      // console.log("Inside useEffect", formData)

      if (!masterDB) {
        //get the master database
        let tempMasterDB = await getMasterDB();
        setMasterDB(tempMasterDB);
      }

      if (masterDB && !formData.companies) {
        //get the records
        let tempGroupData = await getRecords({
          db: masterDB,
          allLists: false,
          type: "ownerGroup",
        });
        tempGroupData = tempGroupData.data;
        // console.log(props.companyCode, tempGroupData)
        if (props.type === "edit") {
          setCompanyID(tempGroupData.companiesList[props.companyCode]);
          if (!companyID) {
            alert("Cant Find Company");
            navigate("-1");
          }
        } else {
          //If its in Add-My-Company mode - add the companyID
          tempGroupData.companies = {};
          tempGroupData.companies[companyID] = {};
          tempGroupData.companies[companyID].companyID = companyID;
        }
        // console.log({tempGroupData, companyID})
        setFormData(tempGroupData);
      }

      if (!originalData && formData) {
        setOriginalData(formData);
      }
    });

    const updateObjProp = (
      changeTemp,
      formTemp,
      value,
      propPath,
      tempOriginalData
    ) => {
      const [head, ...rest] = propPath.split(".");
      // console.log({formTemp, value, propPath, head, rest});

      if (Array.isArray(formTemp[head])) {
        // console.log(`${head} is Array.`)
        return { error: `${head} is Array.` };
      }

      if (formTemp.error) {
        return { error: `Handle Array.` };
      }

      if (!rest.length) {
        // console.log(formTemp[head]);
        changeTemp[head] = tempOriginalData[head];
        formTemp[head] = value;
      } else {
        formTemp[head] = formTemp[head] || {};
        changeTemp[head] = changeTemp[head] || {};
        tempOriginalData[head] = tempOriginalData[head] || {};

        updateObjProp(
          changeTemp[head],
          formTemp[head],
          value,
          rest.join("."),
          tempOriginalData
        );
      }

      return { formTemp, changeTemp };
    };

    const handleChange = (inputValue) => {
      // console.log(inputValue)
      let newValue = inputValue.value || inputValue.target.value;
      const changedData = updateObjProp(
        changeLog,
        formData,
        newValue,
        inputValue.target.name,
        originalData
      );

      // console.log({changedData});

      if (changedData.error) {
        alert(changedData.error);
      } else {
        //Generate user code when the user name changes only in Add-My-Company
        if (
          props.type !== "edit" &&
          inputValue.target.name === `companies.${companyID}.companyName`
        ) {
          changedData.formTemp.companies[companyID].companyCode =
            generateUserCode(newValue);
          setFormData({
            ...changedData.formTemp,
          });
        }
        //update PAN when GSTN changes.
        else if (changedData.formTemp.companies[companyID].gstNumber) {
          changedData.formTemp.companies[companyID].panNumber =
            generatePanFromGst(
              changedData.formTemp.companies[companyID].gstNumber
            );
          changedData.changeTemp.companies[companyID].panNumber =
            generatePanFromGst(
              changedData.formTemp.companies[companyID].gstNumber
            );
          setFormData(changedData.formTemp);
          setChangeLog(() => ({
            ...changedData.changeTemp,
          }));
        } else {
          setFormData(changedData.formTemp);
          setChangeLog(() => ({
            ...changedData.changeTemp,
          }));
        }
      }
      // console.log({formData, changeLog});
    };

    const handleSubmit = async (formInputs) => {
      // console.log({formData, formInputs});
      formInputs.preventDefault();

      //get latest data
      let latestDoc = await getRecords({
        db: masterDB,
        type: "ownerGroup",
        id: "none",
      });
      latestDoc = latestDoc.data;
      //Check if the version has changed for edit function only
      if (props.type === "edit" && formData._rev !== latestDoc._rev) {
        alert(
          `Data recently updated by ${latestDoc.updateLogs[0].userCode} on ${latestDoc.updateLogs[0].updateTime}. Please sync your database & try again.`
        );
        return undefined;
      }

      //if company code is changed, delete the old value from company list
      if (props.type === "edit" && changeLog.companies[companyID].companyCode) {
        delete latestDoc.companiesList[props.companyCode];
      }

      //Adds or Updates record in companies list - works for both Add & Edit format
      latestDoc.companiesList[formData.companies[companyID].companyCode] =
        companyID;

      //Inject form data into companies data
      latestDoc.companies = latestDoc.companies || {};
      // groupData.companies[companyID] = {};
      latestDoc.companies[companyID] = formData.companies[companyID];

      //Add CompanyID field
      latestDoc.companies[companyID].companyID = companyID;

      //Add Status Field
      latestDoc.companies[companyID].status = "Active";

      //update Logs field
      // console.log(latestDoc.updateLogs);
      if (props.type === "edit") {
        latestDoc.updateLogs.unshift({
          updateTime: new Date().toISOString(),
          userID: localStorage.getItem("userid"),
          userCode: localStorage.getItem("usercode"),
          updates: changeLog,
        });
      } else {
        latestDoc.updateLogs.unshift({
          updateTime: new Date().toISOString(),
          userID: localStorage.getItem("userid"),
          userCode: localStorage.getItem("usercode"),
          updates: `Company with id: ${companyID} created`,
        });
      }

      //Save the document
      await masterDB.put(latestDoc).then((res) => {
        // console.log(res);
      });

      //Refresh the form.
      navigate("/my-companies");
    };

    const handleDelete = () => {
      alert("Contact the Administratotr.");
    };

    return (
      <Box>
        <Box flex overflow="auto">
          <Header background="brand" pad="small">
            <BackBox />
            <Box>
              <Text color="white">
                {props.type === "edit" ? "Edit My Company" : "Add My Company"}
              </Text>
            </Box>
            <NotificationsBox />
          </Header>
          <Box pad="small">
            <Form validate="change" id="my-company" onSubmit={handleSubmit}>
              <FormField
                name={`companies.${companyID}.companyName`}
                label="Company Name*"
              >
                <TextInput
                  name={`companies.${companyID}.companyName`}
                  value={
                    formData.companies &&
                    formData.companies[companyID] &&
                    formData.companies[companyID].companyName
                  }
                  onChange={handleChange}
                  required
                />
              </FormField>
              <FormField
                name={`companies.${companyID}.companyEmail`}
                label="Company Email"
              >
                <EmailInput
                  name={`companies.${companyID}.companyEmail`}
                  value={
                    formData.companies &&
                    formData.companies[companyID] &&
                    formData.companies[companyID].companyEmail
                  }
                  onChange={handleChange}
                  icon={<MailOption />}
                />
              </FormField>
              <PhoneNumber
                label="Company Phone Number"
                name={`companies.${companyID}.companyPhone`}
                isdCodeValue={
                  formData.companies &&
                  formData.companies[companyID] &&
                  formData.companies[companyID].companyPhone &&
                  formData.companies[companyID].companyPhone.isdCode
                }
                phoneNumberValue={
                  formData.companies &&
                  formData.companies[companyID] &&
                  formData.companies[companyID].companyPhone &&
                  formData.companies[companyID].companyPhone.phoneNumber
                }
                onChange={handleChange}
                required={false}
              />
              <Box
                margin="small"
                direction="row"
                justify="center"
                onClick={() => setMoreDetails((moreDetails) => !moreDetails)}
              >
                <Text color="brand">Add Company Details</Text>
                {!moreDetails && <FormDown color="brand" />}
                {moreDetails && <FormUp color="brand" />}
              </Box>
              {moreDetails && (
                <Box>
                  <FormField
                    name={`companies.${companyID}.companyCode`}
                    label="Company Code*"
                    validate={(fiedDetails, formDetails) => {
                      return userCodeValidation(fiedDetails, formDetails);
                    }}
                  >
                    <TextInput
                      name={`companies.${companyID}.companyCode`}
                      value={
                        formData.companies &&
                        formData.companies[companyID] &&
                        formData.companies[companyID].companyCode
                      }
                      onChange={handleChange}
                    />
                  </FormField>
                  <FormField
                    name={`companies.${companyID}.gstNumber`}
                    label="Company GST Number"
                    validate={(fieldDetails, formDetails) => {
                      return gstNumberValidation(fieldDetails, formDetails);
                    }}
                  >
                    <GstnInput
                      name={`companies.${companyID}.gstNumber`}
                      value={
                        formData.companies &&
                        formData.companies[companyID] &&
                        formData.companies[companyID].gstNumber
                      }
                      onChange={handleChange}
                    />
                  </FormField>
                  <FormField
                    name={`companies.${companyID}.panNumber`}
                    label="Company PAN Number"
                    validate={(fieldDetails, formDetails) => {
                      return panValidation(fieldDetails, formDetails);
                    }}
                  >
                    <PanInput
                      name={`companies.${companyID}.panNumber`}
                      value={
                        formData.companies &&
                        formData.companies[companyID] &&
                        formData.companies[companyID].panNumber
                      }
                      onChange={handleChange}
                    />
                  </FormField>
                  <FormField
                    name={`companies.${companyID}.country`}
                    label="Country"
                  >
                    <Select
                      name={`companies.${companyID}.country`}
                      options={countryNames}
                      value={
                        formData.companies &&
                        formData.companies[companyID] &&
                        formData.companies[companyID].country
                      }
                      onChange={handleChange}
                    />
                  </FormField>
                  <FormField
                    name={`companies.${companyID}.pinCode`}
                    label="Pincode"
                  >
                    <TextInput
                      name={`companies.${companyID}.pinCode`}
                      validate={(fieldInputs, formInputs) => {
                        return numberValidation(fieldInputs, formInputs);
                      }}
                      value={
                        formData.companies &&
                        formData.companies[companyID] &&
                        formData.companies[companyID].pinCode
                      }
                      onChange={handleChange}
                    />
                  </FormField>
                  <FormField name={`companies.${companyID}.city`} label="City">
                    <TextInput
                      name={`companies.${companyID}.city`}
                      value={
                        formData.companies &&
                        formData.companies[companyID] &&
                        formData.companies[companyID].city
                      }
                      onChange={handleChange}
                    />
                  </FormField>
                  <FormField
                    name={`companies.${companyID}.address`}
                    label="Address"
                  >
                    <TextArea
                      name={`companies.${companyID}.address`}
                      value={
                        formData.companies &&
                        formData.companies[companyID] &&
                        formData.companies[companyID].address
                      }
                      onChange={handleChange}
                    />
                  </FormField>
                  <Box>
                    <Text>
                      <strong>Bank Details</strong>
                    </Text>
                    <Box>
                      <FormField
                        name={`companies.${companyID}.bankDetails.bank1.name`}
                        label="Bank Name"
                      >
                        <TextInput
                          name={`companies.${companyID}.bankDetails.bank1.name`}
                          value={
                            formData.companies &&
                            formData.companies[companyID] &&
                            formData.companies[companyID].bankDetails &&
                            formData.companies[companyID].bankDetails.bank1 &&
                            formData.companies[companyID].bankDetails.bank1.name
                          }
                          onChange={handleChange}
                        />
                      </FormField>
                      <FormField
                        name={`companies.${companyID}.bankDetails.bank1.branch`}
                        label="Bank Branch"
                      >
                        <TextInput
                          name={`companies.${companyID}.bankDetails.bank1.branch`}
                          value={
                            formData.companies &&
                            formData.companies[companyID] &&
                            formData.companies[companyID].bankDetails &&
                            formData.companies[companyID].bankDetails.bank1
                              .branch
                          }
                          onChange={handleChange}
                        />
                      </FormField>
                      <FormField
                        name={`companies.${companyID}.bankDetails.bank1.ifscCode`}
                        label="IFSC Code"
                        validate={(fieldInputs, formInputs) => {
                          return numStringValidation(
                            fieldInputs,
                            formInputs,
                            11
                          );
                        }}
                      >
                        <TextInput
                          name={`companies.${companyID}.bankDetails.bank1.ifscCode`}
                          value={
                            formData.companies &&
                            formData.companies[companyID] &&
                            formData.companies[companyID].bankDetails &&
                            formData.companies[companyID].bankDetails.bank1
                              .ifscCode
                          }
                          onChange={handleChange}
                        />
                      </FormField>
                      <FormField
                        name={`companies.${companyID}.bankDetails.bank1.accountNumber`}
                        label="Account Number"
                        validate={(fieldInputs, formInputs) => {
                          return numStringValidation(fieldInputs, formInputs);
                        }}
                      >
                        <TextInput
                          name={`companies.${companyID}.bankDetails.bank1.accountNumber`}
                          value={
                            formData.companies &&
                            formData.companies[companyID] &&
                            formData.companies[companyID].bankDetails &&
                            formData.companies[companyID].bankDetails.bank1
                              .accountNumber
                          }
                          onChange={handleChange}
                        />
                      </FormField>
                    </Box>
                  </Box>
                  <Box margin="medium" pad="small">
                    <Box
                      direction="row"
                      justify="center"
                      onClick={handleDelete}
                    >
                      <Trash color="#BE2625" />
                      <Text color="#BE2625">&nbsp; Delete Company</Text>
                    </Box>
                  </Box>
                </Box>
              )}
            </Form>
          </Box>
        </Box>
        <Button
          primary
          position="fixed"
          fill="horizontal"
          label="Save"
          bottom="0"
          type="submit"
          form="my-company"
        />
      </Box>
    );
  } catch (err) {
    console.error(err);
  }
};

export default AddMyCompany;
