import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Form,
  FormField,
  Header,
  RadioButtonGroup,
  Select,
  Text,
  TextArea,
  TextInput,
} from "grommet";

import { FormUp, FormDown, MailOption, Trash } from "grommet-icons";
import { EmailInput } from "grommet-controls";

import { BackBox, NotificationsBox } from "../../components/Header/Header";
import getMasterDB from "../../database/getMasterDB";
import getRecords from "../../database/getRecords";
import { navigate } from "@reach/router";

import {
  numberValidation,
  userCodeValidation,
  decimalNumberValidation,
} from "../../database/inputValidations";
const AddOtherCharge = (props) => {
  try {
    const [masterDB, setMasterDB] = useState();
    const [formData, setFormData] = useState(
      props.type == "edit"
        ? { ...props.location.state.data }
        : { chargePercent: "" }
    );
    console.log({ props, formData });

    const handleChange = (inputValue) => {
      // console.log(inputValue)
      let newValue = inputValue.value || inputValue.target.value;
      let fieldName =
        (inputValue.currentTarget && inputValue.currentTarget.name) ||
        (inputValue.target && inputValue.target.name);
      let formTemp = formData;
      console.log({ fieldName, newValue, formTemp, formData });

      //Generate user code when the user name changes only in Add-My-Company
      if (fieldName == "duePeriod.numerals") {
        formTemp.duePeriod = {};
        formTemp.duePeriod.numerals = newValue;
      } else if (fieldName == "duePeriod.uom") {
        formTemp.duePeriod
          ? (formTemp.duePeriod.uom = newValue)
          : (formTemp.duePeriod = {});
        formTemp.duePeriod.uom = newValue;
      } else {
        formTemp[fieldName] = newValue;
        console.log({ formTemp });
      }
      setFormData(formTemp);

      console.log({ formData });
    };

    const handleSubmit = async (formInputs) => {
      // console.log({formData, formInputs});
      formInputs.preventDefault();

      //get latest data
      let masterDB = await getMasterDB();

      let latestDoc = await getRecords({
        db: masterDB,
        type: "ownerGroup",
        id: "none",
      });
      console.log({ latestDoc });
      latestDoc = latestDoc.data;
      if (!latestDoc.groupSettings) {
        latestDoc.groupSettings = {};
        latestDoc.groupSettings.invoiceOtherCharges = [];
      } else if (!latestDoc.groupSettings.invoiceOtherCharges) {
        latestDoc.groupSettings.invoiceOtherCharges = [];
      } else if (props.type == "edit") {
        let chargeIndex = props.location.state.index;
        latestDoc.groupSettings.invoiceOtherCharges[chargeIndex] = formData;
      } else {
        latestDoc.groupSettings &&
          latestDoc.groupSettings.invoiceOtherCharges.unshift(formData);
      }
      console.log(latestDoc);
      //Save the document
      await masterDB.put(latestDoc).then((res) => {
        console.log(res);
      });

      //Refresh the form.
      navigate("/settings");
    };

    const handleDelete = async (formInputs) => {
      //get latest data
      let masterDB = await getMasterDB();

      let latestDoc = await getRecords({
        db: masterDB,
        type: "ownerGroup",
        id: "none",
      });
      console.log({ latestDoc });
      latestDoc = latestDoc.data;

      let chargeIndex = props.location.state.index;
      latestDoc.groupSettings.invoiceOtherCharges.splice([chargeIndex], 1);

      console.log(latestDoc);
      //Save the document
      await masterDB.put(latestDoc).then((res) => {
        console.log(res);
      });

      //Refresh the form.
      navigate("/settings");
    };

    return (
      <Box>
        <Box flex overflow="auto">
          <Header background="brand" pad="small">
            <BackBox />
            <Box>
              <Text color="white">
                {props.type === "edit" ? "Edit charges" : "Add New charges"}
              </Text>
            </Box>
            <NotificationsBox />
          </Header>
          <Box pad="small">
            <Form onSubmit={handleSubmit}>
              <FormField name={`chargeLabel`} label="Charge Label">
                <TextInput
                  name={`chargeLabel`}
                  value={formData.chargeLabel}
                  onChange={handleChange}
                  required
                />
              </FormField>
              <FormField name={`chargeName`} label="Charge Name">
                <TextInput
                  name={`chargeName`}
                  value={formData.chargeName}
                  onChange={handleChange}
                  required
                />
              </FormField>
              <FormField name={`chargeType`} label="Charge Type">
                <Select
                  name={`chargeType`}
                  options={["Discount", "Charge", "Commission", "Tax"]}
                  value={formData.chargeType}
                  onChange={handleChange}
                />
              </FormField>
              <Box direction="row" justify="center">
                <Box
                  background={formData.chargePercent ? "#F6F9F0" : ""}
                  pad="small"
                  round={true}
                >
                  <FormField
                    name="chargePercent"
                    width="xsmall"
                    label="Charge %"
                    margin={{ horizontal: "xsmall" }}
                  >
                    <TextInput
                      type="number"
                      name="chargePercent"
                      placeholder="%"
                      value={formData.chargePercent || ""}
                      onChange={(inputPercent) => {
                        let tempFormData = formData;
                        console.log({ tempFormData });
                        if (tempFormData.chargeAmount) {
                          delete tempFormData.chargeAmount;
                        }
                        tempFormData.chargePercent = Number(
                          Math.round(inputPercent.target.value * 100) / 100
                        );
                        setFormData({ ...tempFormData });
                      }}
                    />
                  </FormField>
                </Box>
                <Box
                  background={formData.chargeAmount ? "#F6F9F0" : ""}
                  pad="small"
                  round={true}
                >
                  <FormField
                    name={`chargeAmount`}
                    label="Charge Amount"
                    margin={{ horizontal: "xsmall" }}
                  >
                    <TextInput
                      name={`chargeAmount`}
                      value={formData.chargeAmount || ""}
                      onChange={(inputAmount) => {
                        let tempFormData = formData;
                        console.log({ tempFormData });
                        if (tempFormData.chargePercent) {
                          delete tempFormData.chargePercent;
                        }
                        tempFormData.chargeAmount = Number(
                          Math.round(inputAmount.target.value * 100) / 100
                        );
                        setFormData({ ...tempFormData });
                      }}
                    />
                  </FormField>
                </Box>
              </Box>
              <Box name={`duePeriod`}>
                <Text>Due Period</Text>
                <Box direction="row" flex>
                  <FormField
                    name={"duePeriod.numerals"}
                    validate={(fieldInput, formInputs) => {
                      console.log({ fieldInput, formInputs });
                      return decimalNumberValidation(
                        fieldInput,
                        formInputs,
                        10
                      );
                    }}
                  >
                    <TextInput
                      type="number"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      name={"duePeriod.numerals"}
                      value={formData.duePeriod && formData.duePeriod.numerals}
                      onChange={handleChange}
                    />
                  </FormField>
                  <FormField margin={{ left: "small" }}>
                    <Select
                      name={"duePeriod.uom"}
                      options={["Days"]}
                      value={formData.duePeriod && formData.duePeriod.uom}
                      onChange={handleChange}
                    />
                  </FormField>
                </Box>
              </Box>
              {props.type === "edit" ? (
                <Box margin="medium">
                  <Box direction="row" justify="center" onClick={handleDelete}>
                    <Trash color="#BE2625" />
                    <Text color="#BE2625">&nbsp; Delete Entry</Text>
                  </Box>
                </Box>
              ) : null}
              <Button
                primary
                fill="horizontal"
                label="Save"
                bottom={0}
                type="submit"
              />
            </Form>
          </Box>
        </Box>
      </Box>
    );
  } catch (err) {
    console.error(err);
  }
};

export default AddOtherCharge;
