import React, { useState } from "react";
import { Box, Button, Form, FormField, FileInput, Layer, Text } from "grommet";
import { Close, StatusGood, FormClose } from "grommet-icons";
import axios from "axios";
import { fileUploadEndpoint } from "../../constants";
import getMasterDB from "../../database/getMasterDB";

const plusLinks = [
  // ["Masters", "/my-masters"],
  ["Buyer/Seller List", "/my-clients-list"],
  ["Sub-Agents List", "/sub-agents-list"],
  ["Add New Invoice", "/invoice/add-new-invoice"],
  ["Add New Payment", "/payment/add-new-payment"],
  ["Add New Dr/Cr Note", "/addDrCrNote"],
];
let ownerGroupID = localStorage.getItem("ownerid");

const containerStyle = {
  paddingBottom: 70,
  right: 0,
  width: "100%",
  height: "100%",
  background: "#2c2c2cbf",
  maxWidth: "454px",
};
const linkStyle = {
  background: "white",
  color: "black",
  marginBottom: 13,
};
const closeButton = {
  position: "relative",
  top: 10,
};
const PlusLayer = ({ onLayerClose }) => {
  const [notificationLayer, setNotificationLayer] = useState({
    open: false,
    status: "",
  });
  const [submitButton, setSubmitButton] = useState(false);
  const onOpen = (status) => {
    setNotificationLayer({ open: true, status: status });

    setTimeout(() => {
      setNotificationLayer({ open: false, status: "" });
      onLayerClose();
    }, 5000);
  };
  const onNotificationClose = () => setNotificationLayer({ open: false });
  const handleSubmit = async (inputs) => {
    let masterDB = await getMasterDB();
    let notificationDB = await masterDB.find({
      selector: {
        type: {
          $eq: "notification",
        },
      },
    });
    notificationDB = notificationDB.docs[0];

    console.log(inputs.value);
    if (inputs.value["file-input"] && inputs.value["file-input"][0].name) {
      const formData = new FormData();
      formData.append("file", inputs.value["file-input"][0]);
      formData.append("fileName", inputs.value["file-input"][0].name);
      formData.append("ownerGroupID", ownerGroupID);
      try {
        setNotificationLayer({ open: true, status: "Uploading file" });

        setSubmitButton(true);
        const res = await axios.post(fileUploadEndpoint, formData);
        console.log(res);

        if (res.data.type == "fileHandling") {
          let statusIcon;
          if (res.status == 200) {
            statusIcon = "✅";
            onOpen("✅ Uploaded successfully");
            setSubmitButton(false);
          } else {
            statusIcon = "❌";
            onOpen("❌ Upload failed");
            setSubmitButton(false);
          }
          let notificationObj = {
            timestamp: new Date().toISOString(),
            data: res.data.data,
            statusIcon: statusIcon,
          };

          notificationDB.notification.unshift(notificationObj);
          await masterDB.put(notificationDB).then((done) => {
            console.log(done);
          });
        }
      } catch (ex) {
        console.log(ex);
        // console.log("File upload failed");
      }
    }
  };

  return (
    <Layer
      animation="slide"
      modal={false}
      plain={true}
      onClickOutside={onLayerClose}
      onEsc={onLayerClose}
      full={true}
      id="layerBox"
    >
      {/* full = "vertical" */}
      <Box
        background="brand"
        justify="between"
        style={containerStyle}
        margin="auto"
      >
        {notificationLayer.open && (
          <Layer
            position="top"
            modal={false}
            margin={{ vertical: "medium", horizontal: "small" }}
            onEsc={onLayerClose}
            responsive={false}
            plain
          >
            <Box
              align="center"
              direction="row"
              gap="small"
              justify="between"
              round="medium"
              elevation="medium"
              pad={{ horizontal: "small" }}
              margin={{ bottom: "large" }}
              background="lightgreen"
            >
              <Box align="center" direction="row" gap="xsmall">
                <Text>{notificationLayer.status}</Text>
              </Box>
              <Button
                icon={<FormClose />}
                onClick={onNotificationClose}
                plain
              />
            </Box>
          </Layer>
        )}
        {/* fill='vertical' */}
        <Box align="end" style={closeButton}>
          <Button icon={<Close size="medium" />} onClick={onLayerClose} />
        </Box>
        <Box align="center">
          <Form align="center" onSubmit={handleSubmit}>
            <FormField label="FileInput" name="file-input" htmlFor="file-input">
              <FileInput
                name="file-input"
                id="file-input"
                onChange={(event, { files }) => {
                  const fileList = files;
                  for (let i = 0; i < fileList.length; i += 1) {
                    const file = fileList[i];
                    console.log(file.name);
                  }
                }}
              />
            </FormField>
            <Button
              align="center"
              label="Submit"
              type="submit"
              disabled={submitButton}
            />
          </Form>
        </Box>
        <Box align="end">
          {plusLinks.map((name, i) => (
            <Button
              style={linkStyle}
              href={name[1]}
              key={i}
              hoverIndicator={{ background: "light-5" }}
            >
              <Box pad={{ horizontal: "medium", vertical: "small" }}>
                <Text size="large">{name[0]}</Text>
              </Box>
            </Button>
          ))}
        </Box>
      </Box>
    </Layer>
  );
};

export default PlusLayer;
