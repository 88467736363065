import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Form,
  FormField,
  Header,
  RadioButtonGroup,
  Select,
  Text,
  TextArea,
  TextInput,
} from "grommet";

import { FormUp, FormDown, MailOption, Trash } from "grommet-icons";
import { EmailInput } from "grommet-controls";

import { BackBox, NotificationsBox } from "../../components/Header/Header";
import getMasterDB from "../../database/getMasterDB";
import getRecords from "../../database/getRecords";
import { navigate } from "@reach/router";

function DefaultSetting() {
  try {
    const [masterDB, setMasterDB] = useState();
    const [formData, setFormData] = useState({
      commission: {},
      invoiceDue: {
        dueNumeral: 0,
        metric: "Days",

        calculateFrom: "Invoice Date",
      },
    });
    useEffect(async () => {
      if (!masterDB) {
        const tempMasterDB = await getMasterDB();
        setMasterDB(tempMasterDB);
        console.log("data got from master");
      } else {
        let ownerGroupDoc = await getRecords({
          db: masterDB,
          type: "ownerGroup",
          id: "none",
        });
        ownerGroupDoc = ownerGroupDoc.data;
        if (ownerGroupDoc.groupSettings.defaultSetting) {
          setFormData(ownerGroupDoc.groupSettings.defaultSetting);
        }
      }
    }, [masterDB]);

    const handleSubmit = async (formInputs) => {
      console.log(formData);
      //   // console.log({formData, formInputs});
      formInputs.preventDefault();

      //   //get latest data

      let latestDoc = await getRecords({
        db: masterDB,
        type: "ownerGroup",
        id: "none",
      });
      console.log({ latestDoc });
      latestDoc = latestDoc.data;
      if (!latestDoc.groupSettings) {
        latestDoc.groupSettings = {};
      }

      latestDoc.groupSettings.defaultSetting = formData;

      console.log(latestDoc);
      //Save the document
      await masterDB.put(latestDoc).then((res) => {
        console.log(res);
      });

      //Refresh the form.
      navigate("/settings");
    };

    return (
      <Box>
        <Box flex overflow="auto">
          <Header background="brand" pad="small">
            <BackBox />
            <Box>
              <Text color="white">Default Setting</Text>
            </Box>
            <NotificationsBox />
          </Header>
          <Box pad="small">
            <Form onSubmit={handleSubmit}>
              <FormField
                name="gstPercentage"
                label="GST Percentage"
                flex
                direction="row"
                justify="between"
              >
                <Box width="small">
                  <TextInput
                    type="number"
                    align="end"
                    name="gstPercentage"
                    placeholder="%"
                    value={formData.gstPercentage || ""}
                    onChange={(inputPercent) => {
                      let tempFormData = formData;
                      console.log({ tempFormData });
                      if (tempFormData.gstPercentage) {
                        delete tempFormData.gstPercentage;
                      }
                      tempFormData.gstPercentage = Number(
                        Math.round(inputPercent.target.value * 100) / 100
                      );
                      setFormData({ ...tempFormData });
                    }}
                  />
                </Box>
              </FormField>
              <Box
                direction="column"
                justify="between"
                border={true}
                pad="xsmall"
              >
                <Text>
                  <strong>Commission Setting</strong>
                </Text>
                <FormField
                  name="commissionPercentage"
                  label="Comm Percentage"
                  flex
                  direction="row"
                  justify="between"
                >
                  <Box width="small">
                    <TextInput
                      type="number"
                      align="end"
                      name="commissionPercentage"
                      placeholder="%"
                      value={
                        (formData.commission &&
                          formData.commission.commissionPercentage) ||
                        ""
                      }
                      onChange={(inputPercent) => {
                        let tempFormData = formData;
                        console.log({ tempFormData });

                        tempFormData.commission.commissionPercentage = Number(
                          Math.round(inputPercent.target.value * 100) / 100
                        );
                        setFormData({ ...tempFormData });
                      }}
                    />
                  </Box>
                </FormField>
                <FormField
                  name="commissionCollectFrom"
                  label="Collect From"
                  flex
                  direction="row"
                  justify="between"
                >
                  <Box width="small">
                    <Select
                      name={`commissionCollectFrom`}
                      options={["Seller", "Buyer"]}
                      value={
                        (formData.commission &&
                          formData.commission.commissionCollectFrom) ||
                        ""
                      }
                      onChange={(inputPercent) => {
                        let tempFormData = formData;
                        console.log({ tempFormData });
                        tempFormData.commission.commissionCollectFrom =
                          inputPercent.target.value;
                        setFormData({ ...tempFormData });
                      }}
                    />
                  </Box>
                </FormField>
                <FormField
                  name="commissionOn"
                  label="Calculated On"
                  flex
                  direction="row"
                  justify="between"
                >
                  <Box width="small">
                    <Select
                      name={`commissionOn`}
                      options={["Gross Amount", "Total Amount"]}
                      value={
                        formData.commission && formData.commission.commissionOn
                      }
                      onChange={(inputPercent) => {
                        let tempFormData = formData;
                        console.log({ tempFormData });

                        tempFormData.commission.commissionOn =
                          inputPercent.target.value;
                        setFormData({ ...tempFormData });
                      }}
                    />
                  </Box>
                </FormField>
              </Box>
              <Box name={`duePeriod`}>
                <Text margin="small">Due Period</Text>
                <Box flex direction="row" align="center">
                  <Box width="xsmall">
                    <FormField width="small" name={"duePeriod.numerals"}>
                      <TextInput
                        type="number"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        name={"duePeriod.numerals"}
                        value={
                          formData.invoiceDue && formData.invoiceDue.dueNumeral
                        }
                        onChange={(inputDueNumeral) => {
                          let tempFormData = formData;
                          console.log({ tempFormData });
                          tempFormData.invoiceDue.dueNumeral = Number(
                            Math.round(inputDueNumeral.target.value * 100) / 100
                          );

                          console.log({ tempFormData });
                          setFormData({ ...tempFormData });
                        }}
                      />
                    </FormField>
                  </Box>
                  <Box width="small" margin={{ horizontal: "xsmall" }}>
                    <FormField name={"duePeriod.dueMetric"}>
                      <Select
                        name={"duePeriod.dueMetric"}
                        flex
                        direction="row"
                        options={["Days", "Week", "Month"]}
                        value={
                          formData.invoiceDue && formData.invoiceDue.metric
                        }
                        onChange={(inputVal) => {
                          let tempFormData = formData;
                          tempFormData.invoiceDue.metric =
                            inputVal.target.value;

                          setFormData({ ...tempFormData });
                        }}
                      />
                    </FormField>
                  </Box>

                  <Text margin={{ bottom: "small", right: "small" }}>from</Text>
                  {/* <Box margin={{ horizontal: "xsmall" }}> */}
                  <FormField name={"invoiceDue.calculateFrom"}>
                    <Select
                      name="calculateFrom"
                      options={["Invoice Date", "Dispatch Date"]}
                      flex
                      direction="row"
                      value={
                        formData.invoiceDue && formData.invoiceDue.calculateFrom
                      }
                      margin={{ left: "xsmall" }}
                      onChange={(inputVal) => {
                        let tempFormData = formData;
                        tempFormData.invoiceDue.calculateFrom =
                          inputVal.target.value;

                        setFormData({ ...tempFormData });
                      }}
                    />
                  </FormField>
                  {/* </Box> */}
                </Box>
              </Box>
              <Button
                primary
                fill="horizontal"
                label="Save"
                margin={{ vertical: "small" }}
                bottom={0}
                type="submit"
              />
            </Form>
          </Box>
        </Box>
      </Box>
    );
  } catch (err) {
    console.error(err);
  }
}

export default DefaultSetting;
