import React from "react";
import { Box, Button, Form, FormField, Image, Anchor, Text } from "grommet";
import { EmailInput, PasswordInput } from "grommet-controls";
import { navigate } from "@reach/router";
import { LinkNext } from "grommet-icons";
import bannerLogo from "../../smart-agent-removebg-preview.png";
import loginImg from "../../images/SignIn.png";
import getUserDB from "../../database/getUserDB";
import getMasterDB from "../../database/getMasterDB";
import errorReporting from "../../exceptions/errorReporting";

const SignIn = (props) => {
  const demo = {
    value: {
      userEmail: "demo@smartagent.one",
      userPass: "pass1234",
    },
  };
  const handleSignIn = (e) => {
    // console.log(e);

    //sanitising the inputs.
    const input = {
      userEmail: e.value.userEmail.toString(),
      userPass: e.value.userPass.toString(),
    };

    const msg = getUserDB(input)
      .then(async (res) => {
        if (res && res.name === input.userEmail) {
          // console.log(res);
          getMasterDB("login"); //Initiating syncing of localDB with masterDB
          navigate(`/`, { state: { isLoggedIn: true } });
        } else {
          errorReporting(res);
          alert(res.message);
          //console.error(res.message);
        }
      })
      .catch((err) => {
        errorReporting(err);
        alert(err);
        console.error(err);
      });
    // console.log(msg);
  };

  return (
    <Box align="center" pad="large">
      <Box width="small" pad="small">
        <Image
          fill="horizontal"
          alignSelf="center"
          fit="contain"
          src={bannerLogo}
          pad="large"
        />
        <Image
          fill="horizontal"
          alignSelf="center"
          fit="contain"
          src={loginImg}
        />
      </Box>
      <Form onSubmit={handleSignIn}>
        <Box margin="small">
          <FormField name="userEmail" label="Your Email Here" margin="small">
            <EmailInput
              id="userEmail"
              name="userEmail"
              focusIndicator={false}
              placeholder="user@gmail.com"
              plain
              required
            />
          </FormField>
          <FormField name="userPass" label="Password" margin="small">
            <PasswordInput
              id="userPass"
              name="userPass"
              placeholder="Password"
              focusIndicator={false}
              plain
              required
            />
          </FormField>

          <Button
            primary
            alignSelf="center"
            id="loginSubmit"
            type="submit"
            label="Login"
            margin="small"
            fill="horizontal"
            size="large"
          />
          <Box flex direction="row" margin="small" alignSelf="center">
            <Anchor
              onClick={() => {
                handleSignIn(demo);
              }}
              margin="small"
            >
              <Text margin={{ right: "small" }}>Demo Account login</Text>

              <LinkNext size="20px" color="brand" />
            </Anchor>
          </Box>
        </Box>
      </Form>
    </Box>
  );
};

export default SignIn;
