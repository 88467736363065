import { Box, Header, InfiniteScroll, Text } from "grommet";
import React, { useEffect, useState } from "react";
import { BackBox, HeaderFilter } from "../../components/Header/Header";
import NavFooter from "../../components/navFooter/navFooter";
import getMasterDB from "../../database/getMasterDB";
import InvoiceCard from "./h02aInvoiceCard";
import SearchComponent from "../../components/Search/Search";

const InvoiceList = () => {
  const [masterDB, setMasterDB] = useState();
  const [allInvoices, setAllInvoices] = useState();
  const [checkBoxValue, setCheckBoxValue] = useState();
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchInput, setSearchInput] = useState();
  let filterOptions = ["Payment Pending", "Paid"];

  useEffect(async () => {
    if (!masterDB) {
      let tempMasterDB = await getMasterDB();
      setMasterDB(tempMasterDB);
    }

    if (masterDB) {
      let tempAllInvoices = await masterDB.find({
        selector: {
          _id: {
            $gt: "invoice",
            $lt: "invoicez",
          },
        },
        sort: [{ _id: "desc" }],
      });
      tempAllInvoices = tempAllInvoices.docs;

      console.log(tempAllInvoices);
      if (searchInput && searchInput.length > 0) {
        tempAllInvoices = tempAllInvoices.filter((data) => {
          if (
            RegExp(searchInput, "i").test(
              data.buyerDetails && data.buyerDetails.name
            ) ||
            RegExp(searchInput, "i").test(
              data.sellerDetails && data.sellerDetails.name
            )
          ) {
            return data;
          }
        });
      }
      setAllInvoices(tempAllInvoices);
    }
  }, [masterDB, searchInput]);

  const handleFilter = (filterChange) => {
    setCheckBoxValue(filterChange);
  };

  return (
    <Box pad={{ bottom: "large" }}>
      <Header background="brand" pad="small">
        <BackBox />
        <Text>My Invoices</Text>
        <Box flex direction="row" justify="end">
          <HeaderFilter
            handleFilter={handleFilter}
            checkBoxValue={checkBoxValue}
            filterOptions={filterOptions}
          />
          <SearchComponent
            open={searchOpen}
            setOpen={(value) => setSearchOpen(value)}
            setSearchInput={(value) => setSearchInput(value)}
            searchInput={searchInput}
          />
        </Box>
      </Header>
      {allInvoices && (
        <InfiniteScroll items={allInvoices}>
          {(invoiceData) => (
            <InvoiceCard invoiceData={invoiceData} key={invoiceData._id} />
          )}
        </InfiniteScroll>
      )}
      <NavFooter />
    </Box>
  );
};

export default InvoiceList;
