import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Diagram,
  Footer,
  Grid,
  Grommet,
  Header,
  Nav,
  Stack,
  DataChart,
  Text,
  Select,
} from "grommet";
import {
  Achievement,
  ContactInfo,
  Checkmark,
  Home,
  LineChart,
  Sync,
} from "grommet-icons";

import {
  UserAvatarBox,
  NotificationsBox,
  SyncButton,
} from "../../components/Header/Header";
import { customTheme } from "../../constants";
import NavFooter from "../../components/navFooter/navFooter";
import ProfileLayer from "../d00profile/D01ProfileImage";
import PlusLayer from "../../pages/c00home/C03Plus";
import getMasterDB from "../../database/getMasterDB";
import LastPayment from "../../components/HomePage/LastPayment";
import LastInvoice from "../../components/HomePage/LastInvoice";
import SummaryGraph from "../../components/HomePage/SummaryGraph";
import MonthlyTotalsWindow from "../../components/MonthlyTotalsWindow";

const HomePage = () => {
  const [payments, setPayments] = useState();

  const StatusIcon = ({ status, gridArea, id }) => {
    status = parseInt(status, 10);
    const backColor = status >= 5 ? "Green" : status <= 0 ? "White" : "Yellow";
    // console.log({status, backColor}, typeof(status));

    return (
      <Box
        round="full"
        background={backColor}
        overflow="hidden"
        border={true}
        pad="xsmall"
        alignSelf="center"
        margin={{
          vertical: "xsmall",
          horizontal: "auto",
        }}
        id={id}
        gridArea={gridArea}
      >
        <Checkmark size="small" color="black" />
      </Box>
    );
  };

  return (
    <Box theme={customTheme}>
      <Box flex overflow="auto" style={{ marginBottom: "20vh" }}>
        <Header pad="small" background="brand">
          <UserAvatarBox />
          {/* <SyncButton /> */}
          <NotificationsBox />
        </Header>
        <MonthlyTotalsWindow />
        {/* chart */}
        <SummaryGraph />
        {/* pending payments list */}
        <LastPayment />
        <LastInvoice />
      </Box>
      <NavFooter />
    </Box>
  );
};

export default HomePage;
