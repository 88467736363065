import React, { useEffect, useState } from 'react';
import { Anchor, Box, Button, Header, Text } from 'grommet';
import { Next } from 'grommet-icons';
import getMasterDB from '../../database/getMasterDB';
import { BackBox, NotificationsBox } from '../../components/Header/Header';
import SearchComponent from '../../components/Search/Search';
import NavFooter from '../../components/navFooter/navFooter';

const CompanyCard = ({companyData}) => {
    console.log(companyData);
    return (
        <Anchor
            href={`/edit-agent-company/${companyData.code}`}
        >
            <Box
                pad="medium"
                direction="row"
                justify="between"
                fill="horizontal"
                elevation="xsmall"
            >
                <Box>
                    {companyData.name}
                </Box>
                <Box>
                    <Next margin="auto" size="medium" />
                </Box>
            </Box>
        </Anchor>
    )
}

const MyAgentList = (props) => {

    const [allAgents, setAllAgents] = useState();
    const [searchOpen, setSearchOpen] = useState(false);
    const [searchInput, setSearchInput] = useState("");

    useEffect(async() => {

        if(!allAgents) {
            let masterDB = await getMasterDB();


            let tempAllAgents = await
                masterDB.find({
                    selector: {
                        type:{$eq: "agentCompany"},
                        status:{$ne: "Deleted"}
                    }
                })

            tempAllAgents = tempAllAgents.docs;


            setAllAgents(tempAllAgents);
        }
    }, []);

    return(
        <Box>
            <Header background="brand" pad="small">
                <BackBox />
                <Box>
                    <Text>My Sub Agents</Text>
                </Box>
                <SearchComponent
                    open={searchOpen}
                    setOpen = {value => setSearchOpen(value)}
                    setSearchInput = {value => setSearchInput(value)}
                    searchInput = {searchInput}
                />
            </Header>
            {allAgents && allAgents.map((agentObj, agentIndex) => {
                // The line below escapes regular expression special characters:
                // [ \ ^ $ . | ? * + ( )
                const escapedText = searchInput.replace(
                    /[-\\^$*+?.()|[\]{}]/g,
                    "\\$&"
                );
        
                // Create the regular expression with modified value which
                // handles escaping special characters. Without escaping special
                // characters, errors will appear in the console
                const exp = new RegExp(escapedText, "i");
                if(exp.test(agentObj.code) || exp.test(agentObj.name)){
                    return <CompanyCard key = {agentIndex} companyData={agentObj} />
                }
            })}
            <Button
                primary
                href="/add-agent-company"
                label="Add New Sub-Agent"
                alignSelf = "center"
                margin="small"
            />
            <NavFooter/>
        </Box>
    )
}

export default MyAgentList;