import { Box, Header, Text } from "grommet";
import React, { useEffect, useState } from "react";
import { UserAvatarBox } from "../../components/Header/Header";
import NavFooter from "../../components/navFooter/navFooter";
import SearchComponent from "../../components/Search/Search";
import getMasterDB from "../../database/getMasterDB";


const NegativeList = () => {

    const [masterDB, setMasterDB] = useState();
    const [negativeListData, setNegativeListData] = useState();
    const [searchOpen, setSearchOpen] = useState(false);
    const [searchInput, setSearchInput] = useState("");

    useEffect (async() => {
        if(!masterDB) {
            let tempMasterDB = await getMasterDB();
            setMasterDB(tempMasterDB);
        }

        if(masterDB && !negativeListData) {
            let tempList = await masterDB.get("negativeList:clientCompanies");
            tempList = tempList.negativeCompaniesDetails;
            setNegativeListData(
                tempList
            );
        }
    }, [masterDB]);

    return(
        <Box>
            <Header pad="small" background="brand">
                <UserAvatarBox />
                <Text size="large">Negative List</Text>
                <SearchComponent
                    open={searchOpen}
                    setOpen={value => setSearchOpen(value)}
                    setSearchInput = {value => setSearchInput(value)}
                    searchInput = {searchInput}
                />
            </Header>
            <Box margin={{bottom: "100px"}}>
            {negativeListData && negativeListData.map(
                (companyObj, companyIndex) => {
                    // The line below escapes regular expression special characters:
                    // [ \ ^ $ . | ? * + ( )
                    const escapedText = searchInput.replace(
                        /[-\\^$*+?.()|[\]{}]/g,
                        "\\$&"
                    );
    
                    // Create the regular expression with modified value which
                    // handles escaping special characters. Without escaping special
                    // characters, errors will appear in the console
                    const exp = new RegExp(escapedText, "i");
                    if (exp.test(companyObj.Company_Name) || exp.test(companyObj.Company_Agent1) || exp.test(companyObj.Company_Agent2) || exp.test(companyObj.Company_GST_Number)){
                        return(
                            <Box key={companyIndex} border={{
                                    color: "black",
                                    size: "xsmall",
                                    style: "solid",
                                    side: "top"
                                }}
                                pad="xsmall"
                            >
                                <Text>{companyObj.Company_Name}, {companyObj.Company_City}</Text>
                                {companyObj.Company_GST_Number &&
                                    <Text>GST: {companyObj.Company_GST_Number}</Text>
                                }
                                {(companyObj.Company_Agent1) && 
                                    <Text>Agent 1: {companyObj.Company_Agent1} </Text>
                                }
                                {(companyObj.Company_Agent2) && 
                                    <Text>Agent 2: {companyObj.Company_Agent2}</Text>
                                }
                                <Text>by {companyObj.Organisation_Name}, {companyObj.Organisation_City}</Text>
                            </Box>
                        )
                    } else {
                        return null
                    }
                }
            )}
            </Box>
            <NavFooter />
        </Box>
    )
};

export default NegativeList;