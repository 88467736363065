import React, { useEffect, useState } from "react";
import { Anchor, Box, Button, Header, Text } from "grommet";

import { FormView, Next } from "grommet-icons";

import { BackBox, NotificationsBox } from "../../components/Header/Header";
import getMasterDB from "../../database/getMasterDB";
import getRecords from "../../database/getRecords";

const MyCompanyCard = (props) => {
  // console.log(props);
  return (
    <Anchor
      href={`/my-companies/edit-my-company/${props.companyData.companyCode}`}
      textDecoration="none"
    >
      <Box
        pad="medium"
        direction="row"
        justify="between"
        fill="horizontal"
        elevation="xsmall"
      >
        <Box>
          <Box>
            <strong>{props.companyData.companyName}</strong>
          </Box>
          <Box>Company Details</Box>
        </Box>
        <Box justify="center">
          <Next margin="auto" size="medium" />
        </Box>
      </Box>
    </Anchor>
  );
};

export const MyCompanies = (props) => {
  try {
    const [groupData, setGroupData] = useState();

    useEffect(async () => {
      if (!groupData) {
        let tempMasterDB = await getMasterDB();
        let tempGroupData = await getRecords({
          db: tempMasterDB,
          allLists: false,
          type: "ownerGroup",
        });
        // console.log({tempGroupData});
        setGroupData(tempGroupData.data);
      }
    });

    return (
      <Box fill>
        <Box overflow="auto" fill>
          <Header background="brand" pad="small">
            <BackBox />
            <Box>
              <Text color="white">My Companies</Text>
            </Box>
            <NotificationsBox />
          </Header>
          {groupData &&
            groupData.companiesList &&
            Object.keys(groupData.companies) &&
            Object.keys(groupData.companies).map((k, i, temp) => {
              // console.log(temp);
              return (
                <MyCompanyCard key={k} companyData={groupData.companies[k]} />
              );
            })}
          <Anchor href="/my-companies/add-my-company" bottom="0">
            <Button
              primary
              fill="horizontal"
              label="Add New Company"
              bottom="0"
            />
          </Anchor>
        </Box>
      </Box>
    );
  } catch (err) {
    console.error(err);
    return err;
  }
};

export default MyCompanies;
