import React from "react";
import { Box, FormField, Select, TextInput, Text } from "grommet";
import { uomOptions } from "../../../constants";
import { decimalNumberValidation } from "../../../database/inputValidations";
const QuantityInput = (props) => {
  //needs name, numeralValue, uomValue, handleChange
  // console.log("Quantity Input", {props})

  return (
    <Box direction="row" flex>
      <FormField
        name={props.name + ".numerals"}
        validate={(fieldInput, formInputs) => {
          console.log({ fieldInput, formInputs });
          return decimalNumberValidation(fieldInput, formInputs, 10);
        }}
      >
        <TextInput
          type="number"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          name={props.name + ".numerals"}
          value={props.numeralValue}
          onChange={props.handleChange}
        />
      </FormField>
      <Select
        name={props.name + ".uom"}
        options={props.uomOptions || uomOptions}
        value={props.uomValue}
        onChange={props.handleChange}
        margin={{ left: "small" }}
      />
    </Box>
  );
};

export default QuantityInput;
