import React from "react";
import { Anchor, Box, Button, Layer, Text } from "grommet";
import { Close, UserManager } from "grommet-icons";
import { Link, navigate } from "@reach/router";
import getUserDB from "../../database/getUserDB";
import getMasterDB from "../../database/getMasterDB";

const links = [
  ["My Profile & Group", "/my-profile"],
  ["My Companies", "/my-companies"],
  ["My Team", "/my-team"],
  ["How it Works", "/how-to"],
  ["Help & Feedback", "/feedback"],
  ["Settings", "/settings"],
  ["Negative List", "/negative-list"]
];

const ProfileLayer = ({ onClose }) => {
  const handleLogout = async () => {
    const masterDB = await getMasterDB("logout");
    const userDB = await getUserDB();
    console.log(masterDB);
    masterDB.destroy();
    await userDB.logout();
    localStorage.clear();
    navigate(`/signin`, { state: { isLoggedIn: false } });
  };

  return (
    <Layer
      animation="slide"
      modal={false}
      plain={true}
      onClickOutside={onClose}
      onEsc={onClose}
      marginTop="small"
      full={true}
    >
      <Box
        background="brand"
        justify="between"
        fill="vertical"
        align="center"
        // pad="large"
        pad={{ bottom: "100px" }}
        style={{maxWidth:"454px", width:"100%", margin:"auto"}}
      >
        <Box fill="horizontal" align="end">
          <Button icon={<Close />} onClick={onClose} />
        </Box>
        <Link to="/my-profile">
          <Button plain margin="xsmall" icon={<UserManager margin="small" />} />
        </Link>
        <Link to="/my-profile">
          <Box border={{ side: "bottom", color: "white" }} pad="medium">
            <Text
              color="white"
              text={{ extend: { "text-decoration": "none" } }}
            >
              {localStorage.getItem("username")}
            </Text>
          </Box>
        </Link>
        {links.map((name) => (
          <Link to={name[1]} key={name[0]}>
            <Button key={name[0]}>
              <Box pad={{ horizontal: "medium", vertical: "small" }}>
                <Text size="large" color="white">
                  {name[0]}
                </Text>
              </Box>
            </Button>
          </Link>
        ))}
        <Button>
          <Box
            pad={{ horizontal: "medium", vertical: "small" }}
            onClick={handleLogout}
          >
            <Text size="large" color="white">
              Logout
            </Text>
          </Box>
        </Button>
      </Box>
    </Layer>
  );
};

export default ProfileLayer;
