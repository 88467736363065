import React, { useState } from "react";
import {
  Anchor,
  Box,
  Button,
  Header,
  Footer,
  Text,
  ThemeContext,
} from "grommet";
import { Add, Currency, Home, LineChart, Notes } from "grommet-icons";
import { useLocation } from "@reach/router";
import PlusLayer from "../../pages/c00home/C03Plus";
import { Close } from "grommet-icons";

const NavFooter = (props) => {
  // console.log(props);

  const location = useLocation();

  const [plusLayer, setPlusLayer] = useState(false);
  const footerBoxStyle = {
    position: "fixed",
    bottom: 0,
    zIndex: 99,
  };
  const NavButtons = ({ iconName }) => {
    // console.log(iconName)
    let BtnIcon;
    let btnColor = "text";
    let borderColor = "none";
    let path;

    if (iconName === "Home") {
      path = "/";
      if (location.pathname === path) {
        btnColor = "brand";
        borderColor = "brand";
      }
      // console.log({ location, borderColor, btnColor });
      BtnIcon = () => <Home color={btnColor} />;
    } else if (iconName === "Invoices") {
      path = "/invoices";
      if (location.pathname === path) {
        btnColor = "brand";
        borderColor = "brand";
      }
      // console.log({ location, borderColor, btnColor });
      BtnIcon = () => <Notes color={btnColor} />;
    } else if (iconName === "Reports") {
      path = "/my-reports";
      if (location.pathname === path) {
        btnColor = "brand";
        borderColor = "brand";
      }
      // console.log({ location, borderColor, btnColor });
      BtnIcon = () => <LineChart color={btnColor} />;
    } else if (iconName === "Comisn") {
      path = "/my-commission";
      if (location.pathname === path) {
        btnColor = "brand";
        borderColor = "brand";
      }
      // console.log({ location, borderColor, btnColor });
      BtnIcon = () => <Currency color={btnColor} />;
    }

    return (
      <Anchor id={"footer_" + iconName} href={path}>
        <Box
          align="center"
          pad="small"
          border={{
            color: borderColor,
            side: "top",
            size: "small",
            style: "ridge",
          }}
          hoverIndicator={{
            elevation: "medium",
          }}
        >
          <BtnIcon />
          <Text color={btnColor}>{iconName}</Text>
        </Box>
      </Anchor>
    );
  };

  return (
    <Box
      fill="horizontal"
      direction="row"
      background="white"
      pad={{
        vertical: "none",
        horizontal: "none",
      }}
      align="center"
      style={{
        position: "fixed",
        bottom: 0,
        width: "100%",
        maxWidth: "454px",
        zIndex: "99",
      }}
      justify="between"
    >
      {plusLayer ? (
        <PlusLayer
          onLayerClose={() => {
            setPlusLayer(null);
          }}
        />
      ) : null}
      <NavButtons iconName="Home" />
      <NavButtons iconName="Invoices" />
      <Box
        background="white"
        round="full"
        pad="xsmall"
        margin={{ top: "-2.5em" }}
        flex={false}
      >
        <Box
          background="brand"
          pad="medium"
          round="full"
          onClick={() => {
            // setPlusLayer(plusLayer => plusLayer? null : "plusButton")
            setPlusLayer(!plusLayer);
          }}
        >
          {!plusLayer ? <Add size="medium" /> : <Close size="medium" />}
        </Box>
      </Box>
      <NavButtons iconName="Reports" />

      <NavButtons iconName="Comisn" background="red" />
    </Box>
  );
};

export default NavFooter;
