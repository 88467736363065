import PouchDB from "pouchdb-browser";
import { COUCH_URL } from "../constants";

import pouchAuth from "pouchdb-authentication";
import pouchFind from "pouchdb-find";
import { Console } from "grommet-icons";
import { emit } from "pouchdb-core";
PouchDB.plugin(pouchAuth);
PouchDB.plugin(pouchFind); //So we can use the find functionality everywhere.

// console.log({input, localDB, remoteDB, dbName})

const getMasterDB = async (input) => {
  try {
    const ownerID = localStorage.getItem("ownerid");
    const dbName = `masters_${ownerID}`;

    // console.log(COUCH_URL);
    // console.log("new localDB")
    let localDB = new PouchDB(dbName, { auto_compaction: true });

    // console.log({localDB});
    // console.log("new remoteDB")
    const remoteDB = new PouchDB(`${COUCH_URL}/${dbName}`, {
      skip_setup: true,
    });

    if (input === "login") {
      // console.log({localDB});

      localDB
        .sync(remoteDB, {
          live: true,
          heartbeat: false,
          timeout: false,
          retry: true,
          // filter: 'app/by_agent',
          // query_params: { "agent": agent }
          // Can use to filter out the deleted docs. More here: https://pouchdb.com/2015/04/05/filtered-replication.html
        })
        .on("change", function (change) {
          console.log({ change });
        })
        .on("paused", function (paused) {
          console.log({ paused });
        })
        .on("active", function (active) {
          console.log({ active });
        })
        .on("complete", function (complete) {
          console.log({ complete });
        })
        .on("denied", function (denied) {
          console.error({ denied });
        })
        .on("error", function ({ err }) {
          console.error(err);
        });
    } else if (input === "logout") {
      // console.log("logout")
      await localDB
        .sync(remoteDB)
        .on("complete", function (res) {
          alert("Database Synced Succesfully");
          // syncHandler.cancel();
          // console.log({res, output})
        })
        .on("error", function (err) {
          alert("Database Syncing Unsuccesfull, You might lose recent changes");
          return err;
        });
      return localDB;
    } else if (input === "sync") {
      await localDB
        .sync(remoteDB)
        .on("complete", function (res) {
          // alert("Database Synced Succesfully");
          // syncHandler.cancel();
          // console.log({res, output})
        })
        .on("error", function (err) {
          // alert("Database Syncing Unsuccesfull, You might lose recent changes");
          return err;
        });
      return localDB;
    } else {
      // console.log("default", output);

      await localDB
        .sync(remoteDB)
        .on("complete", function (res) {
          console.log("Database Synced Succesfully");
          // syncHandler.cancel();
          // console.log({res, output})
        })
        .on("error", function (err) {
          console.log("Database Syncing Unsuccesfull", { err });
        });

      return localDB;
    }
  } catch (err) {
    console.error(err);
    return err;
  }
};

export default getMasterDB;
