import React from "react";
import { Box, FormField, Select, Text, TextInput } from "grommet";
import { NumberInput } from "grommet-controls";
import { decimalNumberValidation } from "../../database/inputValidations";
import { currencyOptions } from "../../constants";

const AmountField = (props) => {
  //Takes inputs --> numeralsValue, currencyValue, onChange, name, label, required, disabled
  // console.log("Amount Field", {props});
  return (
    <Box>
      <Text>{props.label}</Text>
      <Box direction="row">
        <FormField
          name={`${props.name}.currency`}
          margin={{
            right: "small",
          }}
        >
          <Select
            name={`${props.name}.currency`}
            options={currencyOptions}
            value={props.currencyValue}
            onChange={props.onChange}
            required={props.required}
            disabled={props.disabled}
            size="small"
          />
        </FormField>
        <FormField
          name={`${props.name}.amount`}
          // validate={(fieldInputs, formInputs) => {
          //   return decimalNumberValidation(fieldInputs, formInputs);
          // }}
        >
          {/* {console.log((123).toLocaleString("en-IN"))} */}
          <TextInput
            type="number"
            name={`${props.name}.amount`}
            value={Number(props.numeralsValue || 0)}
            onChange={props.onChange}
            required={props.required}
            size="small"
            placeholder={"0.00"}
          />
        </FormField>
      </Box>
    </Box>
  );
};

export default AmountField;
