export const generateUserCode = (inputName) => {
  let filterRegex = /[^a-zA-Z0-9_]/g;
  let userCode = inputName.replace(filterRegex, "");
  if (userCode.length < 6) {
    userCode = userCode.padEnd(
      6,
      Math.random()
        .toString(16)
        .substr(2, 6 - userCode.length)
    );
  }
  userCode = userCode.substr(0, 15);
  // console.log({inputName, userCode});

  return userCode;
};

export const generatePanFromGst = (inputGST) => {
  let panNumber = String(inputGST).substr(2, 10);
  // console.log({inputGST, panNumber});
  return panNumber;
};
