// get the list of payments(type: payment) received in the last 30days.

// get the list of bCC where an invoice is pending & due (above due date). lets call it dueBccList-Array
// check the dueBccList-Array against list of payments received in last 30days.
// import { emit } from 'pouchdb-core';

import React, { useEffect, useState } from "react";
import { Box, Text } from "grommet";
import getMasterDB from "../../database/getMasterDB";
import getRecords from "../../database/getRecords";
import { FormUp, FormDown } from "grommet-icons";

import CardsContainer from "./CallingCard";
import { EmailInput } from "grommet-controls";

const LastPayment = () => {
  let ownerGroupID = localStorage.getItem("ownerid");
  let userCode = localStorage.getItem("usercode");

  const [paymentArray, setPaymentArray] = useState({
    above30dayArray: [],
    above15dayArray: [],
    above7dayArray: [],
  });
  const [masterDB, setMasterDB] = useState();

  // //console.log({ paymentArray });

  let data = {};

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("");
  }
  function sortDates(a, b) {
    return a.getTime() - b.getTime();
  }
  const today = new Date();
  var day7old = new Date(today.setDate(today.getDate() - 6));
  var day15old = new Date(today.setDate(today.getDate() - 15));
  var day30old = new Date(today.setDate(today.getDate() - 30));
  //console.log(day30old.toISOString();
  let tempDate = [today, day7old, day15old, day30old];

  //console.log(tempDate.sort(sortDates);
  useEffect(async () => {
    //console.log("---inside useeffect-------------");
    if (!masterDB) {
      let tempMasterDB = await getMasterDB();
      setMasterDB(tempMasterDB);
    } else {
      const mapReduceFunc = {
        map: function (doc) {
          if (
            doc.type === "invoice" &&
            doc.pendingPayment > 0 &&
            doc.status === "Payment Pending" &&
            doc.invoiceDue.dueDate <
              new Date(
                new Date().setDate(new Date().getDate() - 6)
              ).toISOString()
          ) {
            emit(doc.buyerDetails._id, {
              _id: doc.buyerDetails._id,
              due_date:
                (doc.invoiceDue && doc.invoiceDue.dueDate) || doc.invoiceDate,
              count: 1,
            });
          }
        },
        reduce: function (keys, values) {
          // //console.log({keys, values});
          const min = values.reduce(function (prev, current) {
            return prev.due_date < current.due_date ? prev : current;
          }); //returns object
          return min;
        },
      };

      let tempAllInvoices = await masterDB.query(mapReduceFunc, {
        group: true,
        // include_docs: true,
        group_level: 1,
      });
      tempAllInvoices = tempAllInvoices.rows;
      //console.log(tempAllInvoices);
      let tempPaymentArray = {
        above30dayArray: [],
        above15dayArray: [],
        above7dayArray: [],
      };
      tempAllInvoices.map(async (invoiceGroupData, i) => {
        let clientCompanyData = await masterDB.get(invoiceGroupData.key);

        if (clientCompanyData.lastPaymentDate) {
          //console.log("here it is lastPayment date");

          let decidingDate =
            Math.round(
              Math.abs(
                (new Date() - new Date(clientCompanyData.lastPaymentDate)) /
                  86400000
              )
            ) <
            Math.round(
              Math.abs(
                (new Date() - new Date(invoiceGroupData.value.due_date)) /
                  86400000
              )
            )
              ? clientCompanyData.lastPaymentDate
              : invoiceGroupData.value.due_date;
          let bucketDecidingNumber = Math.round(
            Math.abs((new Date() - new Date(decidingDate)) / 86400000)
          );
          //console.log(bucketDecidingNumber);

          if (bucketDecidingNumber >= 30) {
            tempPaymentArray.above30dayArray.push(clientCompanyData);
          } else if (bucketDecidingNumber >= 15 && bucketDecidingNumber <= 29) {
            tempPaymentArray.above15dayArray.push(clientCompanyData);
          }
          if (bucketDecidingNumber >= 7 && bucketDecidingNumber <= 14) {
            tempPaymentArray.above7dayArray.push(clientCompanyData);
          }
        } else {
          let bucketDecidingNumber = Math.round(
            Math.abs(
              (new Date() - new Date(invoiceGroupData.value.due_date)) /
                86400000
            )
          );
          //console.log("deciding number", bucketDecidingNumber);
          if (bucketDecidingNumber >= 30) {
            tempPaymentArray.above30dayArray.push(clientCompanyData);
          } else if (bucketDecidingNumber >= 15 && bucketDecidingNumber <= 29) {
            tempPaymentArray.above15dayArray.push(clientCompanyData);
          }
          if (bucketDecidingNumber >= 7 && bucketDecidingNumber <= 14) {
            tempPaymentArray.above7dayArray.push(clientCompanyData);
          }
        }
        if (tempAllInvoices.length - 1 === i) {
          //console.log("this is last invoice doc");
          //console.log(tempPaymentArray);
          setPaymentArray(tempPaymentArray);
        }
      });

      // setPaymentArray(tempPaymentArray);
    }
  }, [masterDB]);

  return (
    <Box>
      <Box
        background={{ color: "brand", opacity: "medium" }}
        height="xxsmall"
        justify="center"
        margin={{ horizontal: "medium", top: "medium" }}
        elevation="xsmall"
      >
        <Text alignSelf="center">Remaining Payment</Text>
      </Box>
      {paymentArray && paymentArray.above30dayArray[0] && (
        <CardsContainer
          cardHeading="No Payment in last 30 days"
          dataArray={paymentArray.above30dayArray}
          hideProperty="latestPaymentHideTill"
        />
      )}
      {paymentArray && paymentArray.above15dayArray[0] && (
        <CardsContainer
          cardHeading="No Payment in last 15 days"
          dataArray={paymentArray.above15dayArray}
          hideProperty="latestPaymentHideTill"
        />
      )}
      {paymentArray && paymentArray.above7dayArray[0] && (
        <CardsContainer
          cardHeading="No Payment in last 07 days"
          dataArray={paymentArray.above7dayArray}
          hideProperty="latestPaymentHideTill"
        />
      )}
    </Box>
  );
};

export default LastPayment;
