import React from "react";
import { Grommet, Box, Spinner } from "grommet";
import { Router, Redirect } from "@reach/router";

import { dbTimeOutPeriod, customTheme } from "../constants";
import fetchRequest from "../database/fetchRequest";
import getUserDB from "../database/getUserDB";

import errorReporting from "../exceptions/errorReporting";
import SignUp from "../pages/a00auth/A01SignUp";
import SignIn from "../pages/a00auth/A02SignIn";
import OfflineMessage from "../components/OfflineMessage";

import HomePage from "../pages/c00home/C01Home";
import MyProfile from "../pages/d00profile/D02My-Profile";
import MyCompanies from "../pages/d00profile/D03My-Companies";
import AddMyCompany from "../pages/d00profile/D04Add-My-Company";
import MyTeam from "../pages/d00profile/D07My-Team";

import AddCompany from "../pages/g00mymasters/G03AddCompany";

import MyClientList from "../pages/g00mymasters/G02MyClientList";
import MyAgentList from "../pages/g00mymasters/G10MyAgentList";
import AddNewInvoice from "../pages/h00invoice/h01Add-new-invoice";
import AddNewPayment from "../pages/i00payment/i01Add-payment";
import MyReport from "../pages/f00reports/f01My-reports";
import J01Commission from "../pages/j00commission/J01Commission";
import NotificationPage from "../pages/k00notification/K01Notification";
import InvoiceList from "../pages/h00invoice/h02invoice-list";
import Setting from "../pages/d00profile/D08Setting";
import OtherCharges from "../components/Setting/OtherCharges";
import AddOtherCharge from "../components/Setting/AddOtherCharge";

import AddDrCrNotes from "../pages/l00DrCrNotes/L01AddDrCrNotes";

import DefaultSetting from "./Setting/DefaultSetting";
import NegativeList from "../pages/d00profile/D09NegativeList";
import ReportPdfGenreation from "../pages/f00reports/f02ReportPdfGenreation";

export class App extends React.Component {
  constructor(props) {
    super(props);
    this.mounted = false;
    this.state = {
      online: false,
      isLoading: true,
      isLoggedIn: false,
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.heartBeat();
    // this.checkLogin();
  }

  heartBeat() {
    // console.log("heartbeat")
    if (!this.mounted) {
      // console.log("not mounted")
      return;
    }

    fetch(fetchRequest)
      .then((result) => {
        // console.log("fetch request")
        if (!this.mounted) {
          return;
        }

        if (result.ok && !this.state.online) {
          this.setState({
            online: true,
          });
          this.checkLogin();
        } else if (!result.ok && this.state.online) {
          this.setState({
            online: false,
          });
        }
        setTimeout(this.heartBeat.bind(this), dbTimeOutPeriod);
      })
      .catch(() => {
        if (!this.mounted) {
          return;
        }
        if (this.state.online) {
          this.setState({
            online: false,
          });
        } else if (!this.state.isLoggedIn) {
          // console.log("offline & loggedout")
          this.checkLogin();
        }
        setTimeout(this.heartBeat.bind(this), dbTimeOutPeriod);
      });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  async checkLogin() {
    if (this.state.online) {
      const db = await getUserDB();
      if (localStorage.getItem("user") && localStorage.getItem("ownerid")) {
        const username = localStorage.getItem("user");
        db.getSession()
          .then(async (sessionDetails) => {
            // console.log(sessionDetails)

            if (sessionDetails.userCtx.name === username) {
              //To avoid unnecessary chaning of state & hence re-rendering
              if (!this.state.isLoggedIn || this.state.isLoading) {
                this.setState({
                  isLoggedIn: true,
                  isLoading: false,
                });
              }
              // console.log(this.state);
            } else {
              db.logout();
              this.setState({
                isLoggedIn: false,
                isLoading: false,
              });
            }
          })
          .catch((err) => {
            errorReporting(err);
            alert(err);
            console.error(err);
          });
      } else {
        db.logout();
        this.setState({
          isLoggedIn: false,
          isLoading: false,
        });
      }
    } else if (
      localStorage.getItem("user") &&
      localStorage.getItem("ownerid")
    ) {
      this.setState({
        isLoggedIn: true,
        isLoading: false,
      });
    } else {
      this.setState({
        isLoggedIn: false,
        isLoading: false,
      });
    }
  }

  render() {
    // console.log("App.js Render")

    const NotFound = () => <div>Sorry, nothing here. 404 error </div>;

    if (this.state.isLoading) {
      return (
        <Grommet full={true} theme={customTheme}>
          <Box fill={true}>
            <Spinner margin="auto" />
          </Box>
        </Grommet>
      );
    } else if (!this.state.isLoading) {
      // console.log(this.state.isLoggedIn);
      return (
        <Grommet theme={customTheme}>
          <Box align="center" justify="center">
            <Box style={{ height: "100%", width: "100%" }}>
              <OfflineMessage online={this.state.online} />
              {!this.state.isLoggedIn && <Redirect to="/signin" noThrow />}
              <Router>
                <HomePage path="/" />
                <SignIn path="signin" />
                <MyProfile path="/my-profile" />
                <AddDrCrNotes path="/addDrCrNote" />
                <NegativeList path="/negative-list" />
                <NestedRoute path="/my-companies">
                  <MyCompanies path="/" />
                  <AddMyCompany path="/add-my-company" />
                  <AddMyCompany
                    path="/edit-my-company/:companyCode"
                    type="edit"
                  />
                </NestedRoute>
                <NestedRoute path="/my-team">
                  <MyTeam path="/" />
                </NestedRoute>
                <MyClientList path="/my-clients-list" />

                <AddCompany
                  path="/add-client-company"
                  short="cc"
                  major="client"
                  part="Company"
                />
                <AddCompany
                  path="/edit-client-company/:editUriCode"
                  type="edit"
                  short="cc"
                  major="client"
                  part="Company"
                />
                <MyAgentList path="/sub-agents-list" />

                <AddCompany
                  path="/add-agent-company"
                  short="sac"
                  major="agent"
                  part="Company"
                />
                <AddCompany
                  path="/edit-agent-company/:editUriCode"
                  type="edit"
                  short="sac"
                  major="agent"
                  part="Company"
                />

                <InvoiceList path="/invoices" />
                <NestedRoute path="/invoice">
                  <AddNewInvoice path="/add-new-invoice" />
                  <AddNewInvoice path="/add-new-invoice/:orderNumber" />
                  <AddNewInvoice path="/edit-invoice/" type="edit" />
                </NestedRoute>
                <NestedRoute path="/payment">
                  <AddNewPayment path="/add-new-payment" />
                </NestedRoute>
                <NestedRoute path="/my-reports">
                  <MyReport path="/" />
                </NestedRoute>
                <NestedRoute path="/my-commission">
                  <J01Commission path="/" />
                </NestedRoute>
                <NotFound default />
                <NestedRoute path="my-notification">
                  <NotificationPage path="/" />
                </NestedRoute>
                <NotFound default />
                <NestedRoute path="settings">
                  <Setting path="/" />
                  <OtherCharges path="/other-charges" />
                  <AddOtherCharge path="/add-other-charge" />
                  <AddOtherCharge path="/edit-other-charge" type="edit" />
                  <DefaultSetting path="/default" />
                </NestedRoute>
                <NestedRoute path="report-pdf">
                  <ReportPdfGenreation path="/" />
                </NestedRoute>
              </Router>
            </Box>
          </Box>
        </Grommet>
      );
    }
    return (
      <Grommet full={true} theme={customTheme}>
        <Box fil={true}>
          <Spinner margin="auto" />
          Something is wrong. Contact on +91 - 7575806994
        </Box>
      </Grommet>
    );
  }
}

export default App;

const NestedRoute = (props) => props.children;
