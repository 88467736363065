import { Box, Text } from "grommet";
import { Edit } from "grommet-icons";
import React, { useEffect, useState } from "react";
import getMasterDB from "../database/getMasterDB";

const MonthlyTotalsCard = ({monthAndYear}) => {

    const [masterDB, setMasterDB] = useState();
    const [monthlyTotals, setMonthlyTotals] = useState();
    console.log({monthlyTotals, monthAndYear});

    useEffect(async () => {
        if(!masterDB) {
            const tempMasterDB = await getMasterDB();
            setMasterDB(tempMasterDB);
        }

        if (masterDB && !monthlyTotals){
            await masterDB.find({
                    selector: {
                        invoiceDate:{
                            $gte: `${monthAndYear.year}-${monthAndYear.month}`,
                            $lte: `${monthAndYear.year}-${monthAndYear.month}z`
                        },
                    },
                    fields: ['grandTotals', 'commission']
                }). then (findData => {
                    console.log(findData);
                    findData = findData.docs;
                    let tempTotals = {
                        invoiceTotal :0,
                        commTotal : 0
                    };
                    for (let invData of findData) {
                        tempTotals.invoiceTotal += Number(invData.grandTotals.grandTotalInvoiceAmount || 0),
                        tempTotals.commTotal += Number(invData.commission.amount || 0)
                        console.log({tempTotals});
                    }
                    setMonthlyTotals (tempTotals);
                }).catch (err => console.log(err));
            }
    }, [masterDB]);

    return (
        <Box>
            <Box align="center" direction="row" justify="center" gap="xsmall"
                // onClick={() => {

                // }}
            >
                {(monthAndYear.month == "04") && (
                    <Text>April '22</Text>
                )}
                {(monthAndYear.month == "05") && (
                    <Text>May '22</Text>
                )}
                {/* <Edit size="small"/> */}
            </Box>
            <Box direction="row" align="center" justify="between" gap="xsmall">
                <Text>Sales:</Text>
                <Text> Rs. {monthlyTotals && monthlyTotals.invoiceTotal}</Text>
            </Box>
            <Box direction="row" align="center" justify="between" gap="xsmall">
                <Text>Comm:</Text>
                <Text> Rs. {monthlyTotals && monthlyTotals.commTotal}</Text>
            </Box>
        </Box>
    )
}

const MonthlyTotalsWindow = () => {
    return (
        <Box>
            <Text>Monthly Reports</Text>
            <Box direction="row" gap="medium" justify="center">
                <MonthlyTotalsCard monthAndYear={{month: "04", year: "2022"}}/>
                <MonthlyTotalsCard monthAndYear={{month: "05", year: "2022"}}/>
                {/* <MonthlyTotalsCard monthAndYear={{month: "04",year: "2022"}}/> */}
                {/* <MonthlyTotalsCard monthAndYear={{month: "04",year: "2022"}}/> */}
                {/* <Box>
                    Add a month
                </Box> */}
            </Box>
        </Box>
    )
}

export default MonthlyTotalsWindow;