export const isdCodeValidation = (fieldInputs, formInputs) => {
  console.log({ fieldInputs, formInputs });
  return undefined;
};

export const numberValidation = (fieldInputs, formInputs, length) => {
  let numRegex = /^[0-9]+$/g;
  // console.log("Inside Phone Number Validation");

  // console.log({ fieldInputs, length });
  if (fieldInputs) {
    fieldInputs = fieldInputs.toString();

    if (fieldInputs && !fieldInputs.match(numRegex)) {
      // console.log("Input Only number");
      return { message: "Input Only number", status: "error" };
    }

    if (fieldInputs && length && fieldInputs.length > length) {
      // console.log("Input Only 10 digits");
      return { message: `Input Only ${length} digits`, status: "error" };
    }
    // else if(fieldInputs && length && fieldInputs.length != length) {
    //     return ({message:`Need ${length} digits`, status:"error"})
    // }

    return undefined;
  }
};

export const userCodeValidation = (
  fieldInputs,
  formInputs,
  type,
  listArray,
  edit
) => {
  if (!edit) {
    let codeRegex = /^[A-Za-z0-9_]*$/;

    if (fieldInputs && !fieldInputs.match(codeRegex)) {
      return {
        message: "Spaces & special chars not allowed. '_' is allowed.",
        status: "error",
      };
    }

    if (fieldInputs && fieldInputs.length > 15) {
      return {
        message: "Max length is 15 characters.",
        status: "error",
      };
    }

    if (fieldInputs && fieldInputs.length < 6) {
      return {
        message: "Code should be min. 6 characters.",
        status: "error",
      };
    }

    switch (type) {
      case "order":
        //check if the id is unique.
        if (listArray.includes(fieldInputs)) {
          return {
            message: "The order number already exists.",
            status: "error",
          };
        }
        break;
      case "invoice":
        //check if the id is unique.
        if (listArray.includes(fieldInputs)) {
          return {
            message: "The invoice number already exists.",
            status: "error",
          };
        }
        break;
    }
  }

  return undefined;
};

export const gstNumberValidation = (fieldDetails, formDetails) => {
  return { message: "Set up Validation", status: "info" };
};

export const panValidation = (fieldDetails, formDetails) => {
  return { message: "Set up Validation", status: "info" };
};

export const numStringValidation = (fieldInputs, formInputs, length) => {
  let codeRegex = /^[A-Za-z0-9]*$/;

  if (fieldInputs && !fieldInputs.match(codeRegex)) {
    return {
      message: "Spaces & special chars not allowed.",
      status: "error",
    };
  }

  // if (length && fieldInputs && fieldInputs.length !== length){
  //     return ({
  //         message: `Should be ${length} Characters.`,
  //         status:"error"
  //     })
  // }

  return undefined;
};

export const decimalNumberValidation = (fieldInputs, length) => {
  let numRegex = /^\d*\.?\d+$/;
  // console.log("Inside decimal Number Validation");

  // console.log({ fieldInputs, length });
  if (fieldInputs) {
    fieldInputs = fieldInputs.toString();

    if (fieldInputs && !fieldInputs.match(numRegex)) {
      // console.log("Input Only number");
      return { message: "Input Only number", status: "error" };
    }

    if (fieldInputs && length && fieldInputs.length > length) {
      // console.log("Input Only 10 digits");
      return { message: `Input Only ${length} digits`, status: "error" };
    }
    // else if(fieldInputs && length && fieldInputs.length != length) {
    //     return ({message:`Need ${length} digits`, status:"error"})
    // }

    return undefined;
  }
};
