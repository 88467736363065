const updateObjProp = (changeTemp, formTemp, value, propPath, tempOriginalData) => {


    const [head, ...rest] = propPath.split('.');
    // console.log({formTemp, value, propPath, head, rest});


    if(Array.isArray(formTemp[head])){
        // console.log(`${head} is Array.`)
        return({error: `${head} is Array.`})
    }

    if(formTemp.error){
        return({error: `Handle Array.`})
    }


    if(!rest.length){
        // console.log(formTemp[head]);
        changeTemp[head] = tempOriginalData[head];
        formTemp[head] = value;
    } else {

        formTemp[head] = formTemp[head] || {};
        changeTemp[head] = changeTemp[head] || {};
        tempOriginalData[head] = tempOriginalData[head] || {};

        updateObjProp(changeTemp[head], formTemp[head], value, rest.join('.'), tempOriginalData);
    }

    return ({formTemp, changeTemp});

}

export default updateObjProp;