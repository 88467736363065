import React, { useEffect } from "react";
import {
  Box,
  DateInput,
  Form,
  FormField,
  Select,
  Text,
  DataTable,
  TextArea,
  TextInput,
} from "grommet";

const CommissionPdf = React.forwardRef((props, ref) => {
  const { tableData, dataObj, statementDate, ownerGroupData } = props;
  const now = new Date();

  console.log("----------props-----------");
  console.log({ tableData, dataObj, statementDate, ownerGroupData });
  // console.log("---------this is owner data from pdf---------------");
  // console.log(ownerGroupData);

  return (
    <Box ref={ref} alignSelf="center" background="white">
      <Box>
        <Text size="3xl" alignSelf="center" margin="medium">
          {ownerGroupData.companyName}
        </Text>
        {/* address box*/}
        <Box border="all" margin="medium" flex direction="row">
          {/* agent's company */}
          <Box flex direction="column" border="end" margin="medium">
            {/* company name */}
            <Text size="medium" weight="bold">
              {ownerGroupData.companyName}
            </Text>
            {/* Address */}
            <Text size="xsmall">{ownerGroupData.address}</Text>
            <Text size="xsmall">
              PH:{" "}
              {ownerGroupData.companyPhone &&
                ownerGroupData.companyPhone.phoneNumber}
            </Text>
            <Text size="small">email: {ownerGroupData.companyEmail}</Text>
          </Box>
          {/* seller's company */}
          <Box flex direction="column" margin="small">
            {/* company name */}
            <Text size="medium" weight="bold">
              {tableData[0].sellerDetails && tableData[0].sellerDetails.name}
            </Text>
            {/* Address */}
            <Text size="xsmall">
              {tableData[0].sellerDetails.addressDetails &&
                tableData[0].sellerDetails.addressDetails.address}
            </Text>
            <Text size="xsmall">
              PH:{" "}
              {(tableData[0].sellerDetails.phoneNumber &&
                tableData[0].sellerDetails.phoneNumber.isdCode) +
                (tableData[0].sellerDetails.phoneNumber &&
                  tableData[0].sellerDetails.phoneNumber.phoneNumber)}
            </Text>
            <Text size="small">email:{tableData[0].sellerDetails.email}</Text>
          </Box>
        </Box>
        <Text
          size="large"
          weight="bold"
          alignSelf="center"
          decoratio="underline"
        >
          Commission invoice for the year {now.getFullYear() - 1}-
          {now.getFullYear()}
        </Text>
        <Box>
          <Text
            alignSelf="start"
            justify="start"
            size="medium"
            margin={{ horizontal: "large" }}
          >
            Invoice No.{Math.floor(Math.random() * 1000 + 1)}
          </Text>

          <Text
            alignSelf="start"
            justify="start"
            size="medium"
            margin={{ horizontal: "large" }}
          >
            Date: {now.toLocaleDateString("en-IN")}
          </Text>
        </Box>
        <br />
        <Text
          alignSelf="start"
          justify="start"
          size="medium"
          margin={{ horizontal: "large" }}
        >
          For,
        </Text>
        <Text
          alignSelf="start"
          justify="start"
          size="medium"
          margin={{ horizontal: "large" }}
        >
          {tableData[0].sellerDetails && tableData[0].sellerDetails.name},
          {(tableData[0].sellerDetails.addressDetails &&
            tableData[0].sellerDetails.addressDetails.state) ||
            (tableData[0].sellerDetails.addressDetails &&
              tableData[0].sellerDetails.addressDetails.city)}
        </Text>
        <br />
        <Text
          alignSelf="start"
          justify="start"
          size="medium"
          margin={{ horizontal: "large" }}
        >
          Please issue TDS certificate as follows:-
        </Text>
        <DataTable
          margin={{ horizontal: "large" }}
          columns={[
            {
              property: "name",
              header: <Text weight="bold">In favour of</Text>,
              primary: true,
            },
            {
              property: "totalSales",
              header: <Text weight="bold">Total Sales</Text>,
            },
            {
              property: "commissionAmount",
              header: (
                <Text weight="bold">Total Amount of commission in ₹</Text>
              ),
              render: (data) => (
                <Text>
                  ₹{Math.round(data.commissionAmount * 100) / 100}
                  {/* {parseFloat(
                    data.commissionAmount.toLocaleString("en-IN", {
                      style: "currency",
                      currency: "INR",
                    })
                  )} */}
                </Text>
              ),
            },
            {
              property: "tdsAmt",
              header: <Text weight="bold">TDS Amount in ₹</Text>,
              render: (data) => (
                <Text>
                  ₹ {Math.round(data.tdsAmt * 100) / 100}
                  {/* {parseFloat(
                    data.tdsAmt.toLocaleString("en-IN", {
                      style: "currency",
                      currency: "INR",
                    })
                  )} */}
                </Text>
              ),
            },
            {
              property: "finalAmt",
              header: (
                <Text weight="bold">To Pay After Deduucting TDS in ₹</Text>
              ),
              render: (data) => (
                <Text>
                  ₹ {Math.round(data.finalAmt * 100) / 100}
                  {/* {parseFloat(
                    data.finalAmt.toLocaleString("en-IN", {
                      style: "currency",
                      currency: "INR",
                    })
                  )} */}
                </Text>
              ),
            },
          ]}
          data={[dataObj]}
        />
        <br />
        <Text margin={{ horizontal: "large" }}>
          For NEFT/RTGS details of the bank account are given here under else
          you can issue a cheque in our favour. If you are paying through
          NTFS/RTGS, intimate us with the payment details on{" "}
          {tableData[0].sellerDetails.email} or through WhatsApp.Kindly inform
          us over telephone as well.
        </Text>
        <br />
        <Text weight="bold" margin={{ horizontal: "large" }}>
          Please pay at the earliest
        </Text>
        <br />

        <DataTable
          margin={{ horizontal: "large" }}
          columns={[
            {
              property: "name",
              header: <Text weight="bold">Bank Name</Text>,
              primary: true,
            },
            {
              property: "accountNumber",
              header: <Text weight="bold">Account Number</Text>,
            },
            {
              property: "ifscCode",
              header: <Text weight="bold">IFSC Code</Text>,
            },
            {
              property: "branch",
              header: <Text weight="bold">Branch</Text>,
            },
          ]}
          data={[
            ownerGroupData.bankDetails && ownerGroupData.bankDetails.bank1,
          ]}
        />

        <br />
        <br />
        <Text margin={{ horizontal: "large", top: "medium" }}>E&OE</Text>
        <br />
        <Text margin={{ horizontal: "large" }}>Thanking You,</Text>
        <Text margin={{ horizontal: "large", bottom: "large" }}>
          Yours Faithfully.
        </Text>
      </Box>
      {/* the second page */}
      <br />
      <br />
      <br />
      <br />
      <br />

      <Box margin={{ top: "large" }}>
        <Box margin={{ top: "large" }} flex>
          <Text margin={{ horizontal: "large", top: "large" }}>To</Text>
          <Text margin={{ horizontal: "large" }}>
            {tableData[0].sellerDetails && tableData[0].sellerDetails.name}
          </Text>
          <Text justify="end" margin={{ horizontal: "large", top: "small" }}>
            Date: {now.toLocaleDateString("en-IN")}
          </Text>

          <br />
          <Text alignSelf="center" size="medium">
            Commission statement for the period from
            {new Date(
              now.getFullYear() - 1,
              now.getMonth() + 1,
              1
            ).toLocaleDateString("en-IN")}{" "}
            to{" "}
            {new Date(now.getFullYear(), now.getMonth(), 31).toLocaleDateString(
              "en-IN"
            )}
          </Text>
          <br />
          <br />
          <Text margin={{ horizontal: "large" }}>Agent:</Text>
          <br />
          <br />
          <DataTable
            margin={{ horizontal: "large" }}
            columns={[
              {
                property: "key",
                header: <Text weight="bold">SL.NO</Text>,
                footer: "Total",
              },
              {
                property: "invoiceNumber",
                header: <Text weight="bold">Bill NO.</Text>,
              },
              {
                property: "invoiceDate",
                header: <Text weight="bold">Bill Date</Text>,
              },
              {
                property: "buyerName",
                header: <Text weight="bold">Buyer</Text>,
                size: "1/2",
                primary: true,
              },
              {
                property: "invTotalAmt",
                header: (
                  <Text weight="bold" margin={{ horizontal: "small" }}>
                    Bill Amount
                  </Text>
                ),
                render: (data) => (
                  <Text>
                    ₹{data.invTotalAmt ? Math.round(data.invTotalAmt) : 0}
                  </Text>
                ),
                aggregate: "sum",
                footer: { aggregate: true },
              },
              {
                property: "receivedAmt",
                header: <Text weight="bold">RECD. Amount</Text>,
                render: (data) => (
                  <Text>₹{Math.round(data.receivedAmt * 100) / 100}</Text>
                ),
                aggregate: "sum",
                footer: { aggregate: true },
              },
              {
                property: "paymentDate",
                header: <Text weight="bold">Payment DT</Text>,
              },
              {
                property: "paymentNumber",
                header: <Text weight="bold">Slip No.</Text>,
              },
              {
                property: "commissionPer",
                header: <Text weight="bold">Comm %</Text>,
              },
              {
                property: "commissionAmt",
                header: <Text weight="bold">Comm. Amount</Text>,
                render: (data) => (
                  <Text>₹{Math.round(data.commissionAmt)}</Text>
                ),
                aggregate: "sum",
                footer: { aggregate: true },
              },
            ]}
            data={statementDate}
          />
          <br />
          <br />
          <Text margin={{ horizontal: "large" }}>For:</Text>
          <br />
          <br />
          <Text margin={{ horizontal: "large" }}>Authorized Signatory</Text>
        </Box>
      </Box>
    </Box>
  );
});
export default CommissionPdf;

/*
[
              {
                key: "1",
                invoiceNumber: "1234",
                invoiceDate: now.toLocaleDateString("en-IN"),
                buyerName: "Kanchana textile ltd",
                invTotalAmt: 3500,
                receivedAmt: 3500,
                paymentDate: now.toLocaleDateString("en-IN"),
                paymentNumber: "212",
                commissionPer: "2",
                commissionAmt: 700,
              },
              {
                key: "2",
                invoiceNumber: "1235",
                invoiceDate: now.toLocaleDateString("en-IN"),
                buyerName: "desai textile ltd",
                invTotalAmt: 4000,
                receivedAmt: 4000,
                paymentDate: now.toLocaleDateString("en-IN"),
                paymentNumber: "213",
                commissionPer: "2",
                commissionAmt: 800,
              },
            ]

*/
