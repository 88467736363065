import React, { useState } from "react";
import {
  Form,
  FormField,
  TextInput,
  Select,
  Anchor,
  Box,
  Text,
  Menu,
  Image,
  Button,
  Layer,
} from "grommet";
import { FormUp, FormDown, Phone, Close } from "grommet-icons";
import getMasterDB from "../../database/getMasterDB";
import { countryCodes } from "../../constants";
import { navigate } from "@reach/router";

const addPhoneStyle = {};
const hideFunction = async (
  daysToHide,
  hideProperty,
  companyDocumentId,
  companyCode
) => {
  // console.log({ daysToHide, hideProperty, companyDocumentId });
  document.getElementById(companyCode).setAttribute("style", "display:none");
  let today = new Date();
  let newDate = new Date().setDate(today.getDate() + daysToHide);
  newDate = new Date(newDate).toISOString();

  const masterDB = await getMasterDB();
  let companyDocument = await masterDB.get(companyDocumentId);
  companyDocument[hideProperty] = newDate;

  await masterDB.put(companyDocument);
};

const CallingCard = (props) => {
  const { data, hideProperty } = props;
  const [phoneShow, setPhoneShow] = useState();
  const [phoneNumber, setPhoneNumber] = useState({ isdCode: "+91" });

  const AddPhoneNumber = async (companyData) => {
    const masterDB = await getMasterDB();

    console.log("hey you clicked add phone number");

    console.log(phoneNumber);
    companyData.phoneNumber = phoneNumber;
    console.log(companyData);
    await masterDB.put(companyData);
    navigate(
      `tel:${
        phoneNumber && phoneNumber.isdCode
          ? (phoneNumber && phoneNumber.isdCode) +
            (phoneNumber && phoneNumber.phoneNumber)
          : phoneNumber && phoneNumber.phoneNumber
      }`
    );
    setPhoneShow(false);
  };
  return (
    <Box
      direction="column"
      elevation="small"
      flex
      margin={{ top: "small" }}
      id={data.code || data.buyerDetails.code}
    >
      <Box direction="row" align="center">
        <Text
          margin={{ left: "small", top: "medium", bottom: "small" }}
          alignSelf="start"
          wordBreak="break-all"
        >
          {data.name || data.buyerDetails.name}
        </Text>
        <Box flex direction="row" margin="small" justify="end">
          {!(data.phoneNumber && data.phoneNumber.phoneNumber) ? (
            <Box>
              <Button
                onClick={() => {
                  setPhoneShow(!phoneShow);
                }}
              >
                <Image
                  src={require("../../../logos/addphoneIcon.svg")}
                  alt="addPhoneNumber"
                  width="25px"
                  height="25px"
                  margin={{ top: "medium" }}
                />
              </Button>
            </Box>
          ) : (
            <Anchor
              margin={{ top: "small" }}
              href={`tel:${
                data.phoneNumber && data.phoneNumber.isdCode
                  ? (data.phoneNumber && data.phoneNumber.isdCode) +
                    (data.phoneNumber && data.phoneNumber.phoneNumber)
                  : data.phoneNumber && data.phoneNumber.phoneNumber
              }`}
            >
              <Phone size="medium" />
            </Anchor>
          )}
          <Menu
            dropAlign={{ right: "right", top: "bottom" }}
            items={[
              {
                label: "Hide for 7days",
                onClick: () => {
                  hideFunction(7, hideProperty, data._id, data.code);
                },
              },
              {
                label: "Hide for Ever",
                onClick: () => {
                  hideFunction(3000, hideProperty, data._id, data.code);
                },
              },
            ]}
          />
        </Box>
      </Box>
      {phoneShow && (
        <Box
          justify="between"
          direction="row"
          margin={{ left: "small", bottom: "small" }}
        >
          <Form
            align="center"
            onSubmit={() => {
              AddPhoneNumber(data);
            }}
            validate="blur"
            onValidate={(validationResults) => {
              console.log("validationResults = ", validationResults);
            }}
          >
            <Box direction="row">
              <Box width="small" margin={{ horizontal: "medium" }}>
                <FormField
                  name="phoneNumber.phoneNumber"
                  validate={[
                    // regex for checking phone numbers

                    (number) => {
                      if (!number) {
                        return "Please Enter Phone Number";
                      }
                      console.log(number.length);
                      if (number.length > 10 || number.length < 10) {
                        return "Please Enter Valid Phone number";
                      }
                    },
                  ]}
                >
                  <TextInput
                    icon={<Phone />}
                    type="number"
                    name="phoneNumber.phoneNumber"
                    required
                    value={phoneNumber.phoneNumber}
                    onChange={(inputName) => {
                      let tempFormData = phoneNumber;
                      tempFormData.phoneNumber = inputName.target.value;
                      setPhoneNumber(tempFormData);
                    }}
                  />
                </FormField>
              </Box>

              <Button
                size="medium"
                label="Call"
                type="submit"
                margin={{ left: "small", bottom: "small" }}
              />
            </Box>
          </Form>
        </Box>
      )}
    </Box>
  );
};
const CardsContainer = (props) => {
  const { cardHeading, dataArray, hideProperty } = props;

  const [moreDetails, setMoreDetails] = useState(false);
  // console.log({ cardHeading, dataArray, hideProperty });
  return (
    <Box margin={{ horizontal: "medium" }} elevation="xsmall">
      <Box border="bottom" height="xxsmall" direction="row" justify="center">
        <Box direction="row" alignSelf="center">
          <Text
            color="brand"
            onClick={() => setMoreDetails((moreDetails) => !moreDetails)}
          >
            {cardHeading}
          </Text>
          {!moreDetails && <FormDown color="brand" />}
          {moreDetails && <FormUp color="brand" />}
        </Box>
      </Box>
      {moreDetails && (
        <Box
          alignSelf="center"
          style={{
            overflow: "auto",
            display: "block",
          }}
          margin="small"
          width="95vw"
        >
          {dataArray.map((data, i) => (
            <CallingCard hideProperty={hideProperty} data={data} key={i} />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default CardsContainer;
