/*
goal: We want the company list which didn't give any kind of invoice, particularly buyer company, 

We have lastBuyInvoiceDate in the client company by which we can predict that how long have this company haven't generated invoice

get all the buyer client company, check the lastBuyInvoiceDate, on basis of that put them in the bucket,
if lastBuyInvoiceDate doesn't exist, then it means the invoice is not generated by that company, add them in more then 30 days bucket

Why user should care ?
because, he will get to know which buyer company hasn't given order in this particular time period,
by this piece of information he can increase is business, he can directly call the buyer to ask that you haven't given any kind of business in last 15 days or 30 days.
 */

import React, { useEffect, useState } from "react";
import { Box, Text } from "grommet";
import getMasterDB from "../../database/getMasterDB";
import getRecords from "../../database/getRecords";
import { FormUp, FormDown } from "grommet-icons";

import CardsContainer from "./CallingCard";
import { EmailInput } from "grommet-controls";

const LastInvoice = () => {
  let ownerGroupID = localStorage.getItem("ownerid");
  let userCode = localStorage.getItem("usercode");

  const [InvoiceArray, setInvoiceArray] = useState({
    above30dayArray: [],
    above15dayArray: [],
    above7dayArray: [],
  });
  const [masterDB, setMasterDB] = useState();

  // //console.log({ paymentArray });

  useEffect(async () => {
    //console.log("---inside useeffect-------------");
    if (!masterDB) {
      let tempMasterDB = await getMasterDB();
      setMasterDB(tempMasterDB);
    } else {
      //console.log("jello");

      function mapReduceFunc(doc) {
        if (
          doc.type == "clientCompany" &&
          (doc.category === "Buyer" || doc.category === "Both - Buyer & Seller")
        ) {
          emit(doc);
        }
      }

      let tempAllClientCompany = await masterDB.query(mapReduceFunc, {
        include_docs: true,
      });
      let tempInvoiceArray = {
        above30dayArray: [],
        above15dayArray: [],
        above7dayArray: [],
      };
      tempAllClientCompany = tempAllClientCompany.rows;
      tempAllClientCompany.map((clientDoc, i) => {
        clientDoc = clientDoc.doc;
        let bucketDecidingNumber = clientDoc.lastBuyInvoiceDate
          ? Math.round(
              Math.abs(
                (new Date() - new Date(clientDoc.lastBuyInvoiceDate)) / 86400000
              )
            )
          : // if lastBuyInvoiceDate doesn't exist then this is default case, where it will go in above30days
            31;

        if (bucketDecidingNumber >= 30) {
          tempInvoiceArray.above30dayArray.push(clientDoc);
        } else if (bucketDecidingNumber >= 15 && bucketDecidingNumber <= 29) {
          tempInvoiceArray.above15dayArray.push(clientDoc);
        }
        if (bucketDecidingNumber >= 7 && bucketDecidingNumber <= 14) {
          tempInvoiceArray.above7dayArray.push(clientDoc);
        }
        if (tempAllClientCompany.length - 1 === i) {
          ////console.log("this is last invoice doc");
          //console.log(tempInvoiceArray);
          setInvoiceArray(tempInvoiceArray);
        }
      });
      //console.log(tempAllClientCompany);
    }
  }, [masterDB]);

  return (
    <Box>
      <Box
        background={{ color: "brand", opacity: "medium" }}
        height="xxsmall"
        justify="center"
        margin={{ horizontal: "medium", top: "medium" }}
        elevation="xsmall"
      >
        <Text alignSelf="center">Remaining Invoice</Text>
      </Box>
      {InvoiceArray && InvoiceArray.above30dayArray[0] && (
        <CardsContainer
          cardHeading="No Invoice in last 30 days"
          dataArray={InvoiceArray.above30dayArray}
          hideProperty="latestInvoiceHideTill"
        />
      )}
      {InvoiceArray && InvoiceArray.above15dayArray[0] && (
        <CardsContainer
          cardHeading="No Invoice in last 15 days"
          dataArray={InvoiceArray.above15dayArray}
          hideProperty="latestInvoiceHideTill"
        />
      )}
      {InvoiceArray && InvoiceArray.above7dayArray[0] && (
        <CardsContainer
          cardHeading="No Invoice in last 07 days"
          dataArray={InvoiceArray.above7dayArray}
          hideProperty="latestInvoiceHideTill"
        />
      )}
    </Box>
  );
};

export default LastInvoice;
