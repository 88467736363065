import React, { useState, useEffect } from "react";
import { Box, Header, Text, Tabs, Tab, Select, DataTable } from "grommet";
import NavFooter from "../../components/navFooter/navFooter";
import {
  UserAvatarBox,
  NotificationsBox,
  SyncButton,
} from "../../components/Header/Header";
import getMasterDB from "../../database/getMasterDB";
import SearchComponent from "../../components/Search/Search";
import {
  CaretDownFill,
  CaretUp,
  CaretUpFill,
  ShareOption,
} from "grommet-icons";
import { navigate } from "@reach/router";

function MyReports() {
  let ownerGroupID = localStorage.getItem("ownerid");

  const [index, setIndex] = useState(0);
  const [subTabIndex, setSubTabIndex] = useState(0);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [invoicesData, setInvoicesData] = useState();
  const [expandNames, setExpandNames] = useState([]);
  //console.log({ index, subTabIndex, invoicesData });

  const tabBoxStyle = {
    justifyContent: "space-around",
  };

  useEffect(async () => {
    //////console.log(index);

    const masterDB = await getMasterDB();

    //console.log("hey i am getting your data");
    let queryObj = {};
    if (index === 0) {
      if (subTabIndex === 0) {
        queryObj = {
          map: function (doc) {
            if (doc.type === "invoice") {
              let keyName = doc.buyerDetails.groupName || doc.buyerDetails.name;
              let grpStat = doc.buyerDetails.groupName
                ? "Grouped"
                : "Non-Group";
              // //console.log({grpStat});
              emit([doc.type, doc.status, grpStat, keyName], {
                parentCompany_id: doc.buyerDetails._id,
                parentCompanyName: doc.buyerDetails.name,
                childCompany_id: doc.sellerDetails._id,
                childCompanyName: doc.sellerDetails.name,
                invoiceSerialNumber: doc.invoiceSerialNumber,
                invoiceNumber: doc.invoiceNumber,
                invoiceDate: doc.invoiceDate,
                totalInvoiceAmount: Number(doc.grandTotals.grandTotalInvoiceAmount),
                dueDate: doc.invoiceDue.dueDate,
                pendingAmount: Number(doc.pendingPayment),
              });
            }
          },
          reduce: function (key, values) {
            //console.log({ key, values });
            return values;
          },
        };
      } else if (subTabIndex === 1) {
        queryObj = {
          map: function (doc) {
            if (doc.type === "invoice") {
              let keyName =
                doc.buyerDetails.subAgentCode || doc.buyerDetails.name;
              let grpStat = doc.buyerDetails.subAgentCode
                ? "Grouped"
                : "Non-Group";
              // //console.log({grpStat});
              emit([doc.type, doc.status, grpStat, keyName], {
                parentCompany_id: doc.buyerDetails._id,
                parentCompanyName: doc.buyerDetails.name,
                childCompany_id: doc.sellerDetails._id,
                childCompanyName: doc.sellerDetails.name,
                invoiceSerialNumber: doc.invoiceSerialNumber,
                invoiceNumber: doc.invoiceNumber,
                invoiceDate: doc.invoiceDate,
                totalInvoiceAmount: Number(doc.grandTotals.grandTotalInvoiceAmount),
                dueDate: doc.invoiceDue.dueDate,
                pendingAmount: Number(doc.pendingPayment),
              });
            }
          },
          reduce: function (key, values) {
            //console.log({ key, values });
            return values;
          },
        };
      } else if (subTabIndex === 2) {
        queryObj = {
          map: function (doc) {
            if (doc.type === "invoice") {
              let keyName =
                (doc.salesManAccount && doc.salesManAccount.userName) || doc.buyerDetails.name;
                let grpStat = doc.salesManAccount && doc.salesManAccount.userName
                ? "Grouped"
                : "Non-Group";
              // //console.log({grpStat});
              emit([doc.type, doc.status, grpStat, keyName], {
                parentCompany_id: doc.buyerDetails._id,
                parentCompanyName: doc.buyerDetails.name,
                childCompany_id: doc.sellerDetails._id,
                childCompanyName: doc.sellerDetails.name,
                invoiceSerialNumber: doc.invoiceSerialNumber,
                invoiceNumber: doc.invoiceNumber,
                invoiceDate: doc.invoiceDate,
                totalInvoiceAmount: Number(doc.grandTotals.grandTotalInvoiceAmount),
                dueDate: doc.invoiceDue.dueDate,
                pendingAmount: Number(doc.pendingPayment),
              });
            }
          },
          reduce: function (key, values) {
            //console.log({ key, values });
            return values;
          },
        };
      }
    } else if (index === 1) {
      if (subTabIndex === 0) {
        queryObj = {
          map: function (doc) {
            if (doc.type === "invoice") {
              let keyName =
                doc.sellerDetails.groupName || doc.sellerDetails.name;
              let grpStat = doc.sellerDetails.groupName
                ? "Grouped"
                : "Non-Group";
              // //console.log({grpStat});
              emit([doc.type, doc.status, grpStat, keyName], {
                parentCompany_id: doc.sellerDetails._id,
                parentCompanyName: doc.sellerDetails.name,
                childCompany_id: doc.buyerDetails._id,
                childCompanyName: doc.buyerDetails.name,
                invoiceSerialNumber: doc.invoiceSerialNumber,
                invoiceNumber: doc.invoiceNumber,
                invoiceDate: doc.invoiceDate,
                totalInvoiceAmount: Number(doc.grandTotals.grandTotalInvoiceAmount),
                dueDate: doc.invoiceDue.dueDate,
                pendingAmount: Number(doc.pendingPayment),
              });
            }
          },
          reduce: function (key, values) {
            // //console.log({ key, values });
            return values;
          },
        };
      } else if (subTabIndex === 1) {
        queryObj = {
          map: function (doc) {
            if (doc.type === "invoice") {
              let keyName =
                doc.sellerDetails.subAgentCode || doc.sellerDetails.name;
              let grpStat = doc.sellerDetails.subAgentCode
                ? "Grouped"
                : "Non-Group";
              // //console.log({grpStat});
              emit([doc.type, doc.status, grpStat, keyName], {
                parentCompany_id: doc.sellerDetails._id,
                parentCompanyName: doc.sellerDetails.name,
                childCompany_id: doc.buyerDetails._id,
                childCompanyName: doc.buyerDetails.name,
                invoiceSerialNumber: doc.invoiceSerialNumber,
                invoiceNumber: doc.invoiceNumber,
                invoiceDate: doc.invoiceDate,
                totalInvoiceAmount: Number(doc.grandTotals.grandTotalInvoiceAmount),
                dueDate: doc.invoiceDue.dueDate,
                pendingAmount: Number(doc.pendingPayment),
              });
            }
          },
          reduce: function (key, values) {
            // //console.log({ key, values });
            return values;
          },
        };
      } else if (subTabIndex === 2) {
        queryObj = {
          map: function (doc) {
            if (doc.type === "invoice") {
              let keyName =
              (doc.salesManAccount && doc.salesManAccount.userName) || doc.sellerDetails.name;
              let grpStat = doc.salesManAccount && doc.salesManAccount.userName
                ? "Grouped"
                : "Non-Group";
              // //console.log({grpStat});
              emit([doc.type, doc.status, grpStat, keyName], {
                parentCompany_id: doc.sellerDetails._id,
                parentCompanyName: doc.sellerDetails.name,
                childCompany_id: doc.buyerDetails._id,
                childCompanyName: doc.buyerDetails.name,
                invoiceSerialNumber: doc.invoiceSerialNumber,
                invoiceNumber: doc.invoiceNumber,
                invoiceDate: doc.invoiceDate,
                totalInvoiceAmount: Number(doc.grandTotals.grandTotalInvoiceAmount),
                dueDate: doc.invoiceDue.dueDate,
                pendingAmount: Number(doc.pendingPayment),
              });
            }
          },
          reduce: function (key, values) {
            // //console.log({key, values})
            return values;
          },
        };
      }
    }

    // //console.log({queryObj});
    let tempInvoicesData = await masterDB.query(queryObj, {
      reduce: true,
      group: true,
      group_level: 4,
      inclusive_end: true,
      startkey: ["invoice", "Payment Pending"],
      endkey: ["invoice\u9999", "Payment Pending\u9999"],
    });
    //console.log("here is your invoice data")
    console.log(tempInvoicesData);
    setInvoicesData(tempInvoicesData.rows);

    //when changing the tab, also change the state of the down button
  }, [index, subTabIndex]);

  const columns = [
    {
      property: "invoiceSerialNumber",
      align: "start",
      size: "24px",
    },
    {
      property: "invoiceDate",
      // size:"small",
      align: "start",
      footer: "Total",
      render: (datum) =>
        `${datum.invoiceDate.slice(8, 10)}-${datum.invoiceDate.slice(
          5,
          7
        )}-${datum.invoiceDate.slice(2, 4)}`,
    },
    {
      align: "start",
      property: "invoiceNumber",
      // size:"small",
    },
    {
      property: "pendingAmount",
      aggregate: "sum",
      align: "end",
      footer: { aggregate: true },
      render: (datum) => {
        // //console.log({datum});

        return Number(datum.pendingAmount).toFixed(2);
      },
    },
    {
      property: "dueDate",
      align: "end",
      // size:"small",
      render: (datum) => {
        return `${Math.round(
          Math.abs((new Date() - new Date(datum.dueDate)) / 86400000)
        )}`;
      },
    },
  ];
  const headerColumns = [
    {
      header: "S.No.",
      // size:"small",
    },
    {
      header: "Date",
    },
    {
      header: "Inv. No.",
    },
    {
      header: (
        <Text>
          Pending
          <br />
          Amount
        </Text>
      ),
      align: "end",
    },
    {
      header: (
        <Text>
          Due
          <br />
          Days
        </Text>
      ),
      align: "end",
      size: "30px",
    },
  ];

  const handleExpand = (clickName) => {
    let tempArray = expandNames;
    //console.log({ tempArray, clickName });

    if (tempArray.includes(clickName)) {
      tempArray = tempArray.filter((o) => o !== clickName);
    } else {
      tempArray.push(clickName);
    }
    //console.log({ tempArray });
    setExpandNames([...tempArray]);
  };

  return (
    <Box>
      <Header pad="small" background="brand">
        <UserAvatarBox />
        <Text size="large">Pending Payments</Text>
        <SearchComponent
          open={searchOpen}
          setOpen={(value) => setSearchOpen(value)}
          setSearchInput={(value) => setSearchInput(value)}
          searchInput={searchInput}
        />
        {/* <SyncButton /> */}
      </Header>
      <Box flex background="white" margin={{ top: "small" }} fill>
        <Tabs
          activeIndex={index}
          onActive={(ind) => setIndex(ind)}
          style={tabBoxStyle}
        >
          <Tab title="Buyers">{/* <DataContainer index={index} /> */}</Tab>
          <Tab title="Sellers">{/* <DataContainer index={index} /> */}</Tab>
        </Tabs>
        <Tabs
          activeIndex={subTabIndex}
          onActive={(ind) => setSubTabIndex(ind)}
          alignControls="stretch"
        >
          <Tab title="Company Wise" />
          <Tab title="Sub-Agent Wise" />
          <Tab title="Salesman Wise" />
        </Tabs>
      </Box>
      <Box margin={{ bottom: "100px" }}>
        <DataTable columns={headerColumns} />
        {invoicesData &&
          invoicesData.map((invObj, invIndex) => {
            let groupedData = invObj.value.reduce(function (res, pres) {
              // //console.log({res, pres});
              res.groupLevelTotal =
                (res.groupLevelTotal || 0) + pres.pendingAmount;
              res[pres.parentCompany_id] = res[pres.parentCompany_id] || {};
              res[pres.parentCompany_id].parentLevelTotal =
                (res[pres.parentCompany_id].parentLevelTotal || 0) +
                pres.pendingAmount;
              res[pres.parentCompany_id].parentName = pres.parentCompanyName;
              res[pres.parentCompany_id][pres.childCompany_id] =
                res[pres.parentCompany_id][pres.childCompany_id] || [];
              res[pres.parentCompany_id][pres.childCompany_id].push(pres);
              return res;
            }, Object.create(null));
            console.log({ groupedData });

            // The line below escapes regular expression special characters:
            // [ \ ^ $ . | ? * + ( )
            const escapedText = searchInput.replace(
              /[-\\^$*+?.()|[\]{}]/g,
              "\\$&"
            );

            // Create the regular expression with modified value which
            // handles escaping special characters. Without escaping special
            // characters, errors will appear in the console
            const exp = new RegExp(escapedText, "i");

            let companyMatch = false;
            for (let companyObj of invObj.value){
              if (exp.test(companyObj.parentCompanyName)){
                companyMatch = true;
                // //console.log({companyMatch, companyObj});
                break;
              }
              // //console.log({companyMatch, companyObj});
            }
            //console.log({companyMatch, invObj});
            if (exp.test(invObj.key[3]) || companyMatch) {
              return (
                <Box key={invIndex}>
                  {invObj.key[2] === "Grouped" && (
                    <Box
                      direction="row"
                      justify="between"
                      pad="small"
                      background={"Beige"}
                    >
                      <Box
                        direction="row"
                        onClick={() => handleExpand(invObj.key[3])}
                      >
                        {expandNames.includes(invObj.key[3]) ? (
                          <CaretUpFill />
                        ) : (
                          <CaretDownFill />
                        )}
                        <Text alignSelf="center">
                          <strong>{invObj.key[3]} </strong>
                        </Text>
                      </Box>
                      <Box direction="row" gap="small">
                        <Text>
                          <strong>
                            {groupedData.groupLevelTotal.toFixed(2)}
                          </strong>
                        </Text>
                        <ShareOption
                          onClick={() => {
                            navigate("/report-pdf", {
                              state: {
                                type: "group",
                                data: groupedData,
                                groupName: invObj.key[3],
                              },
                            });
                          }}
                        />
                      </Box>
                    </Box>
                  )}
                  {(expandNames.includes(invObj.key[3]) ||
                    invObj.key[2] !== "Grouped") &&
                    Object.keys(groupedData).map((parentCo_id, parentIndex) => {
                      // //console.log({parentCoName});
                      if (
                        parentCo_id !== "groupLevelTotal" &&
                        parentCo_id !== "parentName"
                      ) {
                        return (
                          <Box key={`${parentCo_id}${parentIndex}`}>
                            <Box direction="row" justify="between" pad="small" background={invObj.key[2] !== "Grouped" ? "#edf1fd" : "none"}>
                              <Box
                                direction="row"
                                onClick={() =>
                                  handleExpand(invObj.key[3] + parentCo_id)
                                }
                              >
                                {expandNames.includes(parentCo_id) ? (
                                  <CaretUpFill />
                                ) : (
                                  <CaretDownFill />
                                )}
                                <Text>
                                  {/* {index===0 ? "Buyer: " : "Seller: "} */}
                                  <strong>
                                    {groupedData[parentCo_id].parentName}
                                  </strong>
                                </Text>
                              </Box>
                              <Box direction="row" gap="small">
                                <Text>
                                  <strong>
                                    {groupedData[
                                      parentCo_id
                                    ].parentLevelTotal.toFixed(2)}
                                  </strong>
                                </Text>
                                <ShareOption
                                  onClick={() => {
                                    navigate("/report-pdf", {
                                      state: {
                                        type: "clientCompany",
                                        data: groupedData[parentCo_id],
                                      },
                                    });
                                  }}
                                />
                              </Box>
                            </Box>
                            {expandNames.includes(
                              invObj.key[3] + parentCo_id
                            ) &&
                              Object.keys(groupedData[parentCo_id]).map(
                                (childCo_id, childIndex) => {
                                  //console.log({ childCo_id });
                                  if (
                                    childCo_id !== "parentLevelTotal" &&
                                    childCo_id !== "parentName"
                                  ) {
                                    return (
                                      <Box key={`${childCo_id}${childIndex}`}>
                                        <Text>
                                          {index === 0 ? "Seller: " : "Buyer: "}
                                          {
                                            groupedData[parentCo_id][
                                              childCo_id
                                            ][0].childCompanyName
                                          }
                                        </Text>
                                        <DataTable
                                          key={invIndex}
                                          columns={columns}
                                          data={
                                            groupedData[parentCo_id][childCo_id]
                                          }
                                          sortable
                                        />
                                      </Box>
                                    );
                                  }
                                }
                              )}
                          </Box>
                        );
                      }
                    })}
                </Box>
              );
            }
          })}
      </Box>
      <NavFooter />
    </Box>
  );
}

export default MyReports;
