import React from "react";
import { Box, FormField, Select, TextInput, TextArea } from "grommet";
import { countryNames } from "../../constants";
const Address = (props) => {
  //Takes Inputs --> name, countryValue, pinCodeValue, cityValue, AddressValue, onChange

  return (
    <Box>
      <FormField name={`${props.name}.country`} label="Country">
        <Select
          name={`${props.name}.country`}
          options={countryNames}
          value={props.countryValue}
          onChange={props.onChange}
        />
      </FormField>
      <FormField name={`${props.name}.pinCode`} label="Pincode">
        <TextInput
          type="number"
          name={`${props.name}.pinCode`}
          validate={(fieldInputs, formInputs) => {
            return numberValidation(fieldInputs, formInputs);
          }}
          value={props.pinCodeValue}
          onChange={props.onChange}
          onBlur = {props.onBlur}


        />
      </FormField>
      <FormField name={`${props.name}.state`} label="State">
        <TextInput
          name={`${props.name}.state`}
          value={props.stateValue}
          onChange={props.onChange}
        />
      </FormField>
      <FormField name={`${props.name}.city`} label="City">
        <TextInput
          name={`${props.name}.city`}
          value={props.cityValue}
          onChange={props.onChange}
        />
      </FormField>
      <FormField name={`${props.name}.address`} label="Address">
        <TextArea
          name={`${props.name}.address`}
          value={props.addressValue}
          onChange={props.onChange}
        />
      </FormField>
    </Box>
  );
};

export default Address;
