import React, { useEffect, useState } from "react";
import {
  Form,
  FormField,
  Button,
  TextArea,
  DateInput,
  Box,
  Select,
  Header,
  Text,
  TextInput,
} from "grommet";
import getMasterDB from "../../database/getMasterDB";
import getRecords from "../../database/getRecords";
import { BackBox, SyncButton } from "../../components/Header/Header";
import { Configure, Add, Trash } from "grommet-icons";
import updateObjProp from "../../components/formComponents/updateObjProp/updateObjProp";
import { navigate } from "@reach/router";
import AmountField from "../../components/AmountField/AmountField";
import plusorminus from "../../images/plusorminus.png";

// setting box
const SettingsBox = (props) => {
  return (
    <Box direction="row" background="brand" pad="xsmall" round>
      <Configure />
      <Text margin={{ horizontal: "small" }}>Configure</Text>
    </Box>
  );
};

const Addbox = (props) => {
  return (
    <Box direction="row" background="brand" pad="xsmall" round>
      <Add />
      <Text margin={{ horizontal: "small" }}>Add Details</Text>
    </Box>
  );
};

const AddNewPayment = (props) => {
  let ownerGroupID = localStorage.getItem("ownerid");
  let userCode = localStorage.getItem("usercode");
  const [masterDB, setMasterDB] = useState();
  const [originalData, setOriginalData] = useState({
    allLists: {},
    data: {},
    sellerOptions: [],
    buyerOptions: [],
    invoicesData: [],
    invoiceOptions: [],
  });
  const [formData, setFormData] = useState({
    sellerDetails: {},
    buyerDetails: {},
    invoiceLevelDetail: [],
    paymentDate: new Date().toISOString(),
  });
  const [changeLog, setChangeLog] = useState({});
  console.log({ originalData, formData });
  useEffect(async () => {
    //if masterdb is empty then get the masterdb and assign it to masterdb
    if (!masterDB) {
      const tempMasterDB = await getMasterDB();
      setMasterDB(tempMasterDB);
      console.log("data got from master");
    }

    //this is for if user want to edit his invoice
    if (masterDB && props.type == "edit" && !originalData.allLists._id) {
      const tempOriginalData = await getRecords({
        db: masterDB,
        allLists: true,
        type: "payment",
        code: "",
      });
      console.log("got All Lists data");
      //Create originalData.sellerCodes array
      tempOriginalData.allLists.sellerCodes = (
        tempOriginalData.allLists.scgList || []
      ).concat(
        (tempOriginalData.allLists.bscgList || []).concat(
          (tempOriginalData.allLists.sccList || []).concat(
            tempOriginalData.allLists.bsccList || []
          )
        )
      );

      //Create originalData.buyerCodes array
      tempOriginalData.allLists.buyerCodes = (
        tempOriginalData.allLists.bcgList || []
      ).concat(
        (tempOriginalData.allLists.bscgList || []).concat(
          (tempOriginalData.allLists.bccList || []).concat(
            tempOriginalData.allLists.bsccList || []
          )
        )
      );
      tempOriginalData.sellerOptions = tempOriginalData.allLists.sellerCodes;
      tempOriginalData.buyerOptions = tempOriginalData.allLists.buyerCodes;
      setOriginalData(tempOriginalData);
    } else if (masterDB && !originalData.allLists._id) {
      const tempOriginalData = await getRecords({
        db: masterDB,
        allLists: true,
      });
      console.log(tempOriginalData);

      //Create originalData.sellerCodes array
      tempOriginalData.allLists.sellerCodes = (
        tempOriginalData.allLists.scgList || []
      ).concat(
        (tempOriginalData.allLists.bscgList || []).concat(
          (tempOriginalData.allLists.sccList || []).concat(
            tempOriginalData.allLists.bsccList || []
          )
        )
      );

      //Create originalData.buyerCodes array
      tempOriginalData.allLists.buyerCodes = (
        tempOriginalData.allLists.bcgList || []
      ).concat(
        (tempOriginalData.allLists.bscgList || []).concat(
          (tempOriginalData.allLists.bccList || []).concat(
            tempOriginalData.allLists.bsccList || []
          )
        )
      );
      tempOriginalData.sellerOptions = tempOriginalData.allLists.sellerCodes;
      tempOriginalData.buyerOptions = tempOriginalData.allLists.buyerCodes;
      // console.log(tempOriginalData);
      setOriginalData(tempOriginalData);
    }
    //if user want to edit
    if (props.type === "edit" && originalData.data._id) {
      console.log("edit is here");

      console.log("i am geting your data");
      console.log({ originalData });
      setFormData(originalData.data);
    } else if (originalData.allLists._id) {
      let paymentCount = await masterDB.find({
        selector: {
          type: {
            $eq: "payment",
          },
        },
      });

      let paymentNumber = ((paymentCount.docs.length || 0) + 1)
        .toString()
        .padStart(7, "0");
      setFormData({
        ...formData,
        paymentNumber: paymentNumber,
      });
    }
  }, [masterDB, originalData]);

  const handleSubmit = async (formInputs) => {
    // alert(
    //   " Payment form is under development, please check again tomorrow. Thank you for your understanding."
    // );
    try {
      formInputs.preventDefault();

      let latestDoc;

      if (props.type !== "edit") {
        latestDoc = formData;
        let RandomID = Math.random().toString(36).substr(2, 3);
        let ownerGroupID = localStorage.getItem("ownerid");
        let paymentDate =
          (formData.paymentDate &&
            formData.paymentDate.substr(0, 10).replace(/\-/g, "")) ||
          new Date().toISOString().substr(0, 10).replace(/\-/g, "");

        latestDoc.type = "payment";
        latestDoc._id = `payment:${ownerGroupID}_${paymentDate}_${formData.invoiceNumber}_${RandomID}`;
        latestDoc.updateLogs = [];
        // console.log(latestDoc);
        latestDoc.updateLogs.unshift({
          updateTime: new Date().toISOString(),
          userID: localStorage.getItem("userid"),
          userCode: localStorage.getItem("usercode"),
          updates: `New order with id: ${latestDoc._id} created`,
        });
      }
      //if invoice is edit then
      // else {
      //   latestDoc = await masterDB.get(formData._id);

      //   latestDoc.updateLogs.unshift({
      //     updateTime: new Date().toISOString(),
      //     userID: localStorage.getItem("userid"),
      //     userCode: localStorage.getItem("usercode"),
      //     updates: changeLog,
      //   });
      // }

      console.log("this is latest doc");
      console.log({ latestDoc });
      console.log("this is original data" + originalData);

      //write the payment document to database to get the _id

      let paymentId = await masterDB.put(latestDoc);
      let bulkDocsToWrite = [];
      console.log({ paymentId });
      //check & edit the status of the linked invoices, add payment object & last payment date.
      for (let invDetail of formData.invoiceLevelDetail) {
        console.log({ invDetail });
        let invoiceDoc = await masterDB.get(invDetail._id);
        invoiceDoc.pendingPayment = Number(invDetail.finalOutstandingAmount);
        if (Number(invDetail.finalOutstandingAmount) === 0) {
          invoiceDoc.status = "Payment Complete";
        }
        if (!invoiceDoc.paymentsDetails || !invoiceDoc.paymentsDetails[0]) {
          invoiceDoc.paymentsDetails = [];
        }
        invoiceDoc.paymentsDetails.push({
          _id: paymentId.id,
          amountAgainstInvoice: invDetail.amountAgainstInvoice,
          detailId: invDetail.detailId,
          paymentDate: formData.paymentDate,
          paymentNumber: formData.paymentNumber,
        });
        bulkDocsToWrite.push(invoiceDoc);
      }
      // update the last payment date for buyer

      let buyerDoc = await masterDB.get(formData.buyerDetails._id);
      if (buyerDoc.lastPaymentDate) {
        if (buyerDoc.lastPaymentDate < formData.paymentDate) {
          buyerDoc.lastPaymentDate = formData.paymentDate;
        }
      } else {
        buyerDoc.lastPaymentDate = formData.paymentDate;
      }
      console.log("updated buyer doc");
      console.log(buyerDoc);
      bulkDocsToWrite.push(buyerDoc);

      //push all the docs to db.
      console.log({ bulkDocsToWrite });

      await masterDB.bulkDocs(bulkDocsToWrite);

      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = async (inputValue, dateField) => {
    console.log(inputValue);

    let newValue = (inputValue.value || inputValue.target.value).replace(
      /,/g,
      ""
    );
    let fieldName =
      (inputValue.currentTarget && inputValue.currentTarget.name) ||
      (inputValue.target && inputValue.target.name) ||
      dateField;
    // console.log(inputValue.currentTarget);
    console.log(fieldName);
    console.log(newValue);

    let changedData = updateObjProp(
      changeLog,
      formData,
      newValue,
      fieldName,
      originalData
    );
    let newFieldName = fieldName.split(".");
    console.log(formData);
    if (changedData.error) {
      alert(changedData.error);
    } else {
      //all filed handlechage will go here

      //Handle change of seller code
      if (fieldName === "sellerDetails.code") {
        let dbType = "clientCompany";

        if (
          originalData.allLists.scgList.includes(newValue) ||
          originalData.allLists.bscgList.includes(newValue)
        ) {
          dbType = "clientGroup";
        }

        let sellerData = await getRecords({
          db: masterDB,
          allLists: false,
          type: dbType,
          code: newValue,
        });
        sellerData = sellerData.data;
        sellerData.subAgentCode = sellerData.primarySubAgent;

        changedData.formTemp.sellerDetails = sellerData;

        setFormData(changedData.formTemp);
        setChangeLog({
          ...changedData.changeTemp,
          sellerDetails: originalData.data && originalData.data.sellerDetails,
        });
      }

      //Handle change of buyer code
      else if (fieldName === "buyerDetails.code") {
        let dbType = "clientCompany";

        let buyerData = await masterDB.find({
          selector: {
            type: { $eq: dbType },
            code: { $eq: newValue },
          },
        });
        buyerData = buyerData.docs[0];

        changedData.formTemp.buyerDetails = buyerData;
        console.log({ buyerData });
        let invoicesData = await masterDB.find({
          selector: {
            "sellerDetails._id": { $eq: formData.sellerDetails._id },
            "buyerDetails._id": { $eq: buyerData._id },
            type: {
              $eq: "invoice",
            },
            status: { $eq: "Payment Pending" },
          },
        });
        invoicesData = invoicesData.docs;
        console.log({ invoicesData });

        let tempOriginalData = {
          ...originalData,
          invoicesData,
          invoiceOptions: invoicesData,
        };

        setOriginalData(tempOriginalData);

        setFormData(changedData.formTemp);
        setChangeLog({
          ...changedData.changeTemp,
          buyerDetails: originalData.data && originalData.data.buyerDetails,
        });
      }

      //Handle default
      else {
        setFormData(changedData.formTemp);
        setChangeLog({
          ...changedData.changeLog,
        });
      }
    }
  };

  const calculateGrandTotal = (tempFormData) => {
    tempFormData.invoiceLevelTotal = 0;
    for (let i = 0; i < tempFormData.invoiceLevelDetail.length; i++) {
      tempFormData.invoiceLevelTotal += Number(
        tempFormData.invoiceLevelDetail[i].amountAgainstInvoice || 0
      );
    }
    tempFormData.grandTotalPaymentAmount = Number(
      Math.round(
        (Number(tempFormData.invoiceLevelTotal || 0) +
          Number(tempFormData.onAccountAmount || 0)) *
          100
      ) / 100
    );
    return tempFormData;
  };

  return (
    <Box>
      <Header background="brand" pad="small">
        <BackBox />
        <Box>
          <Text>
            {props.type === "edit" ? "Edit Payment" : "Add New Payment"}
          </Text>
        </Box>
        <SyncButton />
      </Header>

      <Box fill overflow="auto" pad="small">
        <Form
          onSubmit={handleSubmit}
          validate="blur"
          onValidate={(validationResults) => {
            console.log("validationResults = ", validationResults);
          }}
        >
          <FormField
            name="paymentNumber"
            label="Payment Number"
            // validate= {(fieldInput, formInputs) => {
            //     return userCodeValidation(fieldInput, formInputs, "invoice", (originalData.allLists.invoiceNumbers || []),props.type)
            // }}
          >
            <TextInput
              name="paymentNumber"
              value={formData.paymentNumber || ""}
              onChange={handleChange}
            />
          </FormField>

          <Box direction="row" justify="between">
            <Text>
              <strong>Seller Information</strong>
            </Text>
            {/* <SettingsBox /> */}
          </Box>
          {/* seller information */}
          <FormField
            name="sellerDetails.code"
            label="Seller Code*"
            validate={[
              (name) => {
                if (!name) return "Please Select Seller code";
                return undefined;
              },
            ]}
          >
            <Select
              name="sellerDetails.code"
              options={originalData.sellerOptions || []}
              value={formData.sellerDetails && formData.sellerDetails.code}
              onChange={handleChange}
              required
              onSearch={(text) => {
                // The line below escapes regular expression special characters:
                // [ \ ^ $ . | ? * + ( )
                const escapedText = text.replace(
                  /[-\\^$*+?.()|[\]{}]/g,
                  "\\$&"
                );

                // Create the regular expression with modified value which
                // handles escaping special characters. Without escaping special
                // characters, errors will appear in the console
                const exp = new RegExp(escapedText, "i");
                originalData.sellerOptions =
                  originalData.allLists.sellerCodes.filter((o) => exp.test(o));
                setOriginalData({ ...originalData });
              }}
            />
          </FormField>

          <Box direction="row" justify="between">
            <Text>
              <strong>Buyer Information</strong>
            </Text>
            {/* <SettingsBox /> */}
          </Box>
          {/* buyer information */}

          <FormField
            name="buyerDetails.code"
            label="Buyer Code*"
            validate={[
              (name) => {
                if (!name) return "Please Select Buyer code";
                return undefined;
              },
            ]}
          >
            <Select
              name="buyerDetails.code"
              options={originalData.buyerOptions || []}
              value={formData.buyerDetails && formData.buyerDetails.code}
              onChange={handleChange}
              required
              onSearch={(text) => {
                // The line below escapes regular expression special characters:
                // [ \ ^ $ . | ? * + ( )
                const escapedText = text.replace(
                  /[-\\^$*+?.()|[\]{}]/g,
                  "\\$&"
                );

                // Create the regular expression with modified value which
                // handles escaping special characters. Without escaping special
                // characters, errors will appear in the console
                const exp = new RegExp(escapedText, "i");
                originalData.buyerOptions =
                  originalData.allLists.buyerCodes.filter((o) => exp.test(o));
                setOriginalData({ ...originalData });
              }}
            />
          </FormField>
          {/* details */}

          <FormField name="paymentMode" label="Payment Mode">
            <Select
              name="paymentMode"
              options={["cash", "cheque", "online", "PDC-Post Dated Cheque"]}
              value={formData.paymentMode || ""}
              onChange={handleChange}
            />
          </FormField>
          {/* <AmountField
            name="totalPayment"
            label="Total Payment Amt"
            numeralsValue={
              (formData.totalPayment && formData.totalPayment.amount) || ""
            }
            currencyValue={
              (formData.totalPayment && formData.totalPayment.uom) || "INR"
            }
            onChange={handleChange}
          /> */}
          <FormField
            name="instrumentNumber"
            label="Instrument/Ref Number"
            // validate= {(fieldInput, formInputs) => {
            //     return userCodeValidation(fieldInput, formInputs, "invoice", (originalData.allLists.invoiceNumbers || []),props.type)
            // }}
          >
            <TextInput
              name="instrumentNumber"
              value={formData.instrumentNumber || ""}
              onChange={handleChange}
            />
          </FormField>

          <FormField name="paymentDate" label="Cheque / Payment Date">
            <DateInput
              name="payment Date*"
              format="dd/mm/yyyy"
              value={formData.paymentDate || new Date().toISOString()}
              onChange={(e) => {
                handleChange(e, "paymentDate");
              }}
              open={open}
              dropProps={{ width: "medium" }}
              required
            />
          </FormField>
          {formData.invoiceLevelDetail &&
            formData.invoiceLevelDetail.map((invLevelObj, invLevelIndex) => (
              <Box
                key={invLevelObj.detailId}
                background="#F6F9F0"
                pad={{ vertical: "small", horizontal: "xsmall" }}
                margin="small"
                round={true}
              >
                <Box direction="row" justify="between" align="center">
                  <Text>Invoice Number</Text>
                  <Box
                    margin="xsmall"
                    onClick={() => {
                      let tempFormData = formData;
                      console.log({ tempFormData, invLevelIndex });
                      tempFormData.invoiceLevelDetail.splice(invLevelIndex, 1);
                      console.log({ tempFormData });
                      tempFormData = calculateGrandTotal(tempFormData);
                      setFormData({ ...tempFormData });
                    }}
                  >
                    <Trash size="medium" />
                  </Box>
                </Box>
                <FormField name="invoiceNumber">
                  <Select
                    name="invoiceNumber"
                    options={originalData.invoiceOptions || []}
                    labelKey={(labelInput) => {
                      console.log({ labelInput });
                      return `${labelInput.serialNumber} | ${
                        labelInput.invoiceNumber
                      } | ${labelInput.invoiceDate.substr(0, 10)}`;
                    }}
                    valueKey={{ key: "invoiceNumber", reduce: true }}
                    value={invLevelObj.invoiceNumber}
                    onChange={(invObj) => {
                      console.log({ invObj });
                      let tempFormData = formData;

                      let invoiceLevelTotals = {
                        earlierDebitsAndCreditsAmount: 0,
                        earlierCommissionAmountDebits: 0,
                        earlierReceivedPayments: 0,
                      };
                      if (invObj.option.debitsAndCredits) {
                        for (
                          let i = 0;
                          i < invObj.option.debitsAndCredits.length;
                          i++
                        ) {
                          if (
                            invObj.option.debitsAndCredits[invLevelIndex]
                              .chargeType == "Commission"
                          ) {
                            invoiceLevelTotals.earlierCommissionAmountDebits +=
                              Number(
                                invObj.option.debitsAndCredits[i]
                                  .chargeAmount || 0
                              );
                          } else {
                            invoiceLevelTotals.earlierDebitsAndCreditsAmount +=
                              Number(
                                invObj.option.debitsAndCredits[i]
                                  .chargeAmount || 0
                              );
                          }
                        }
                      }

                      if (invObj.option.paymentsDetails) {
                        for (let pytDetail of invObj.option.paymentsDetails) {
                          invoiceLevelTotals.earlierReceivedPayments += Number(
                            pytDetail.amountAgainstInvoice || 0
                          );
                        }
                      }

                      console.log({ invObj });
                      tempFormData.invoiceLevelDetail[invLevelIndex] = {
                        ...invLevelObj,
                        _id: invObj.option._id,
                        _rev: invObj.option._rev,
                        invoiceDate: invObj.option.invoiceDate,
                        invoiceNumber: invObj.option.invoiceNumber,
                        grandTotalInvoiceAmount: Number(
                          invObj.option.grandTotals.grandTotalInvoiceAmount
                        ),
                        earlierDebitsAndCreditsAmount:
                          invoiceLevelTotals.earlierDebitsAndCreditsAmount,
                        earlierReceivedPayments:
                          invoiceLevelTotals.earlierReceivedPayments,
                        earlierCommissionAmountDebits:
                          invoiceLevelTotals.earlierCommissionAmountDebits,
                        pendingPayment: Number(invObj.option.pendingPayment),
                        finalOutstandingAmount: Number(
                          Number(invObj.option.pendingPayment || 0) -
                            Number(
                              tempFormData.invoiceLevelDetail[invLevelIndex]
                                .amountAgainstInvoice || 0
                            )
                        ).toFixed(2),
                      };
                      setFormData({ ...tempFormData });
                    }}
                  />
                </FormField>
                {invLevelObj.invoiceDate && (
                  <Text margin="small">
                    Invoice Date: {invLevelObj.invoiceDate.substr(0, 10)}
                  </Text>
                )}
                <Box margin="small">
                  <Box
                    direction="row"
                    align="center"
                    justify="between"
                    margin={{ top: "xxsmall", bottom: "0px" }}
                  >
                    <Text>Invoice Amt.:</Text>
                    <Text>
                      {Number(
                        Math.round(
                          (invLevelObj.grandTotalInvoiceAmount || 0) * 100
                        ) / 100
                      )}
                    </Text>
                  </Box>
                  {invLevelObj.commission &&
                  invLevelObj.commission.collectForm == "Seller" ? (
                    <Text size="xsmall">
                      Commission not deducted in Invoice
                    </Text>
                  ) : (
                    <Text size="xsmall">
                      Commission Rs.{" "}
                      {Number(
                        Math.round(
                          ((invLevelObj.commission &&
                            invLevelObj.commission.amount) ||
                            0) * 100
                        ) / 100
                      )}
                      deducted in Invoice.
                    </Text>
                  )}
                  <Text></Text>
                  <Box
                    direction="row"
                    align="center"
                    justify="between"
                    margin={{ vertical: "xsmall" }}
                  >
                    <Text>Total DN/CN Amt.:</Text>
                    <Text>
                      {Number(
                        Math.round(
                          (invLevelObj.earlierDebitsAndCreditsAmount || 0) * 100
                        ) / 100
                      )}
                    </Text>
                  </Box>
                  <Box
                    direction="row"
                    align="center"
                    justify="between"
                    margin={{ vertical: "xsmall" }}
                  >
                    <Text>Total commission debits:</Text>
                    <Text>
                      {Number(
                        Math.round(
                          (invLevelObj.earlierCommissionAmountDebits || 0) * 100
                        ) / 100
                      )}
                    </Text>
                  </Box>
                  <Box
                    direction="row"
                    align="center"
                    justify="between"
                    margin={{ vertical: "xsmall" }}
                  >
                    <Text>
                      <strong>Pending Payment.:</strong>
                    </Text>
                    <Text>
                      <strong>
                        {Number(
                          Math.round((invLevelObj.pendingPayment || 0) * 100) /
                            100
                        )}
                      </strong>
                    </Text>
                  </Box>
                </Box>
                <FormField label="Amount against Invoice">
                  <TextInput
                    type="number"
                    name="amountAgainstInvoice"
                    textAlign="end"
                    value={invLevelObj.amountAgainstInv}
                    onChange={(agtAmt) => {
                      let tempFormData = formData;
                      tempFormData.invoiceLevelDetail[
                        invLevelIndex
                      ].amountAgainstInvoice = Number(agtAmt.target.value);
                      tempFormData.invoiceLevelDetail[
                        invLevelIndex
                      ].finalOutstandingAmount = Number(
                        (
                          Number(invLevelObj.pendingPayment || 0) -
                          Number(agtAmt.target.value || 0)
                        ).toFixed(2)
                      );
                      tempFormData = calculateGrandTotal(tempFormData);
                      console.log({ tempFormData });
                      setFormData({ ...tempFormData });
                    }}
                  />
                </FormField>
                <Box
                  direction="row"
                  align="center"
                  margin={{ vertical: "xxsmall" }}
                >
                  <Text>
                    <strong>Invoice Outstanding Amt.:</strong>
                  </Text>
                  <Text>
                    <strong>
                      {Number(
                        Math.round(
                          (invLevelObj.finalOutstandingAmount || 0) * 100
                        ) / 100
                      )}
                    </strong>
                  </Text>
                </Box>
              </Box>
            ))}
          <Box align="center" margin={{ vertical: "xxsmall" }}>
            <Button
              label="Add Invoice Level Data"
              onClick={() => {
                let tempFormData = formData;
                tempFormData.invoiceLevelDetail.push({
                  detailId:
                    "detail" + (tempFormData.invoiceLevelDetail.length + 1),
                });
                setFormData({ ...tempFormData });
              }}
            />
          </Box>
          <FormField label="On Account - Amount">
            <TextInput
              name="onAccountAmount"
              value={Number(formData.onAccountAmount) || 0}
              textAlign="end"
              onChange={(changeAmt) => {
                let tempFormData = formData;
                tempFormData.onAccountAmount = Number(changeAmt.target.value);
                tempFormData = calculateGrandTotal(tempFormData);
                setFormData({ ...tempFormData });
              }}
            />
          </FormField>

          <Box
            direction="row"
            align="center"
            justify="between"
            margin={{ top: "xsmall", bottom: "large" }}
          >
            <Box direction="row" align="center">
              <Text>
                <strong>
                  Total Payment <br /> Received
                </strong>
              </Text>
              <Text>
                <strong>:</strong>
              </Text>
            </Box>
            <Text>
              <strong>
                {Number(
                  Math.round((formData.grandTotalPaymentAmount || 0) * 100) /
                    100
                )}
              </strong>
            </Text>
          </Box>

          <Button
            primary
            fill="horizontal"
            label="Save"
            bottom={0}
            type="submit"
          />
        </Form>
      </Box>
    </Box>
  );
};

export default AddNewPayment;
