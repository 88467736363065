import React from "react";
import {
  Box,
  DateInput,
  Form,
  FormField,
  Select,
  Text,
  DataTable,
  TextArea,
  TextInput,
} from "grommet";

const columns = [
  {
    property: "invoiceSerialNumber",
    header: "S.No.",

    align: "start",
    size: "24px",
  },
  {
    property: "invoiceDate",
    // size:"small",
    header: "Date",

    align: "start",
    footer: "Total",
    render: (datum) =>
      `${datum.invoiceDate.slice(8, 10)}-${datum.invoiceDate.slice(
        5,
        7
      )}-${datum.invoiceDate.slice(2, 4)}`,
  },
  {
    align: "start",
    property: "invoiceNumber",
    // size:"small",
    header: "Inv. No.",
  },
  {
    property: "totalInvoiceAmount",
    header: <Text>Opening Amount</Text>,
    aggregate: "sum",
    align: "end",
    footer: { aggregate: true },
    render: (datum) => {
      // //console.log({datum});

      return Number(datum.totalInvoiceAmount).toFixed(2);
    },
  },
  {
    property: "pendingAmount",
    header: <Text>Pending Amount</Text>,
    aggregate: "sum",
    align: "end",
    footer: { aggregate: true },
    render: (datum) => {
      // //console.log({datum});

      return Number(datum.pendingAmount).toFixed(2);
    },
  },
  {
    property: "dueDate",
    align: "end",

    header: <Text>Due Days</Text>,
    // size:"small",
    render: (datum) => {
      return `${Math.round(
        Math.abs((new Date() - new Date(datum.dueDate)) / 86400000)
      )}`;
    },
  },
];
let marginTop = "7px";
let marginRight = "7px";
let marginBottom = "7px";
let marginLeft = "7px";
const getPageMargins = () => {
  return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
};
const CompanyWiseReportPDF = (props) => {
  console.log("hey this is your company wise data");
  console.log(props);
  const { data, detailedPdf, onAccountPdf, from } = props;
  const filteredObject = Object.keys(data).filter((key) => {
    return key !== "parentLevelTotal" && key !== "parentName";
  });

  console.log(filteredObject);
  return (
    <Box background="white" height={"100%"}>
      <style>{getPageMargins()}</style>

      <Box>
        <Text
          size={from === "company" ? "3xl" : "large"}
          alignSelf="center"
          margin="medium"
          decoration="underline"
        >
          {data.parentName}
        </Text>
        {from === "company" ? (
          <Box>
            <Text size="large" alignSelf="center">
              Bill-wise Details
            </Text>
            <Text size="large" alignSelf="center">
              Remaining Payment
            </Text>
          </Box>
        ) : (
          ""
        )}
        <Box>
          {filteredObject.map((companyId, index) => {
            console.log(data[companyId]);

            return (
              <Box key={data[companyId][0].childCompanyName} margin="small">
                <Text>
                  <strong>{data[companyId][0].childCompanyName}</strong>
                </Text>
                <DataTable
                  key={index}
                  columns={columns}
                  data={data[companyId]}
                  sortable
                />
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
export default CompanyWiseReportPDF;
