import React, { useEffect, useState } from "react";
import { Anchor, Box, Button, Header, Text } from "grommet";

import { FormView, Next } from "grommet-icons";

import { BackBox, NotificationsBox } from "../../components/Header/Header";
import getMasterDB from "../../database/getMasterDB";
import getRecords from "../../database/getRecords";
import NavFooter from "../../components/navFooter/navFooter";

export const Setting = () => {
  try {
    return (
      <Box>
        <Header background="brand" pad="small">
          <BackBox />
          <Box>
            <Text color="white">Setting</Text>
          </Box>
          <NotificationsBox />
        </Header>
        <Box
          margin={{ horizontal: "medium", top: "medium", bottom: "large" }}
          elevation="xsmall"
        >
          <Anchor href={`/settings/default`} textDecoration="none">
            <Box height="xxsmall" justify="center" border="bottom">
              <Text alignSelf="center">Default Settings</Text>
            </Box>
          </Anchor>
          <Anchor href={`/settings/other-charges`} textDecoration="none">
            <Box height="xxsmall" justify="center" border="bottom">
              <Text alignSelf="center">Invoice Other charge</Text>
            </Box>
          </Anchor>
        </Box>
        <NavFooter/>
      </Box>
    );
  } catch (err) {
    console.error(err);
    return err;
  }
};

export default Setting;
