import {
  Box,
  Button,
  DateInput,
  Form,
  FormField,
  Header,
  Image,
  Select,
  Text,
  TextInput,
} from "grommet";
import React, { useEffect, useState } from "react";
import getMasterDB from "../../database/getMasterDB";
import { BackBox, SyncButton } from "../../components/Header/Header";
import updateObjProp from "../../components/formComponents/updateObjProp/updateObjProp";
import { Trash } from "grommet-icons";
import plusorminus from "../../images/plusorminus.png";
import { navigate } from "@reach/router";

//Import this from global settings
const chargeTypeOptions = [
  "GR - Goods Return",
  "Discount",
  "Commission",
  "Tax",
  "Interest",
];

const AddDrCrNotes = (props) => {
  const [masterDB, setMasterDB] = useState();
  const [formData, setFormData] = useState({
    drCrNoteDate: new Date().toISOString(),
    chargeDetails: [],
  });
  const [originalData, setOriginalData] = useState({
    sellerOptions: [],
    buyerData: [],
    buyerOptions: [],
    invoicesData: [],
    invoiceOptions: [],
  });
  const [changeLog, setChangeLog] = useState({});

  useEffect(async () => {
    //get the connection to database function
    if (!masterDB) {
      let tempMasterDB = await getMasterDB();
      setMasterDB(tempMasterDB);
    }

    //populate the list of sellers
    if (masterDB && !originalData.sellerData) {
      let tempSellerData = await masterDB.find({
        selector: {
          type: {
            $eq: "clientCompany",
          },
          category: { $in: ["Seller", "Both - Buyer & Seller"] },
          status: { $ne: "Deleted" },
        },
      });

      let tempBuyerData = await masterDB.find({
        selector: {
          type: {
            $eq: "clientCompany",
          },
          category: { $in: ["Buyer", "Both - Buyer & Seller"] },
          status: { $ne: "Deleted" },
        },
      });

      setOriginalData({
        ...originalData,
        sellerData: tempSellerData.docs,
        sellerOptions: tempSellerData.docs,
        buyerData: tempBuyerData.docs,
        buyerOptions: tempBuyerData.docs,
      });
    }
    if (masterDB) {
      let drCrNoteNumberCount = await masterDB.find({
        selector: {
          type: {
            $eq: "drCrNote",
          },
        },
      });
      let drCrNoteNumberSerialNumber = (
        (drCrNoteNumberCount.docs.length || 0) + 1
      )
        .toString()
        .padStart(1, "0");
      setFormData({
        ...formData,
        drCrNoteNumber: Number(drCrNoteNumberSerialNumber),
      });
    }
  }, [masterDB]);

  const handleChange = async (inputValue) => {
    const newValue = (inputValue.value || inputValue.target.value).replace(
      /,/g,
      ""
    );
    const fieldName =
      (inputValue.currentTarget && inputValue.currentTarget.name) ||
      (inputValue.target && inputValue.target.name);

    let changedData = updateObjProp(
      changeLog,
      formData,
      newValue,
      fieldName,
      originalData
    );

    if (changedData.error) {
      alert(changedData.error);
    } else {
      setFormData(changedData.formTemp);
      setChangeLog({
        ...changedData.changeLog,
      });
    }
  };

  const handleSubmit = async (formInputs) => {
    formInputs.preventDefault();

    let latestDoc;
    let invDoc = formData.invoiceDetails.invObj;

    console.log({ formData });
    if (props.type != "edit") {
      latestDoc = formData;

      let RandomID = Math.random().toString(36).substr(2, 3);
      let ownerGroupID = localStorage.getItem("ownerid");
      let voucherDate =
        (formData.drCrNoteDate &&
          formData.drCrNoteDate.substr(0, 10).replace(/\-/g, "")) ||
        new Date().toISOString().substr(0, 10).replace(/\-/g, "");
      latestDoc.type = "drCrNote";
      latestDoc._id = `drCrNote:${ownerGroupID}_${voucherDate}_${formData.drCrNoteNumber}_${RandomID}`;
      latestDoc.updateLogs = [];
      // console.log(latestDoc);
      latestDoc.updateLogs.unshift({
        updateTime: new Date().toISOString(),
        userID: localStorage.getItem("userid"),
        userCode: localStorage.getItem("usercode"),
        updates: `New Dr/Cr Note with id: ${latestDoc._id} created`,
      });

      delete latestDoc.invoiceDetails.invObj;

      if (!invDoc.debitsAndCredits) {
        invDoc.debitsAndCredits = [];
      }
      invDoc.debitsAndCredits.push({
        drCrNote_id: latestDoc._id,
        drCrNoteNumber: latestDoc.drCrNoteNumber,
        drCrNoteDate: latestDoc.drCrNoteDate,
        chargeDetails: latestDoc.chargeDetails,
      });

      invDoc.pendingPayment = Number(
        formData.invoiceDetails.finalOutstandingAmount
      );
      if (invDoc.pendingPayment == 0) {
        invDoc.status == "Payment Complete";
      }
    }

    await masterDB.bulkDocs([latestDoc, invDoc]);

    navigate("/");
  };

  const calculateFinalBalance = (tempFormData) => {
    let totalPresentCharges = 0;
    for (let chargeObj of tempFormData.chargeDetails) {
      totalPresentCharges += Number(chargeObj.chargeAmount || 0);
    }

    let finalOutstandingAmount =
      Number(tempFormData.invoiceDetails.grandTotalInvoiceAmount || 0) -
      Number(tempFormData.invoiceDetails.earlierReceivedPayments || 0) +
      Number(tempFormData.invoiceDetails.earlierDrCrAmount || 0) +
      Number(totalPresentCharges || 0);

    tempFormData.invoiceDetails.finalOutstandingAmount = Number(
      Math.round(finalOutstandingAmount * 100) / 100
    );

    console.log(tempFormData.invoiceDetails.finalOutstandingAmount);

    return tempFormData;
  };

  return (
    <Box>
      <Header background="brand" pad="small">
        <BackBox />
        <Box>
          <Text>
            {props.type === "edit" ? "Edit Dr/Cr Note" : "New Dr/Cr Note"}
          </Text>
        </Box>
        <SyncButton />
      </Header>
      <Box fill overflow={"auto"} pad="small">
        <Form
          onSubmit={handleSubmit}
          validate="blur"
          onValidate={(validationResults) => {
            console.log("validation Results = ", validationResults);
          }}
        >
          <FormField
            name="drCrNoteNumber"
            label="Dr/Cr Note Number"
            direction="row"
            justify="between"
            align="center"
          >
            <TextInput
              type="number"
              name="drCrNoteNumber"
              value={formData.drCrNoteNumber}
              onChange={handleChange}
              required
            />
          </FormField>
          <FormField
            name="drCrNoteDate"
            label="Dr/Cr Note Date"
            direction="row"
            justify="between"
            align="center"
          >
            <DateInput
              name="drCrNoteDate"
              format="dd/mm/yyyy"
              value={formData.drCrNoteDate}
              onChange={(inputDate) => {
                setFormData({
                  ...formData,
                  drCrNoteDate: inputDate.value,
                });
              }}
              open={open}
              dropProps={{ width: "medium" }}
              required
            />
          </FormField>
          <FormField
            name="sellerDetails.code"
            label="Seller Code*"
            validate={[
              (name) => {
                if (!name) return "Please Select a different Seller & Retry";
                return undefined;
              },
            ]}
          >
            <Select
              name="sellerDetails.code"
              options={originalData.sellerOptions || []}
              labelKey="code"
              valueKey={{ key: "code", reduce: true }}
              value={formData.sellerDetails && formData.sellerDetails.code}
              onSearch={(text) => {
                // The line below escapes regular expression special characters:
                // [ \ ^ $ . | ? * + ( )
                const escapedText = text.replace(
                  /[-\\^$*+?.()|[\]{}]/g,
                  "\\$&"
                );

                // Create the regular expression with modified value which
                // handles escaping special characters. Without escaping special
                // characters, errors will appear in the console
                const exp = new RegExp(escapedText, "i");
                let tempOriginalData = originalData;
                tempOriginalData.sellerOptions =
                  tempOriginalData.sellerData.filter((o) => exp.test(o.code));
                setOriginalData({ ...tempOriginalData });
              }}
              onChange={(sellerObj) => {
                let tempFormData = formData;
                tempFormData.sellerDetails = sellerObj.option;
                setFormData({ ...tempFormData });
              }}
            />
          </FormField>
          <FormField
            name="buyerDetails.code"
            label="Buyer Code*"
            validate={[
              (name) => {
                if (!name) return "Please Select a different Buyer & Retry";
                return undefined;
              },
            ]}
          >
            <Select
              name="buyerDetails.code"
              options={originalData.buyerOptions || []}
              labelKey="code"
              valueKey={{ key: "code", reduce: true }}
              value={formData.buyerDetails && formData.buyerDetails.code}
              onSearch={(text) => {
                // The line below escapes regular expression special characters:
                // [ \ ^ $ . | ? * + ( )
                const escapedText = text.replace(
                  /[-\\^$*+?.()|[\]{}]/g,
                  "\\$&"
                );

                // Create the regular expression with modified value which
                // handles escaping special characters. Without escaping special
                // characters, errors will appear in the console
                const exp = new RegExp(escapedText, "i");
                let tempOriginalData = originalData;
                tempOriginalData.buyerOptions =
                  tempOriginalData.buyerData.filter((o) => exp.test(o.code));
                setOriginalData({ ...tempOriginalData });
              }}
              onChange={async (buyerObj) => {
                let tempFormData = formData;
                tempFormData.buyerDetails = buyerObj.option;

                let invoicesData = await masterDB.find({
                  selector: {
                    "sellerDetails._id": { $eq: formData.sellerDetails._id },
                    "buyerDetails._id": { $eq: buyerObj.option._id },
                    type: { $eq: "invoice" },
                    status: { $eq: "Payment Pending" },
                  },
                });

                let tempOriginalData = {
                  ...originalData,
                  invoicesData: invoicesData.docs,
                  invoiceOptions: invoicesData.docs,
                };

                setOriginalData(tempOriginalData);
                setFormData({ ...tempFormData });
              }}
            />
          </FormField>
          <FormField
            name="invoiceDetails.invoiceNumber"
            label="Invoice Number*"
            validate={[
              (name) => {
                // if(!name) return ("Please Select a different Buyer & Retry");
                return undefined;
              },
            ]}
          >
            <Select
              name="invoiceDetails.invoiceNumber"
              options={originalData.invoiceOptions || []}
              labelKey={(labelInput) => {
                // console.log({labelInput});
                return `${labelInput.invoiceSerialNumber} | ${
                  labelInput.invoiceNumber
                } | ${labelInput.invoiceDate.substr(0, 10)}`;
              }}
              valueKey={{ key: "invoiceNumber", reduce: true }}
              value={
                formData.invoiceDetails && formData.invoiceDetails.invoiceNumber
              }
              onSearch={(text) => {
                // The line below escapes regular expression special characters:
                // [ \ ^ $ . | ? * + ( )
                const escapedText = text.replace(
                  /[-\\^$*+?.()|[\]{}]/g,
                  "\\$&"
                );

                // Create the regular expression with modified value which
                // handles escaping special characters. Without escaping special
                // characters, errors will appear in the console
                const exp = new RegExp(escapedText, "i");
                let tempOriginalData = originalData;
                tempOriginalData.invoiceOptions =
                  tempOriginalData.invoicesData.filter(
                    (o) => exp.test(o.serialNumber) || exp.test(o.invoiceNumber)
                  );
                setOriginalData({ ...tempOriginalData });
              }}
              onChange={async (invObj) => {
                console.log({ invObj });

                let tempFormData = formData;

                let earlierDrCrAmount = 0;
                let earlierCommissionAmount = 0;

                if (invObj.option.debitsAndCredits) {
                  for (let drCrObj of invObj.option.debitsAndCredits) {
                    for (let chargeObj of drCrObj.chargeDetails) {
                      if (chargeObj.chargeType === "Commission") {
                        earlierCommissionAmount += Number(
                          chargeObj.chargeAmount || 0
                        );
                      } else {
                        earlierDrCrAmount += Number(
                          chargeObj.chargeAmount || 0
                        );
                      }
                    }
                  }
                }

                let earlierReceivedPayments = 0;

                if (invObj.option.paymentsDetails) {
                  for (let pytDetail of invObj.option.paymentsDetails) {
                    earlierReceivedPayments += Number(
                      pytDetail.amountAgainstInvoice || 0
                    );
                  }
                }

                tempFormData.invoiceDetails = {
                  invObj: invObj.option,
                  invoiceDate: invObj.option.invoiceDate,
                  invoiceNumber: invObj.option.invoiceNumber,
                  debitsAndCredits: invObj.option.debitsAndCredits || [],
                  commission: invObj.option.commission,
                  grandTotalInvoiceAmount: Number(
                    invObj.option.grandTotals.grandTotalInvoiceAmount
                  ),
                  earlierDrCrAmount,
                  earlierCommissionAmount,
                  earlierReceivedPayments,
                  finalOutstandingAmount: Number(invObj.option.pendingPayment),
                };
                console.log({ tempFormData });
                setFormData({ ...tempFormData });
              }}
            />
          </FormField>
          <Text margin="small">
            Invoice Date:{" "}
            {formData.invoiceDetails &&
              formData.invoiceDetails.invoiceDate.substr(0, 10)}
          </Text>
          <Box margin="small">
            <Box
              direction="row"
              align="center"
              justify="between"
              margin={{ top: "xxsmall", bottom: "0px" }}
            >
              <Text>Invoice Amt.:</Text>
              <Text>
                {(
                  Number(
                    formData.invoiceDetails &&
                      formData.invoiceDetails.grandTotalInvoiceAmount
                  ) || 0
                ).toFixed(2)}
              </Text>
            </Box>
            {formData.invoiceDetails &&
            formData.invoiceDetails.commission &&
            formData.invoiceDetails.commission.collectForm == "Seller" ? (
              <Text size="xsmall">Commission not deducted in Invoice</Text>
            ) : (
              <Text size="xsmall">
                Commission Rs.{" "}
                {(
                  Number(
                    formData.invoiceDetails &&
                      formData.invoiceDetails.commission &&
                      formData.invoiceDetails.commission.amount
                  ) || 0
                ).toFixed(2)}{" "}
                deducted in Invoice.
              </Text>
            )}
            <Box
              direction="row"
              align="center"
              justify="between"
              margin={{ vertical: "xsmall" }}
            >
              <Text>Earlier Dr/Cr Notes:</Text>
              <Text>
                {(
                  Number(
                    formData.invoiceDetails &&
                      formData.invoiceDetails.earlierDrCrAmount
                  ) || 0
                ).toFixed(2)}
              </Text>
            </Box>
            <Box
              direction="row"
              align="center"
              justify="between"
              margin={{ vertical: "xsmall" }}
            >
              <Text>Earlier Comm Debits:</Text>
              <Text>
                {(
                  Number(
                    formData.invoiceDetails &&
                      formData.invoiceDetails.earlierCommissionAmount
                  ) || 0
                ).toFixed(2)}
              </Text>
            </Box>
            <Box
              direction="row"
              align="center"
              justify="between"
              margin={{ vertical: "xsmall" }}
            >
              <Text>Total Payments Received:</Text>
              <Text>
                {(
                  Number(
                    formData.invoiceDetails &&
                      formData.invoiceDetails.earlierReceivedPayments
                  ) || 0
                ).toFixed(2)}
              </Text>
            </Box>
          </Box>
          {formData.chargeDetails &&
            formData.chargeDetails.map((chargeObj, chargeIndex) => (
              <Box
                direction="row"
                align="center"
                justify="between"
                key={chargeIndex}
                gap="small"
                margin={{ vertical: "small" }}
              >
                <Box
                  justify="center"
                  onClick={() => {
                    let tempFormData = formData;
                    tempFormData.chargeDetails.splice(chargeIndex, 1);
                    tempFormData = calculateFinalBalance(tempFormData);

                    setFormData({ ...tempFormData });
                  }}
                >
                  <Trash size="medium" />
                </Box>
                <Box>
                  <FormField name="chargeType" margin="none">
                    <Select
                      name={"chargeType" + chargeIndex}
                      options={chargeTypeOptions}
                      value={chargeObj.chargeType}
                      onChange={(inputVal) => {
                        console.log({ inputVal });
                        let tempFormData = formData;
                        tempFormData.chargeDetails[chargeIndex].chargeType =
                          inputVal.target.value;
                        setFormData({ ...tempFormData });
                      }}
                      required
                    />
                  </FormField>
                </Box>
                <Box
                  justify="center"
                  height="xxsmall"
                  width="xxsmall"
                  onClick={() => {
                    let tempFormData = formData;
                    tempFormData.chargeDetails[chargeIndex].chargeAmount =
                      Number(chargeObj.chargeAmount || 0) * -1;
                    tempFormData = calculateFinalBalance(tempFormData);
                    setFormData({ ...tempFormData });
                  }}
                >
                  <Image fit="contain" src={plusorminus} />
                </Box>
                <Box>
                  <FormField name="chargeAmount" margin="none">
                    <TextInput
                      type="number"
                      name={"chargeAmount" + chargeIndex}
                      value={chargeObj.chargeAmount}
                      onChange={(inputAmt) => {
                        let tempFormData = formData;
                        tempFormData.chargeDetails[chargeIndex].chargeAmount =
                          Number(inputAmt.target.value || 0);

                        tempFormData = calculateFinalBalance(tempFormData);

                        setFormData({ ...tempFormData });
                      }}
                      required
                    />
                  </FormField>
                </Box>
              </Box>
            ))}
          <Box align="center">
            <Button
              label="Add Disc / Charge / Comm Entry"
              disabled={
                !formData.invoiceDetails ||
                !formData.invoiceDetails.invoiceNumber
              }
              onClick={() => {
                let tempFormData = formData;
                let otherChargeDetail = {
                  chargeNumber: tempFormData.chargeDetails.length + 1,
                  chargeType: "Discount",
                };
                tempFormData.chargeDetails.push(otherChargeDetail);
                setFormData({ ...tempFormData });
              }}
            />
          </Box>
          <Box
            direction="row"
            align="center"
            justify="between"
            margin={{ vertical: "xsmall" }}
          >
            <Text>
              <strong>Invoice Outstanding Amount:</strong>
            </Text>
            <Text>
              <strong>
                {(
                  Number(
                    formData.invoiceDetails &&
                      formData.invoiceDetails.finalOutstandingAmount
                  ) || 0
                ).toFixed(2)}
              </strong>
            </Text>
          </Box>
          <Button
            primary
            fill="horizontal"
            label="Save"
            bottom={0}
            type="submit"
          />
        </Form>
      </Box>
    </Box>
  );
};

export default AddDrCrNotes;
