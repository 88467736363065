import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Form,
    FormField,
    Grommet,
    Header,
    Select,
    Spinner,
    Text,
    TextArea,
    TextInput
} from 'grommet';
import { FormUp, FormDown, MailOption, Phone, Trash, UserManager } from 'grommet-icons';
import { navigate } from '@reach/router';
import { EmailInput } from 'grommet-controls';

import getUserDB from '../../database/getUserDB';
import getMasterDB from '../../database/getMasterDB';
import getRecords from '../../database/getRecords';

import { BackBox, NotificationsBox } from '../../components/Header/Header';
import { customTheme, countryCodes, countryNames } from '../../constants';
import { numberValidation, userCodeValidation } from '../../database/inputValidations';
import { LoadingBox } from '../../components/LoadingBox/LoadingBox';

export const MyProfile = (props) => {
    try {
        // console.log("my profile");
        // console.log(props);

        const [moreDetails, setMoreDetails] = useState(false);
        const [formData, setFormData] = useState();
        const [changeLog, setChangeLog] = useState({});
        const [masterDB, setMasterDB] = useState();

        const userID = localStorage.getItem('userid');
        const userCode = localStorage.getItem('usercode');
        const userEmail = localStorage.getItem('user');

        let groupDB;

        useEffect(async() => {
            // console.log("Inside useEffect");

            let tempMasterDB;
            if(!masterDB){        
                // console.log("Getting MasterDB");
                tempMasterDB = await getMasterDB();
                setMasterDB(tempMasterDB);
            }

            if(masterDB && !formData){
                console.log("Getting groupData");
                groupDB = await getRecords({db: masterDB, allLists: false, type:"ownerGroup"})
                console.log({groupDB})
                setFormData(groupDB.data);
            }

        });

        const updateObjProp = (changeTemp, formTemp , value, propPath) => {
            
            if(changeTemp.error){
                return({error: `Handle Array.`})
            }
            
            const [head, ...rest] = propPath.split('.');

            console.log({changeTemp, formTemp, head, rest, value});

            if(Array.isArray(formTemp[head])){
                // console.log(`${head} is Array.`)
                return({error: `${head} is Array.`})
            }

            

            if(!rest.length){
                changeTemp[head] = value;
                formTemp[head] = value;
            } else {
                // console.log({head});
                // console.log(formTemp[head], changeTemp[head]);

                formTemp[head] = formTemp[head] || {};
                changeTemp[head] = changeTemp[head] || {};

                console.log({formTemp, changeTemp});

                updateObjProp(changeTemp[head], formTemp[head], value, rest.join('.'));
            }

            return ({changeTemp, formTemp});
        }
        
        //Handle any Input change in the Form
        const handleChange = (inputValue) => {
            inputValue.persist();
            
            let newValue = inputValue.target.value;
            const changedData = updateObjProp(changeLog, formData, newValue, inputValue.target.name);
            
            // console.log({changedData, inputValue});

            if(changedData.error) {
                alert(changedData.error)
            } else {
                setFormData(changedData.formTemp);
                setChangeLog(() => ({
                    ...changedData.changeTemp
                }));
                // console.log("Both states changes", {changeLog, formData});
            }
            
        }

        const handleDelete = () => {

            //Confirm user if they want to delete & tell them their all data will be lost.
                //Ask them to input phrase "delete <userCode>" to confirm delete

            //Check if the user is Admin
                //Allow delete if user is non-admin

                //If user is Admin as them to enter phrase "delete <groupCode>" to confirm deletion.

            alert("Contact Admin to Delete");
        }

        //Handle Form Submit button Click.
        const submit = async formInput => {
            // console.log({formData, formInput});
            
            //If no changes just return the old value.
            if (!changeLog){
                alert("Data Succesfully Updated");
            } else {

                let latestDoc = await getRecords({db: masterDB, type:"id", id:formData._id});

                if (formData._rev === latestDoc._rev){

                    //SHOULD NOT ATTEMPT IF USER IS OFFLINE
                    //If user data update, update the _users database
                    if (changeLog.teamMembers) {
                        let userDB = await getUserDB();

                        if(changeLog.teamMembers.userID.userName){
                            await userDB.putUser(userEmail, {
                                userName: changeLog.teamMembers.userID.userName
                            }).then((res) => {
                                localStorage.setItem('username', changeLog.teamMembers.userID.userName);
                                // console.log({res}, "new user name", changeLog.teamMembers.userID.userName);
                                //userName = changeLog.teamMembers.userID.userCode; //If & when needed.
                            })
                        }
                        
                        if(changeLog.teamMembers.userID.userCode){
                            await userDB.putUser(userEmail, {
                                userCode: changeLog.teamMembers.userID.userCode
                            }).then((res) => {
                                localStorage.setItem('usercode', changeLog.teamMembers.userID.userCode);
                                // console.log({res}, "new user code", changeLog.teamMembers.userID.userCode);
                                userCode = changeLog.teamMembers.userID.userCode;
                            })
                        }

                        if(changeLog.teamMembers.userID.user){

                            await userDB.changeUsername(userEmail, changeLog.teamMembers.userID.user)
                                            .then((res) => {
                                                localStorage.setItem('user', changeLog.teamMembers.userID.user);
                                                // console.log({res}, "new user", changeLog.teamMembers.userID.user);
                                                userEmail = changeLog.teamMembers.userID.user;
                                            })
                        }
                        //Update the update log with changes log.
                        let userChangeLog = {
                            updateTime  : new Date().toISOString(),
                            userID      : userID,
                            userCode    : userCode,
                            updates     : changeLog.teamMembers, 
                        }
                        await userDB.getUser(userEmail)
                                    .then(async doc => { 
                                        await userDB.putUser(userEmail, {
                                            updateLogs: [userChangeLog, ...doc.updateLogs]
                                        });
                                        // console.log("Change Logs Updated")
                                    });
                    }

                    //Update the update log
                    let groupChangeLogs = {
                        updateTime  : new Date().toISOString(),
                        userID      : userID,
                        userCode    : userCode,
                        updates     : changeLog, 
                    }

                    let tempUserList;
                    //If the userCode is changed, update the userList.
                    if(changeLog.teamMembers[userID].userCode) {
                        tempUserList = formData.userList;
                        delete tempUserList[userCode];
                        tempUserList[changeLog.teamMembers[userID].userCode] = userID;
                        groupChangeLogs.updates.userList = tempUserList;
                    }
                    
                    let updateData;
                    setFormData((formData) => {
                                    updateData = {
                                        ...formData,
                                        userList: tempUserList || formData.userList,
                                        updateLogs: [groupChangeLogs, ...formData.updateLogs]
                                    }
                                    return updateData;
                                } 
                    );

                    //Update the Master-Group Document
                    await masterDB.put(updateData)
                    
                    //set changeLog state to empty state.
                    setChangeLog();

                    alert("Data Succesfully Updated");

                } else {
                    alert (`Data recently updated by ${latestDoc.updateLogs[0].userCode} on ${latestDoc.updateLogs[0].updateTime}. Please sync your database & try again.`)
                }
            }
        }

        return (
            <Grommet full={true} theme={customTheme}>
            <Box fill>
                <Box flex overflow="auto">
                    <Header pad="small" background="brand">
                        <BackBox />
                        <Box>
                            <Text color="white">My Profile</Text>
                        </Box>
                        <NotificationsBox />
                    </Header>
                    <Box fill="horizontal" background="#F1F1F1" direction="row" justify="center" pad="medium">
                        <Box round="full" pad="medium" background="brand" alignSelf="center">
                            <UserManager margin="medium" size="large"/>
                        </Box>
                    </Box>
                    <Box alignSelf="center" pad="medium" fill="horizontal">
                    {!formData && (
                        <LoadingBox />
                    )}
                    {formData && (
                    <Form 
                        validate="change" 
                        id="my-profile" 
                        onSubmit={submit}
                    >
                        {/* THE USER SECTION EDIT SHOULD BE DISABLED IF USER IS OFFLINE */}
                        <FormField name={`teamMembers.${userID}.userName`} label="My Name*">
                            <TextInput name={`teamMembers.${userID}.userName`} value={formData.teamMembers[userID].userName} onChange={handleChange} required/>
                        </FormField>
                        <FormField 
                            name={`teamMembers.${userID}.userCode`} 
                            label="My User Code*" 
                            validate = {(fieldInputs, formInputs) => {
                                return userCodeValidation(fieldInputs, formInputs);
                            }}
                        >
                            <TextInput name={`teamMembers.${userID}.userCode`} value={formData.teamMembers[userID].userCode} onChange={handleChange} required/>
                        </FormField>
                        <FormField name={`teamMembers.${userID}.user`} label="My e-mail*">
                            <EmailInput name={`teamMembers.${userID}.user`} margin="small" icon={<MailOption />} type="email" value={formData.teamMembers[userID].user} onChange={handleChange} required/>
                        </FormField>
                        <Box>
                            <Text>My Phone Number*</Text>
                            <Box direction="row">
                                <Box width="small">
                                    <Select options={countryCodes} name={`teamMembers.${userID}.userPhone.isdCode`} value={formData.teamMembers[userID].userPhone.isdCode} margin={{right: "small"}} onChange={handleChange} required/>
                                </Box>
                                <FormField 
                                    name={`teamMembers.${userID}.userPhone.phoneNumber`}
                                    validate = {(fieldInputs, formInputs) => {
                                        return numberValidation(fieldInputs, formInputs, 10);
                                    } }
                                >
                                    <TextInput 
                                        icon={<Phone />} 
                                        name={`teamMembers.${userID}.userPhone.phoneNumber`} 
                                        value={formData.teamMembers[userID].userPhone.phoneNumber} 
                                        onChange={handleChange}
                                        required 
                                    />
                                </FormField>
                            </Box>
                        </Box>

                        <Box margin="small" direction="row" justify="center" onClick = {() => setMoreDetails(moreDetails => !moreDetails)}>
                            <Text color="brand">See Group Details</Text>
                            {!moreDetails &&
                                <FormDown color="brand"/>
                            }
                            {moreDetails &&
                                <FormUp color="brand"/>
                            }
                        </Box>
                        
                        {/* Expand Form only if user wants to add more data */}
                        {moreDetails &&
                            <Box>
                                <FormField name="groupDetails.groupName" label="My Group Name*">
                                    <TextInput name="groupDetails.groupName" value={formData.groupDetails.groupName} onChange={handleChange} required/>
                                </FormField>
                                <FormField 
                                    name="groupDetails.groupCode" 
                                    label="My Group Code*"
                                    validate={(fieldInputs, formInputs) => {
                                        return userCodeValidation(fieldInputs, formInputs);
                                    }}
                                >
                                    <TextInput name="groupDetails.groupCode" value={formData.groupDetails.groupCode} onChange={handleChange} required/>
                                </FormField>
                                <FormField name="groupDetails.groupEmail" label="My Group e-mail*">
                                    <EmailInput name="groupDetails.groupEmail" margin="small" icon={<MailOption />} value={formData.groupDetails.groupEmail} onChange={handleChange} required/>
                                </FormField>
                                <Box>
                                    <Text>Group Phone Number*</Text>
                                    <Box direction="row">
                                        <Box width="small" margin={{bottom: "medium"}}>
                                            <Select options={countryCodes} name="groupDetails.groupPhone.isdCode" value={formData.groupDetails.groupPhone.isdCode} margin={{right: "small"}} onChange={handleChange} required/>
                                        </Box>
                                        <FormField 
                                            name = "groupDetails.groupPhone.phoneNumber"
                                            validate = {(fieldInputs, formInputs) => {
                                                return numberValidation(fieldInputs, formInputs, 10);
                                            }}
                                        >
                                            <TextInput 
                                                icon={<Phone />} 
                                                name="groupDetails.groupPhone.phoneNumber" 
                                                value={formData.groupDetails.groupPhone.phoneNumber} 
                                                onChange={handleChange} 
                                                required
                                            />
                                        </FormField>
                                    </Box>
                                </Box>
                                <FormField name="groupDetails.groupCountry" label="Country">
                                    <Select options={countryNames} name="groupDetails.groupCountry" value={formData.groupDetails.groupCountry} onChange={handleChange} />
                                </FormField>
                                <FormField 
                                    name="groupDetails.groupPincode" 
                                    label="Pincode"
                                    validate = {(fieldInputs, formInputs) => {
                                        return numberValidation(fieldInputs, formInputs, 6)
                                    }}
                                >
                                    <TextInput 
                                        name="groupDetails.groupPincode" 
                                        value={formData.groupDetails.groupPincode} 
                                        onChange={handleChange} 
                                    />
                                </FormField>
                                <FormField name="groupDetails.groupCity" label="City">
                                    <TextInput name="groupDetails.groupCity" value={formData.groupDetails.groupCity} onChange={handleChange} /> 
                                </FormField>
                                <FormField name="groupDetails.groupAddress" label="Address">
                                    <TextArea name="groupDetails.groupAddress" value={formData.groupDetails.groupAddress} onChange={handleChange}/>
                                </FormField>
                                {/* <FormField name="userStatus"></FormField> */}
                                <Box margin="medium" pad="small">
                                    <Box direction="row" justify="center" onClick={handleDelete}>
                                        <Trash color="#BE2625"/>
                                        <Text color="#BE2625">&nbsp; Delete User</Text>
                                    </Box>
                                </Box>
                            </Box>
                        }
                    </Form>
                    )}
                    </Box>
                </Box>
                <Button
                    disabled = { !formData } 
                    primary 
                    fill="horizontal" 
                    label="Save" 
                    bottom="0" 
                    type="submit" 
                    form="my-profile" 
                /> 
            </Box>
            </Grommet>
        )
        } catch (err) {
            console.error(err);
            return err;
        }
}

export default MyProfile;