import React, { useEffect, useState } from "react";
import {
  Anchor,
  Box,
  Button,
  Form,
  FormField,
  Header,
  Layer,
  Select,
  Text,
  TextInput,
} from "grommet";
import {
  Add,
  FormUp,
  FormDown,
  FormClose,
  MailOption,
  Next,
  Trash,
} from "grommet-icons";
import { userCodeValidation } from "../../database/inputValidations";
import { EmailInput } from "grommet-controls";
import PhoneNumber from "../../components/PhoneNumber/PhoneNumber";
import getMasterDB from "../../database/getMasterDB";
import getRecords from "../../database/getRecords";
import { set } from "react-hook-form";
import { BackBox, NotificationsBox } from "../../components/Header/Header";

const localUserid = localStorage.getItem("userid");

const MemberDetailCard = (props) => {
  console.log({props});
  //userID if its addition of new user.
  if (!props.userData) {
  }

  return (
    <Box pad="medium" background="beige" margin="xsmall">
      <Box direction="row" justify="between" onClick={() => props.onExpand()}>
        <Text>{props.userData.userName}</Text>
        <FormClose />
      </Box>
      <Form>
        <FormField
          label="Team Member Name*"
          disabled={props.userData.userID === localUserid}
        >
          <TextInput
            name={`teamMembers.${props.userData.userID}.userName`}
            value={props.userData.userName}
            // onChange={() => props.onChange}
            disabled={true}
          />
        </FormField>
        <FormField
          label="Team Member Code*"
          disabled={props.userData.userID === localUserid}
          // validate={(fieldInputs, formInputs) => {
          //   return userCodeValidation(fielInputs, formInputs);
          // }}
        >
          <TextInput
            name="userName"
            value={props.userData.userID}
            // onChange={props.onChange}
            disabled={true}
          />
        </FormField>
        <FormField
          label="Member Email"
          disabled={props.userData.userID === localUserid}
        >
          <EmailInput
            name={`teamMembers.${props.userData.userID}.user`}
            value={props.userData.user}
            // onChange={props.onChange}
            icon={<MailOption />}
            disabled={true}
          />
        </FormField>
        <PhoneNumber
          label="Member Phone Number"
          name={`teamMembers.${props.userData.userID}.userPhone`}
          isdCodeValue={
            props.userData.userPhone && props.userData.userPhone.isdCode
          }
          phoneNumberValue={
            props.userData.userPhone && props.userData.userPhone.phoneNumber
          }
          // onChange={props.onChange}
          disabled={true}
        />
        <FormField label="Status" disabled={true}>
          <Select
            disabled={true}
            options={["Active", "Deleted"]}
            value={
              props.userData.permissions && props.userData.permissions[0].status
            }
          />
        </FormField>
        {/* <Box margin="medium" pad="small">
          <Box direction="row" justify="center" onClick={props.handleDelete}>
            <Trash color="#BE2625" />
            <Text color="#BE2625">&nbsp; Delete Company</Text>
          </Box>
        </Box>
        <Box justify="center" background="beige">
          <Button primary type="submit" label="Save" disabled={true} />
        </Box> */}
      </Form>
    </Box>
  );
};

const MyTeamCard = (inputs) => {
  // console.log(inputs);
  let userID = inputs.userData.userID;
  return (
    <Button
    // onClick={inputs.onClick(inputs.userData.userID)}
    >
      <Box
        pad="medium"
        direction="row"
        justify="between"
        fill="horizontal"
        elevation="xsmall"
        onClick={() => inputs.onExpand(userID)}
      >
        <Box>
          <Box>
            <strong>{inputs.userData.userName}</strong>
          </Box>
          <Box>User Details</Box>
        </Box>
        <Box justify="center">
          <FormDown margin="auto" size="large" />
        </Box>
      </Box>
    </Button>
  );
};

const MyTeam = (props) => {
  try {
    const [expandID, setExpandID] = useState();
    const [groupData, setGroupData] = useState();
    console.log({expandID, groupData});

    useEffect(async () => {
      console.log("---Inside use effect");
      if (!groupData) {
        let masterDB = await getMasterDB();
        
        let tempGroupData = await
          masterDB.find({
            selector:{
              type:{
                $eq: "ownerGroup"
              }
            }
          })
        
        setGroupData(tempGroupData.docs[0]);
      }
    }, []);

    const handleChange = () => {
      // console.log("Inside handle Change")
    };

    const handleDelete = () => {
      // console.log("Inside handleDelete")
      alert("Contact your Admin.");
    };

    // console.log(groupData);

    const handleExpand = (userID) => {
      // console.log("Hanlde Expand", {userID})
      if (!userID) {
        // console.log("true", {expandID})
        setExpandID();
      } else {
        // console.log("false", {expandID}, userID)
        setExpandID(userID);
      }
    };

    return (
      <Box>
        <Header background="brand" pad="small">
          <BackBox />
          <Box>
            <Text color="white">My Team</Text>
          </Box>
          <NotificationsBox />
        </Header>
        <Box>
          {groupData &&
            groupData.teamMembers.map((userData, userIndex) => {
              if (expandID === userData.userID) {
                // console.log("Expand Yes");
                return (
                  <MemberDetailCard
                    key={userData.userID}
                    userData={userData}
                    onExpand={handleExpand}
                    // onChange={handleChange}
                    // handleDelete={handleDelete}
                  />
                );
              } else {
                // console.log("Expand No");
                return (
                  <MyTeamCard
                    key={userData.userID}
                    userData={userData}
                    onExpand={handleExpand}
                  />
                );
              }
            })}
        </Box>

        {/* Below to activated When new user addition module has been added. */}
        {/* <Box direction="row" justify="end" >
                    <Box background="brand" round="full" elevation="small" onClick={() => setAddMemberLayer((addMemberLayer) => !addMemberLayer)}>
                        <Button type="button" margin="auto" >
                            <Add margin="auto" size="large"/>
                        </Button>
                    </Box>
                </Box>
                {addMemberLayer && (
                    <Layer
                        position="center"
                        onClickOutside = {onClose}
                        onEsc={onClose}
                    >
                        <MemberDetailCard userData={{}} onClick={onClose}/>
                    </Layer>

                )} */}
      </Box>
    );
  } catch (err) {
    console.error(err);
  }
};

export default MyTeam;
