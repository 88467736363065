import React from "react";
import {
  Box,
  DateInput,
  Form,
  FormField,
  Select,
  Text,
  DataTable,
  TextArea,
  TextInput,
} from "grommet";
import CompanyWiseReportPDF from "./CompanyWiseReportPDF";

let marginTop = "7px";
let marginRight = "7px";
let marginBottom = "7px";
let marginLeft = "7px";
const getPageMargins = () => {
  return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
};

function GroupWiseReportPdf(props) {
  console.log("hey this is your group wise data");
  const { data, detailedPdf, onAccountPdf, groupName } = props;
  console.log({ data, detailedPdf, onAccountPdf, groupName });
  const filteredObject = Object.keys(data).filter((key) => {
    return key != "groupLevelTotal";
  });
  console.log(filteredObject);
  return (
    <Box background="white" height={"100%"}>
      <style>{getPageMargins()}</style>

      <Box>
        <Text
          size="3xl"
          alignSelf="center"
          margin="medium"
          decoration="underline"
        >
          {groupName}
        </Text>

        <Text size="large" alignSelf="center">
          Bill-wise Details
        </Text>
        <Text size="large" alignSelf="center">
          Remaining Payment
        </Text>
        <Box>
          {filteredObject.map((companyId, index) => {
            console.log(data[companyId]);
            return (
              <CompanyWiseReportPDF
                detailedPdf={detailedPdf}
                onAccountPdf={onAccountPdf}
                data={data[companyId]}
                from="group"
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}

export default GroupWiseReportPdf;
