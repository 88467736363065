import React, { useState, useEffect } from "react";
import {
  Form,
  FormField,
  Button,
  TextArea,
  DateInput,
  Box,
  Select,
  Header,
  Text,
  TextInput,
  Image,
} from "grommet";
import { BackBox, SyncButton } from "../../components/Header/Header";
import {
  Configure,
  Add,
  AddCircle,
  SubtractCircle,
  Trash,
} from "grommet-icons";
import PaymentPeriod from "../../components/PaymentPeriod/PaymentPeriod";
import AmountField from "../../components/AmountField/AmountField";
import getMasterDB from "../../database/getMasterDB";
import getRecords from "../../database/getRecords";
import updateObjProp from "../../components/formComponents/updateObjProp/updateObjProp";
import {
  numberValidation,
  userCodeValidation,
  decimalNumberValidation,
} from "../../database/inputValidations";
import { navigate } from "@reach/router";
import { defaultCurrency, packageWeightuom, uomOptions } from "../../constants";
import QuantityInput from "../../components/formComponents/QuantityInput/QuantityInput";
import RateInput from "../../components/formComponents/RateInput/RateInput";
import plusorminus from "../../images/plusorminus.png";
import { NumberInput } from "grommet-controls";

// setting box
const SettingsBox = (props) => {
  return (
    <Box direction="row" background="brand" pad="xsmall" round>
      <Configure />
      <Text margin={{ horizontal: "small" }}>Configure</Text>
    </Box>
  );
};

const Addbox = (props) => {
  return (
    <Box direction="row" background="brand" pad="xsmall" round>
      <Add />
      <Text margin={{ horizontal: "small" }}>Add product</Text>
    </Box>
  );
};

function AddNewInvoice(props) {
  let ownerGroupID = localStorage.getItem("ownerid");

  //console.log(props);
  const [masterDB, setMasterDB] = useState();
  const [originalData, setOriginalData] = useState({
    allLists: {},
    data: {},
    groupSettings: {
      invoiceOtherCharges: [],
    },
    buyerOptions: [],
    sellerOptions: [],
  });
  const [formData, setFormData] = useState({
    salesManAccount: {
      userCode: localStorage.getItem("usercode"),
      user: localStorage.getItem("user"),
      userName: localStorage.getItem("username"),
      userID: localStorage.getItem("userid"),
    },
    sellerDetails: {},
    buyerDetails: {},
    deliveryDetails: {
      dispatchDate: new Date().toISOString(),
    },
    productsArray: [],
    otherChargesArray: [],
    invoiceDate: new Date().toISOString(),
    status: "Payment Pending",
    commission: {
      collectFrom: "Seller",
      accountOf: "Seller",
      currency: "INR",
      amount: 0,
      percentage: 2,
      calculateOn: "Gross Amount",
    },
    invoiceDue: {
      dueNumeral: 0,
      metric: "Days",
      dueDate: new Date().toISOString(),
      calculateFrom: "Invoice Date",
    },
    grandTotals: {
      grandTaxRate: 5,
    },
    debitsAndCredits: [],
    payments: [],
  });
  const [changeLog, setChangeLog] = useState({});
  const [invoiceSerialArray, setInvoiceSerialArray] = useState([]);

  const invoiceId = props.location.state && props.location.state.invoiceId;
  //console.log({ invoiceId, props });
  let dateField;

  //console.log({ originalData, formData });

  // getig all codes and other details from masterdb and storing in to temodata
  //using useEffect for fetching it every time it render itself

  useEffect(async () => {
    //if masterdb is empty then get the masterdb and assign it to masterdb
    //console.log("----Inside Use Effect ---");
    if (!masterDB) {
      //console.log("----Inside Use Effect MasterDB---");
      const tempMasterDB = await getMasterDB();
      setMasterDB(tempMasterDB);
      //console.log("data got from master");
    }

    //this is for if user want to edit his invoice
    if (masterDB && props.type == "edit" && !originalData.allLists._id) {
      //console.log("----Inside Use Effect Edit Alllists---");

      let tempOriginalData = await getRecords({
        db: masterDB,
        allLists: true,
      });
      //console.log("got All Lists data");
      //Create originalData.sellerCodes array
      tempOriginalData.allLists.sellerCodes = (
        tempOriginalData.allLists.scgList || []
      ).concat(
        (tempOriginalData.allLists.bscgList || []).concat(
          (tempOriginalData.allLists.sccList || []).concat(
            tempOriginalData.allLists.bsccList || []
          )
        )
      );

      //Create originalData.buyerCodes array
      tempOriginalData.allLists.buyerCodes = (
        tempOriginalData.allLists.bcgList || []
      ).concat(
        (tempOriginalData.allLists.bscgList || []).concat(
          (tempOriginalData.allLists.bccList || []).concat(
            tempOriginalData.allLists.bsccList || []
          )
        )
      );

      tempOriginalData.sellerOptions = tempOriginalData.allLists.sellerCodes;
      tempOriginalData.buyerOptions = tempOriginalData.allLists.buyerCodes;

      // //console.log({tempOriginalData}, {originalData});
      if (invoiceId) {
        //console.log("getting your invoice Data");
        let invoiceData = await masterDB.get(invoiceId);
        //console.log({ invoiceData }, invoiceData._id);
        tempOriginalData.data = invoiceData;
      }
      //console.log({ originalData, formData, tempOriginalData });
      tempOriginalData = {
        ...originalData,
        ...tempOriginalData,
        data: tempOriginalData.data || {},
      };

      let ogData = await getRecords({ db: masterDB, type: "ownerGroup" });
      tempOriginalData.groupSettings = ogData.groupSettings;
      tempOriginalData.teamMembers = ogData.data.teamMembers;

      setOriginalData(tempOriginalData);
    } else if (masterDB && !originalData.allLists._id) {
      //console.log("----Inside Use Effect New All lists---");

      let tempOriginalData = await getRecords({
        db: masterDB,
        allLists: true,
      });
      //console.log(tempOriginalData);

      //Create originalData.sellerCodes array
      tempOriginalData.allLists.sellerCodes = (
        tempOriginalData.allLists.scgList || []
      ).concat(
        (tempOriginalData.allLists.bscgList || []).concat(
          (tempOriginalData.allLists.sccList || []).concat(
            tempOriginalData.allLists.bsccList || []
          )
        )
      );

      //Create originalData.buyerCodes array
      tempOriginalData.allLists.buyerCodes = (
        tempOriginalData.allLists.bcgList || []
      ).concat(
        (tempOriginalData.allLists.bscgList || []).concat(
          (tempOriginalData.allLists.bccList || []).concat(
            tempOriginalData.allLists.bsccList || []
          )
        )
      );

      tempOriginalData.sellerOptions = tempOriginalData.allLists.sellerCodes;
      tempOriginalData.buyerOptions = tempOriginalData.allLists.buyerCodes;

      //console.log({ originalData, tempOriginalData });

      tempOriginalData = {
        ...originalData,
        ...tempOriginalData,
      };

      let ogData = await getRecords({ db: masterDB, type: "ownerGroup" });
      //console.log({ ogData });
      tempOriginalData.groupSettings = ogData.data.groupSettings;
      tempOriginalData.teamMembers = ogData.data.teamMembers;

      //console.log({ originalData, tempOriginalData });
      setOriginalData(tempOriginalData);
    }
    //if user want to edit
    if (props.type === "edit" && originalData.data._id) {
      //console.log("edit is here");

      //console.log("i am geting your data");
      //console.log({ originalData });
      setFormData(originalData.data);
    } else if (originalData.allLists._id && !formData.invoiceNumber) {
      //console.log("----Get Invoice Serial Number---");

      let invoiceCount = await masterDB.find({
        selector: {
          type: {
            $eq: "invoice",
          },
        },
      });
      let tempInvoiceSerialArray =
        invoiceCount &&
        invoiceCount.docs.map((invoice) => {
          if (invoice && invoice.invoiceSerialNumber) {
            //console.log(invoice.invoiceSerialNumber);
            return invoice && invoice.invoiceSerialNumber;
          }
        });
      //console.log("hey bro here is the serial array you are lookig for");
      //console.log(tempInvoiceSerialArray);
      setInvoiceSerialArray(tempInvoiceSerialArray);
      let invoiceNumber = ((invoiceCount.docs.length || 0) + 1)
        .toString()
        .padStart(1, "0");

      setFormData({
        ...formData,
        invoiceSerialNumber: Number(invoiceNumber),
      });
    }
  }, [masterDB, originalData]);

  const handleSubmit = async (formInputs) => {
    try {
      //we have to create new document in the database of for invoice
      formInputs.preventDefault();
      let latestDoc;
      //console.log({ formInputs });

      if (props.type !== "edit") {
        latestDoc = formData;
        let RandomID = Math.random().toString(36).substr(2, 3);
        let ownerGroupID = localStorage.getItem("ownerid");
        let invoiceDate = formData.invoiceDate.substr(0, 10).replace(/\-/g, "");
        let latestAllLists = await masterDB.get(originalData.allLists._id);

        latestAllLists.invoiceNumbers = latestAllLists.invoiceNumbers || [];
        latestAllLists.invoiceNumbers.push(formData.invoiceNumber);
        await masterDB.put(latestAllLists);

        latestDoc.type = "invoice";
        latestDoc._id = `invoice:${ownerGroupID}_${invoiceDate}_${formData.invoiceNumber}_${RandomID}`;
        latestDoc.status = formData.status;
        latestDoc.updateLogs = [];
        latestDoc.pendingPayment = Number(
          Math.round(formData.grandTotals.grandTotalInvoiceAmount)
        );

        //console.log(latestDoc);
        latestDoc.updateLogs.unshift({
          updateTime: new Date().toISOString(),
          userID: localStorage.getItem("userid"),
          userCode: localStorage.getItem("usercode"),
          updates: `New order with id: ${latestDoc._id} created`,
        });
      }
      //if invoice is edit then
      else {
        latestDoc = await masterDB.get(formData._id);

        //to prevent any data conflict,due to any intermediate update by different user
        if (formData._rev !== latestDoc._rev) {
          alert(
            `Data recently updated by ${latestDoc.updateLogs[0].userCode} on ${latestDoc.updateLogs[0].updateTime}. Please sync your database & try again.`
          );
          return undefined;
        }
        latestDoc = formData;
        latestDoc.updateLogs.unshift({
          updateTime: new Date().toISOString(),
          userID: localStorage.getItem("userid"),
          userCode: localStorage.getItem("usercode"),
          updates: changeLog,
        });
      }
      let sellerClientDoc;
      await masterDB
        .find({
          selector: {
            _id: {
              $gte: `clientCompany:${ownerGroupID}`,
              $lt: `clientCompany:${ownerGroupID}z`,
            },
            category: {
              $eq: "Seller",
            },
            type: {
              $eq: "clientCompany",
            },
            code: {
              $eq: formData.sellerDetails && formData.sellerDetails.code,
            },
            status: {
              $nin: ["Deleted"],
            },
          },
        })
        .then(async (data) => {
          if (data.docs[0]) {
            sellerClientDoc = data.docs[0];
            if (sellerClientDoc.lastSellInvoiceDate) {
              if (formData.invoiceDate > sellerClientDoc.lastSellInvoiceDate) {
                sellerClientDoc.lastSellInvoiceDate = formData.invoiceDate;
                await masterDB.put(sellerClientDoc);
              }
            } else {
              sellerClientDoc.lastSellInvoiceDate = formData.invoiceDate;
              await masterDB.put(sellerClientDoc);
            }
            //console.log("buyer and seller company's data");
            //console.log(sellerClientDoc);
          }
        });
      let buyerClientDoc;
      await masterDB
        .find({
          selector: {
            _id: {
              $gte: `clientCompany:${ownerGroupID}`,
              $lt: `clientCompany:${ownerGroupID}z`,
            },
            category: {
              $eq: "Buyer",
            },
            type: {
              $eq: "clientCompany",
            },
            code: {
              $eq: formData.buyerDetails && formData.buyerDetails.code,
            },
            status: {
              $nin: ["Deleted"],
            },
          },
        })
        .then(async (data) => {
          if (data.docs[0]) {
            buyerClientDoc = data.docs[0];
            if (buyerClientDoc.lastBuyInvoiceDate) {
              if (formData.invoiceDate > buyerClientDoc.lastBuyInvoiceDate) {
                buyerClientDoc.lastBuyInvoiceDate = formData.invoiceDate;
                await masterDB.put(buyerClientDoc);
              }
            } else {
              buyerClientDoc.lastBuyInvoiceDate = formData.invoiceDate;
              await masterDB.put(buyerClientDoc);
            }
            //console.log("buyer and seller company's data");
            //console.log(buyerClientDoc);
          }
        });
      //console.log(latestDoc);
      //console.log("this is original data" + originalData);
      await masterDB.put(latestDoc);

      navigate("/");
    } catch (error) {
      //console.log(error);
    }
  };

  const handleChange = async (inputValue, dateField) => {
    //console.log(inputValue);

    let newValue = (inputValue.value || inputValue.target.value).replace(
      /,/g,
      ""
    );

    let fieldName =
      (inputValue.currentTarget && inputValue.currentTarget.name) ||
      (inputValue.target && inputValue.target.name) ||
      dateField;
    // //console.log(inputValue.currentTarget);
    //console.log(fieldName);
    //console.log(newValue);

    let changedData = updateObjProp(
      changeLog,
      formData,
      newValue,
      fieldName,
      originalData
    );
    let newFieldName = fieldName.split(".");
    //console.log(formData);
    if (changedData.error) {
      alert(changedData.error);
    } else {
      //all filed handlechage will go here

      //Handle change of seller code
      if (fieldName === "sellerDetails.code") {
        let dbType = "clientCompany";

        if (
          originalData.allLists.scgList.includes(newValue) ||
          originalData.allLists.bscgList.includes(newValue)
        ) {
          dbType = "clientGroup";
        }

        let sellerData = await getRecords({
          db: masterDB,
          allLists: false,
          type: dbType,
          code: newValue,
        });
        sellerData = sellerData.data;
        sellerData.subAgentCode = sellerData.primarySubAgent;
        changedData.formTemp.sellerDetails = sellerData;
        // setOriginalData(tempOrderNumber);
        setFormData(changedData.formTemp);
        setChangeLog({
          ...changedData.changeLog,
          sellerDetails: originalData.data && originalData.data.sellerDetails,
        });
      }

      //Handle change of buyer code
      else if (fieldName === "buyerDetails.code") {
        let dbType = "clientCompany";

        if (
          originalData.allLists.bcgList.includes(newValue) ||
          originalData.allLists.bscgList.includes(newValue)
        ) {
          dbType = "clientGroup";
        }

        let buyerData = await getRecords({
          db: masterDB,
          allLists: false,
          type: dbType,
          code: newValue,
        });
        buyerData = buyerData.data;
        buyerData.subAgentCode = buyerData.primarySubAgent;

        changedData.formTemp.buyerDetails = buyerData;

        setFormData(changedData.formTemp);
        setChangeLog({
          ...changedData.changeLog,
          buyerDetails: originalData.data && originalData.data.buyerDetails,
        });
      }
      //Handle default
      else {
        setFormData(changedData.formTemp);
        setChangeLog({
          ...changedData.changeLog,
        });
      }
    }
  };

  const calculateGrandTotals = (tempFormData) => {
    tempFormData.grandTotals.grandTotalGrossAmount = Number(
      (
        Number(tempFormData.grandTotals.grandTotalProductAmount || 0) +
        Number(tempFormData.grandTotals.grandTotalOtherChargesAmount || 0)
      ).toFixed(2)
    );
    tempFormData.grandTotals.grandTotalTaxAmount = Number(
      (
        (Number(tempFormData.grandTotals.grandTotalGrossAmount || 0) *
          Number(tempFormData.grandTotals.grandTaxRate || 0)) /
        100
      ).toFixed(2)
    );
    tempFormData.grandTotals.grandTotalInvoiceAmount = Number(
      Math.round(
        Number(tempFormData.grandTotals.grandTotalGrossAmount || 0) +
          Number(tempFormData.grandTotals.grandTotalTaxAmount || 0)
      )
    );

    tempFormData = calculateCommission(tempFormData);

    return tempFormData;
  };

  const calculateCommission = (tempFormData) => {
    //calculate commission only when not entered in charges already
    if (!tempFormData.commission.deductedInInvoice) {
      //console.log("calculating commission");
      if (tempFormData.commission.calculateOn == "Gross Amount") {
        tempFormData.commission.amount = Number(
          Math.round(
            (Number(tempFormData.grandTotals.grandTotalGrossAmount || 0) *
              Number(tempFormData.commission.percentage || 0)) /
              100
          )
        );
      } else {
        tempFormData.commission.amount = Number(
          Math.round(
            (Number(tempFormData.grandTotals.grandTotalInvoiceAmount || 0) *
              Number(tempFormData.commission.percentage || 0)) /
              100
          )
        );
      }
    }

    return tempFormData;
  };

  const handleOtherChargesChange = (tempFormData, chargeIndex) => {
    //console.log({ tempFormData, chargeIndex });
    if (chargeIndex === 0 || chargeIndex) {
      let tempGrossAmt =
        chargeIndex === 0
          ? Number(tempFormData.grandTotals.grandTotalProductAmount || 0)
          : Number(
              tempFormData.otherChargesArray[chargeIndex - 1]
                .sectionGrossAmount || 0
            );

      tempFormData.otherChargesArray[chargeIndex].sectionGrossAmount =
        Number(tempGrossAmt || 0) +
        Number(tempFormData.otherChargesArray[chargeIndex].chargeAmount || 0);

      if (
        tempFormData.otherChargesArray[chargeIndex].chargeType == "Commission"
      ) {
        tempFormData.commission.deductedInInvoice = true;
        tempFormData.commission.percentage = Number(
          tempFormData.otherChargesArray[chargeIndex].chargePercent || 0
        );
        tempFormData.commission.amount = Number(
          Math.round(
            tempFormData.otherChargesArray[chargeIndex].chargeAmount || 0
          )
        );

        if (tempFormData.commission.percentage < 0) {
          tempFormData.commission.percentage *= -1;
        }

        if (tempFormData.commission.amount < 0) {
          tempFormData.commission.amount *= -1;
        }
      }
    }

    tempFormData.grandTotals.grandTotalOtherChargesAmount = 0;
    for (let i = 0; i < tempFormData.otherChargesArray.length; i++) {
      tempFormData.grandTotals.grandTotalOtherChargesAmount += Number(
        tempFormData.otherChargesArray[i].chargeAmount || 0
      );
    }
    tempFormData.grandTotals.grandTotalOtherChargesAmount = Number(
      tempFormData.grandTotals.grandTotalOtherChargesAmount.toFixed(2)
    );

    //console.log({ tempFormData });

    tempFormData = calculateGrandTotals(tempFormData);

    //set the Due Date

    //console.log({ tempFormData, formData });
    return tempFormData;
  };

  const handleInvoiceDueNumeralChange = (tempFormData) => {
    switch (tempFormData.invoiceDue.calculateFrom) {
      case "Invoice Date":
        let invoiceDate = new Date(tempFormData.invoiceDate);
        tempFormData.invoiceDue.dueDate = new Date(
          invoiceDate.setDate(
            invoiceDate.getDate() +
              Number(tempFormData.invoiceDue.dueNumeral || 0)
          )
        ).toISOString();
        return tempFormData;
        break;
      case "Dispatch Date":
        let dispatchDate = new Date(tempFormData.deliveryDetails.dispatchDate);
        tempFormData.invoiceDue.dueDate = new Date(
          dispatchDate.setDate(
            dispatchDate.getDate() +
              Number(tempFormData.invoiceDue.dueNumeral || 0)
          )
        );
        return tempFormData;
        break;
      case "Delivery Date":
        let deliveryDate = new Date(tempFormData.deliveryDetails.dispatchDate);
        tempFormData.invoiceDue.dueDate = new Date(
          invoiceDate.setDate(
            invoiceDate.getDate() +
              Number(tempFormData.invoiceDue.dueNumeral || 0)
          )
        );
        return tempFormData;
        break;
      case "Invoice Receive Date":
        let invoiceReceiveDate = new Date(
          tempFormData.deliveryDetails.dispatchDate
        );
        tempFormData.invoiceDue.dueDate = new Date(
          invoiceDate.setDate(
            invoiceDate.getDate() +
              Number(tempFormData.invoiceDue.dueNumeral || 0)
          )
        );
        return tempFormData;
        break;
    }
    return tempFormData;
  };

  if (false) {
    //console.log("inside if");
    return <Box>Rendering</Box>;
  } else {
    return (
      <Box>
        <Header background="brand" pad="small">
          <BackBox />
          <Box>
            <Text>
              {props.type === "edit" ? "Edit Invoice" : "Add New Invoice"}
            </Text>
          </Box>
          <SyncButton />
        </Header>

        {/* form */}

        <Box fill overflow="auto" pad="small">
          <Form
            onSubmit={handleSubmit}
            validate="blur"
            onValidate={(validationResults) => {
              //console.log("validationResults = ", validationResults);
            }}
          >
            <Box direction="row" justify="between">
              <Box justify="between">
                Serial / Lot / <br />
                Batch No.
              </Box>
              <FormField
                name="invoiceSerialNumber"
                type="number"
                validate={[
                  { regexp: /\d+/i },
                  (name) => {
                    if (!name) {
                      return "Please Enter Serial Number";
                    }
                    if (invoiceSerialArray.includes(Number(name))) {
                      return "Serial Number already exist";
                    }
                    return undefined;
                  },
                ]}
              >
                <TextInput
                  textAlign="end"
                  name="invoiceSerialNumber"
                  value={formData.invoiceSerialNumber}
                  onChange={handleChange}
                />
              </FormField>
            </Box>
            <FormField
              name="salesManAccount.userCode"
              direction="row"
              justify="between"
              align="center"
              label="Salesman Account"
              margin="none"
            >
              <Select
                options={originalData.teamMembers || []}
                labelKey="userCode"
                valueKey={{ key: "userCode", reduce: true }}
                value={
                  formData.salesManAccount && formData.salesManAccount.userCode
                }
                name="salesManAccount.userCode"
                onChange={(selectedObj) => {
                  let tempFormData = formData;
                  tempFormData.salesManAccount = selectedObj.option;
                  setFormData({ ...tempFormData });
                }}
              />
            </FormField>
            <hr />
            {/* <Box direction="column" justify="between" flex>
              <FormField
                name="invoiceSerialNumber"
                label="Serial / Lot / Batch No."
                direction="row"
                justify="between"
                type="number"
                validate={[
                  { regexp: /\d+/i },
                  (name) => {
                    if (!name) {
                      return "Please Enter Serial Number";
                    }
                    if (invoiceSerialArray.includes(Number(name))) {
                      return "Serial Number already exist";
                    }
                    return undefined;
                  },
                ]}
              >
                <TextInput
                  name="invoiceSerialNumber"
                  value={formData.invoiceSerialNumber}
                  onChange={handleChange}
                />
              </FormField>
            </Box> */}
            <Box direction="row" justify="between">
              <Text>
                <strong>Seller Information</strong>
              </Text>
              {/* <SettingsBox /> */}
            </Box>
            {/* seller information */}
            <FormField
              name="sellerDetails.code"
              label="Seller Code*"
              validate={[
                (name) => {
                  if (!name) return "Please Select Seller code";
                  if (name) {
                    if (!formData.sellerDetails.name) {
                      return "Please choose a different seller & retry";
                    }
                  }
                  return undefined;
                },
              ]}
            >
              <Select
                name="sellerDetails.code"
                options={originalData.sellerOptions || []}
                value={formData.sellerDetails && formData.sellerDetails.code}
                onChange={handleChange}
                onSearch={(text) => {
                  // The line below escapes regular expression special characters:
                  // [ \ ^ $ . | ? * + ( )
                  const escapedText = text.replace(
                    /[-\\^$*+?.()|[\]{}]/g,
                    "\\$&"
                  );

                  // Create the regular expression with modified value which
                  // handles escaping special characters. Without escaping special
                  // characters, errors will appear in the console
                  const exp = new RegExp(escapedText, "i");
                  originalData.sellerOptions =
                    originalData.allLists.sellerCodes.filter((o) =>
                      exp.test(o)
                    );
                  setOriginalData({ ...originalData });
                }}
                required
              />
            </FormField>
            {formData.sellerDetails && (
              <Box>
                {formData.sellerDetails.name && (
                  <Text>Name: {formData.sellerDetails.name}</Text>
                )}
                {formData.sellerDetails.gstNumber && (
                  <Text>GST: {formData.sellerDetails.gstNumber}</Text>
                )}
              </Box>
            )}
            <FormField
              name="invoiceNumber"
              label="Invoice Number*"
              // validate= {(fieldInput, formInputs) => {
              //     return userCodeValidation(fieldInput, formInputs, "invoice", (originalData.allLists.invoiceNumbers || []),props.type)
              // }}
              validate={[
                (name) => {
                  if (!name) return "Please Enter Invoice Number";
                  return undefined;
                },
              ]}
            >
              <TextInput
                name="invoiceNumber"
                value={formData.invoiceNumber}
                onChange={handleChange}
                required
              />
            </FormField>
            <FormField name="invoiceDate" label="Invoice Date">
              <DateInput
                name="invoice Date*"
                format="dd/mm/yyyy"
                value={formData.invoiceDate}
                onChange={(e) => {
                  handleChange(e, (dateField = "invoiceDate"));
                }}
                open={open}
                dropProps={{ width: "medium" }}
                required
              />
            </FormField>
            <FormField
              name="sellerDetails.subAgentCode"
              label="Seller sub-Agent"
            >
              <Select
                name="sellerDetails.subAgentCode"
                options={
                  (originalData.allLists.sagList || []).concat(
                    originalData.allLists.sacList
                  ) || []
                }
                value={
                  formData.sellerDetails && formData.sellerDetails.subAgentCode
                }
                onChange={handleChange}
                required
              />
            </FormField>
            <hr />
            <Box direction="row" justify="between">
              <Text>
                <strong>Buyer Information</strong>
              </Text>
              {/* <SettingsBox /> */}
            </Box>
            {/* buyer information */}
            <FormField
              name="buyerDetails.code"
              label="Buyer Code*"
              // validate= {(fieldInput, formInputs) => {
              //     return userCodeValidation(fieldInput, formInputs, "invoice", (originalData.allLists.invoiceNumbers || []))
              // }}
              validate={[
                (name) => {
                  if (!name) return "Please Select Buyer code";
                  if (name) {
                    if (!formData.buyerDetails.name) {
                      return "Please choose a different Buyer & retry";
                    }
                  }
                  return undefined;
                },
              ]}
            >
              <Select
                name="buyerDetails.code"
                options={originalData.buyerOptions || []}
                value={formData.buyerDetails && formData.buyerDetails.code}
                onChange={handleChange}
                required
                onSearch={(text) => {
                  // The line below escapes regular expression special characters:
                  // [ \ ^ $ . | ? * + ( )
                  const escapedText = text.replace(
                    /[-\\^$*+?.()|[\]{}]/g,
                    "\\$&"
                  );

                  // Create the regular expression with modified value which
                  // handles escaping special characters. Without escaping special
                  // characters, errors will appear in the console
                  const exp = new RegExp(escapedText, "i");
                  originalData.buyerOptions =
                    originalData.allLists.buyerCodes.filter((o) => exp.test(o));
                  setOriginalData({ ...originalData });
                }}
              />
            </FormField>
            {formData.buyerDetails && (
              <Box>
                {formData.buyerDetails.name && (
                  <Text>Name: {formData.buyerDetails.name}</Text>
                )}
                {formData.buyerDetails.gstNumber && (
                  <Text>GST: {formData.buyerDetails.gstNumber}</Text>
                )}
              </Box>
            )}
            <FormField name="buyerDetails.subAgentCode" label="Buyer Sub-Agent">
              <Select
                name="buyerDetails.subAgentCode"
                options={
                  (originalData.allLists.sagList || []).concat(
                    originalData.allLists.sacList
                  ) || []
                }
                value={
                  formData.buyerDetails && formData.buyerDetails.subAgentCode
                }
                onChange={handleChange}
                required
              />
            </FormField>
            <hr />
            <Box direction="row" justify="between">
              <Text>
                <strong>Delivery Details</strong>
              </Text>
              {/* <SettingsBox /> */}
            </Box>
            {/* delivery details */}
            <Box direction="row">
              <FormField name="deliveryDetails.name" label="Transport name">
                <TextInput
                  name="deliveryDetails.name"
                  value={
                    (formData.deliveryDetails &&
                      formData.deliveryDetails.name) ||
                    " "
                  }
                  onChange={handleChange}
                />
              </FormField>

              <FormField
                name="deliveryDetails.station"
                label="Delivery Station / Branch"
                margin={{ left: "xsmall" }}
              >
                <TextInput
                  name="deliveryDetails.station"
                  value={
                    (formData.deliveryDetails &&
                      formData.deliveryDetails.station) ||
                    ""
                  }
                  onChange={handleChange}
                />
              </FormField>
            </Box>
            <Box direction="row">
              <FormField
                name="deliveryDetails.consignmentNumber"
                label="LR / Consignment No."
              >
                <TextInput
                  name="deliveryDetails.consignmentNumber"
                  value={
                    (formData.deliveryDetails &&
                      formData.deliveryDetails.consignmentNumber) ||
                    ""
                  }
                  onChange={handleChange}
                />
              </FormField>
              <FormField
                name="deliveryDetails.dispatchDate"
                label="LR / Dispatch Date"
                margin={{ left: "xsmall" }}
              >
                <DateInput
                  name="dispatchDate"
                  format="dd/mm/yyyy"
                  value={
                    formData.deliveryDetails &&
                    formData.deliveryDetails.dispatchDate
                  }
                  onChange={(e) => {
                    handleChange(
                      e,
                      (dateField = "deliveryDetails.dispatchDate")
                    );
                  }}
                  open={open}
                  dropProps={{ width: "medium" }}
                  required
                />
              </FormField>
            </Box>
            <hr />
            {formData.productsArray &&
              formData.productsArray.map((prodObj, prodIndex) => {
                //console.log(
                // "products array",
                //   { prodObj, prodIndex },
                //   Number(prodObj.grossAmt || 0)
                // );
                return (
                  <Box
                    key={prodObj.productId}
                    background="#F6F9F0"
                    pad={{ vertical: "small" }}
                    margin={{ vertical: "small" }}
                  >
                    <Box direction="row" justify="between" align="center">
                      <Text>Product Name</Text>
                      <Box
                        margin="xsmall"
                        onClick={() => {
                          let tempFormData = formData;
                          tempFormData.productsArray.splice(prodIndex, 1);
                          tempFormData.grandTotals.grandTotalProductAmount = 0;
                          //console.log(tempFormData.productsArray.length);
                          for (
                            let i = 0;
                            i < tempFormData.productsArray.length;
                            i++
                          ) {
                            //console.log(i, tempFormData.productsArray[i]);
                            tempFormData.grandTotals.grandTotalProductAmount +=
                              Number(
                                tempFormData.productsArray[i].grossAmount || 0
                              );
                            //console.log(
                            //   tempFormData.productsArray[i].grossAmount,
                            //   tempFormData.grandTotals.grandTotalProductAmount
                            // );
                          }

                          tempFormData = calculateGrandTotals(tempFormData);

                          setFormData({ ...tempFormData });
                        }}
                      >
                        <Trash size="medium" />
                      </Box>
                    </Box>
                    <TextInput
                      name={"productName" + prodIndex} // unique names needed, else leads to infinite renders.
                      value={prodObj.productName || ""}
                      onChange={(inputName) => {
                        let tempFormData = formData;
                        tempFormData.productsArray[prodIndex].productName =
                          inputName.target.value;
                        setFormData({ ...tempFormData });
                      }}
                    />

                    <Text margin={{ vertical: "xxsmall", right: "xxsmall" }}>
                      Quantity
                    </Text>
                    <Box
                      direction="row"
                      align="center"
                      margin={{ vertical: "xxsmall" }}
                    >
                      <TextInput
                        // thousandsSeparatorSymbol=","
                        // decimalSymbol="."
                        // decimals={2}
                        // addIcon={<AddCircle />}
                        // subtractIcon={<SubtractCircle />}
                        type="number"
                        margin="xxsmall"
                        textAlign="end"
                        name={"productQuantity" + prodIndex}
                        value={Number(prodObj.productQuantity || 0)}
                        onChange={(inputQty) => {
                          //console.log({ inputQty });
                          let tempFormData = formData;
                          tempFormData.productsArray[
                            prodIndex
                          ].productQuantity = Number(
                            inputQty.target.value.replace(/[₹ ,]/g, "") || 0
                          );
                          // tempFormData.productsArray[prodIndex].productQty = Number((parseFloat(inputQty.target.value)).toFixed(2));
                          //console.log({ tempFormData });
                          tempFormData.productsArray[prodIndex].grossAmount =
                            Number(
                              (
                                Number(
                                  tempFormData.productsArray[prodIndex]
                                    .productQuantity || 0
                                ) * Number(prodObj.productRate || 0)
                              ).toFixed(2)
                            );
                          tempFormData.grandTotals.grandTotalProductAmount = 0;
                          //console.log(tempFormData.productsArray.length);
                          for (
                            let i = 0;
                            i < tempFormData.productsArray.length;
                            i++
                          ) {
                            //console.log(i, tempFormData.productsArray[i]);
                            tempFormData.grandTotals.grandTotalProductAmount +=
                              Number(
                                tempFormData.productsArray[i].grossAmount || 0
                              );
                            //console.log(
                            //   tempFormData.productsArray[i].grossAmount,
                            //   tempFormData.grandTotals.grandTotalProductAmount
                            // );
                          }

                          tempFormData = calculateGrandTotals(tempFormData);

                          setFormData({ ...tempFormData });
                        }}
                        onBlur={(val) => {
                          //console.log(val);
                          let tempFormData = formData;
                          tempFormData.productsArray[
                            prodIndex
                          ].productQuantity = Number(
                            Math.round((val.target.value || 0) * 100) / 100
                          );
                          setFormData({ ...tempFormData });
                        }}
                      />
                      <Select
                        name={"productUom" + prodIndex}
                        margin={{ vertical: "xxsmall", left: "xxsmall" }}
                        options={uomOptions}
                        value={prodObj.productUom}
                        onChange={(inputUom) => {
                          let tempFormData = formData;
                          tempFormData.productsArray[prodIndex].productUom =
                            inputUom.target.value;
                          tempFormData.grandTotalInvUom = inputUom.target.value;

                          // Need to disable change in total qty uom.

                          setFormData({ ...tempFormData });
                        }}
                      />
                    </Box>
                    <Box direction="row">
                      <FormField
                        label="Rate"
                        margin={{ vertical: "xxsmall", right: "xxsmall" }}
                      >
                        <Box width="small">
                          <TextInput
                            // prefix="₹ "
                            // thousandsSeparatorSymbol=","
                            // decimalSymbol="."
                            // decimals={2}
                            // addIcon={<AddCircle />}
                            // subtractIcon={<SubtractCircle />}
                            type="number"
                            name={"productRate" + prodIndex}
                            value={Number(prodObj.productRate || 0)}
                            onChange={(inputRate) => {
                              let tempFormData = formData;

                              tempFormData.productsArray[
                                prodIndex
                              ].productRate = inputRate.target.value.replace(
                                /[₹ ,]/g,
                                ""
                              );
                              tempFormData.productsArray[
                                prodIndex
                              ].grossAmount = Number(
                                (
                                  Number(
                                    tempFormData.productsArray[prodIndex]
                                      .productRate
                                  ) * Number(prodObj.productQuantity)
                                ).toFixed(2)
                              );
                              tempFormData.grandTotals.grandTotalProductAmount = 0;
                              //console.log(tempFormData.productsArray.length);
                              for (
                                let i = 0;
                                i < tempFormData.productsArray.length;
                                i++
                              ) {
                                //console.log(i, tempFormData.productsArray[i]);
                                tempFormData.grandTotals.grandTotalProductAmount +=
                                  Number(
                                    tempFormData.productsArray[i].grossAmount ||
                                      0
                                  );
                                //console.log(
                                //   tempFormData.productsArray[i].grossAmount,
                                //   tempFormData.grandTotals
                                //     .grandTotalProductAmount
                                // );
                              }

                              tempFormData = calculateGrandTotals(tempFormData);

                              setFormData({ ...tempFormData });
                            }}
                            onBlur={(val) => {
                              //console.log(val);
                              let tempFormData = formData;
                              tempFormData.productsArray[
                                prodIndex
                              ].productRate = Number(
                                Number(
                                  formData.productsArray[prodIndex]
                                    .productRate || 0
                                ).toFixed(2)
                              );
                              setFormData({ ...tempFormData });
                            }}
                            required={true}
                          />
                        </Box>
                      </FormField>
                      <FormField
                        label="Gross Amount (excl. Taxes)"
                        margin={{ vertical: "xxsmall", left: "xxsmall" }}
                      >
                        <TextInput
                          // prefix="₹ "
                          // thousandsSeparatorSymbol=","
                          // decimalSymbol="."
                          // decimals={2}
                          // addIcon={<AddCircle />}
                          // subtractIcon={<SubtractCircle />}
                          textAlign="end"
                          name={"grossAmt" + prodIndex}
                          type="number"
                          value={Number(
                            Number(prodObj.grossAmount || 0).toFixed(2)
                          )}
                          onChange={(inputAmt) => {
                            let tempFormData = formData;

                            tempFormData.productsArray[prodIndex].grossAmount =
                              Number(
                                Number(
                                  inputAmt.target.value.replace(/[₹ ,]/g, "") ||
                                    0
                                ).toFixed(2)
                              );
                            tempFormData.totalProductAmount =
                              tempFormData.productsArray.reduce(
                                (previousValue, currentValue) =>
                                  Number(previousValue.grossAmount) +
                                  Number(currentValue.grossAmount)
                              );

                            tempFormData = calculateGrandTotals(tempFormData);

                            setFormData({ ...tempFormData });
                          }}
                          onBlur={(val) => {
                            // console.log(val);
                            let tempFormData = formData;
                            tempFormData.productsArray[prodIndex].grossAmount =
                              Number(
                                Number(
                                  formData.productsArray[prodIndex]
                                    .grossAmount || 0
                                ).toFixed(2)
                              );
                            setFormData({ ...tempFormData });
                          }}
                          required={true}
                        />
                      </FormField>
                    </Box>
                  </Box>
                );
              })}
            <Box flex align="center" margin={{ vertical: "xxsmall" }}>
              <Button
                label="Add Product"
                onClick={(var1, var2) => {
                  // console.log({ var1, var2, changeLog, formData });

                  let tempFormData = formData;
                  let productDetail = {
                    productId:
                      "product" +
                      Number(tempFormData.productsArray.length || 0) +
                      1,
                    productUom: "METERS",
                  };

                  tempFormData.productsArray.push(productDetail);
                  setFormData({ ...tempFormData });
                }}
              />
            </Box>
            <FormField label={<strong>Total Product Amount</strong>}>
              <TextInput
                // prefix="₹ "
                // thousandsSeparatorSymbol=","
                // decimalSymbol="."
                // decimals={2}
                // addIcon={<AddCircle />}
                // subtractIcon={<SubtractCircle />}
                name="grandTotals.grandTotalProductAmount"
                textAlign="end"
                type="number"
                value={Number(
                  Number(
                    (formData.grandTotals &&
                      formData.grandTotals.grandTotalProductAmount) ||
                      0
                  ).toFixed(2)
                )}
                onChange={(inputAmt) => {
                  let tempFormData = formData;

                  tempFormData.grandTotals.grandTotalProductAmount =
                    inputAmt.target.value.replace(/[₹ ,]/g, "");

                  tempFormData = calculateGrandTotals(tempFormData);

                  setFormData({ ...tempFormData });
                }}
                onBlur={(val) => {
                  //console.log(val);
                  let tempFormData = formData;
                  tempFormData.grandTotals.grandTotalProductAmount = Number(
                    formData.grandTotals.grandTotalProductAmount.toFixed(2) || 0
                  );
                  setFormData({ ...tempFormData });
                }}
              />
            </FormField>
            <hr />
            {formData.otherChargesArray.map((chargeObj, chargeIndex) => {
              //console.log({ chargeObj, chargeIndex });
              return (
                <Box
                  key={chargeObj.chargeId}
                  background="#F5F4F6"
                  pad={{ top: "xsmall", bottom: "xsmall" }}
                  margin={{ top: "xsmall", bottom: "xsmall" }}
                >
                  <Box direction="row" justify="between" align="center">
                    <Text>Comm / Disc / Charges</Text>
                    <Box
                      margin="xsmall"
                      onClick={() => {
                        let tempFormData = formData;
                        tempFormData.otherChargesArray.splice(chargeIndex, 1);
                        tempFormData = handleOtherChargesChange(tempFormData);

                        tempFormData = calculateGrandTotals(tempFormData);

                        setFormData({ ...tempFormData });
                      }}
                    >
                      <Trash size="medium" />
                    </Box>
                  </Box>
                  <Select
                    name={"chargeLabel" + chargeIndex}
                    options={
                      (originalData &&
                        originalData.groupSettings &&
                        originalData.groupSettings.invoiceOtherCharges) ||
                      []
                    }
                    labelKey="chargeLabel"
                    valueKey={{ key: "chargeLabel", reduce: "true" }}
                    value={chargeObj.chargeLabel}
                    onChange={(inputValue) => {
                      let tempFormData = formData;

                      tempFormData.otherChargesArray[chargeIndex] = {
                        ...chargeObj,
                        chargeName: "",
                        chargePercent: "",
                        chargeAmount: "",
                        ...inputValue.option,
                      };

                      let commTileCount = 0;
                      for (
                        let i = 0;
                        i < tempFormData.otherChargesArray.length;
                        i++
                      ) {
                        commTileCount +=
                          tempFormData.otherChargesArray[i].chargeType ==
                          "Commission"
                            ? 1
                            : 0;
                      }

                      if (
                        inputValue.option.chargeType == "Commission" &&
                        tempFormData.commission.deductedInInvoice === true
                      ) {
                        if (commTileCount > 1) {
                          alert(
                            "Only 1 commission tile allowed in one invoice"
                          );
                          tempFormData.otherChargesArray[chargeIndex] =
                            chargeObj;
                          setFormData({ ...tempFormData });
                          return null;
                        }
                      }

                      if (inputValue.option.chargeType == "Commission") {
                        tempFormData.commission.collectFrom = "Buyer";
                      } else if (commTileCount == 0) {
                        tempFormData.commission.deductedInInvoice = false;
                      }

                      if (inputValue.option.chargePercent) {
                        let tempGrossAmt =
                          chargeIndex === 0
                            ? Number(
                                formData.grandTotals.grandTotalProductAmount ||
                                  0
                              )
                            : Number(
                                formData.otherChargesArray[chargeIndex - 1]
                                  .sectionGrossAmount || 0
                              );

                        tempFormData.otherChargesArray[
                          chargeIndex
                        ].chargeAmount = Number(
                          (
                            (Number(tempGrossAmt || 0) *
                              Number(
                                tempFormData.otherChargesArray[chargeIndex]
                                  .chargePercent || 0
                              )) /
                            100
                          ).toFixed(2)
                        );
                        //console.log({ tempGrossAmt }, tempFormData);
                      }

                      if (inputValue.option.duePeriod) {
                        tempFormData.invoiceDue.dueNumeral =
                          inputValue.option.duePeriod.numerals;
                        tempFormData.invoiceDue.units =
                          inputValue.option.duePeriod.units;
                        tempFormData =
                          handleInvoiceDueNumeralChange(tempFormData);
                      }

                      //console.log({ chargeIndex });

                      tempFormData = handleOtherChargesChange(
                        tempFormData,
                        chargeIndex
                      );
                      setFormData({ ...tempFormData });
                    }}
                    required
                  />
                  <FormField name={"chargeName" + chargeIndex} label="Name">
                    <TextInput
                      placeholder=""
                      name={"chargeName" + chargeIndex}
                      value={chargeObj.chargeName}
                      onChange={(inputName) => {
                        let tempFormData = formData;
                        tempFormData.otherChargesArray[chargeIndex].chargeName =
                          inputName.target.value;
                        setFormData({ ...tempFormData });
                      }}
                    />
                  </FormField>
                  <Box direction="row" align="end">
                    <Box
                      height="xxsmall"
                      width="xxsmall"
                      name={"plusorminus" + chargeIndex}
                      onClick={() => {
                        let tempFormData = formData;

                        tempFormData.otherChargesArray[
                          chargeIndex
                        ].chargePercent =
                          Number(
                            tempFormData.otherChargesArray[chargeIndex]
                              .chargePercent || 0
                          ) * -1;
                        tempFormData.otherChargesArray[
                          chargeIndex
                        ].chargeAmount =
                          Number(
                            tempFormData.otherChargesArray[chargeIndex]
                              .chargeAmount || 0
                          ) * -1;
                        //console.log({ tempFormData });
                        tempFormData = handleOtherChargesChange(
                          tempFormData,
                          chargeIndex
                        );
                        //console.log({ tempFormData });

                        setFormData({ ...tempFormData });
                      }}
                    >
                      <Image
                        name="plusorminus"
                        fit="contain"
                        src={plusorminus}
                      />
                    </Box>
                    <FormField
                      name={"chargePercent" + chargeIndex}
                      label="%"
                      width="small"
                      margin={{ horizontal: "xsmall" }}
                    >
                      <TextInput
                        type="number"
                        placeholder="%"
                        name={"chargePercent" + chargeIndex}
                        value={chargeObj.chargePercent}
                        onChange={(inputPercent) => {
                          let tempFormData = formData;

                          tempFormData.otherChargesArray[
                            chargeIndex
                          ].chargePercent = Number(
                            inputPercent.target.value || 0
                          );

                          let tempGrossAmt =
                            chargeIndex === 0
                              ? Number(
                                  formData.grandTotals
                                    .grandTotalProductAmount || 0
                                )
                              : Number(
                                  formData.otherChargesArray[chargeIndex - 1]
                                    .sectionGrossAmount || 0
                                );

                          tempFormData.otherChargesArray[
                            chargeIndex
                          ].chargeAmount = Number(
                            (
                              (Number(tempGrossAmt || 0) *
                                Number(
                                  tempFormData.otherChargesArray[chargeIndex]
                                    .chargePercent || 0
                                )) /
                              100
                            ).toFixed(2)
                          );

                          tempFormData = handleOtherChargesChange(
                            tempFormData,
                            chargeIndex
                          );

                          setFormData({ ...tempFormData });
                        }}
                      />
                    </FormField>
                    <FormField
                      name={"chargeAmount" + chargeIndex}
                      label="Amount"
                      margin={{ left: "xsmall" }}
                    >
                      <NumberInput
                        // prefix=" ₹ "
                        // thousandsSeparatorSymbol=","
                        decimalSymbol="."
                        decimals={2}
                        addIcon={<AddCircle />}
                        subtractIcon={<SubtractCircle />}
                        name={"chargeAmount" + chargeIndex}
                        type="number"
                        value={chargeObj.chargeAmount}
                        onChange={(inputAmt) => {
                          let tempFormData = formData;

                          tempFormData.otherChargesArray[
                            chargeIndex
                          ].chargeAmount = inputAmt.target.value.replace(
                            /[₹ ,]/g,
                            ""
                          );

                          tempFormData = handleOtherChargesChange(
                            tempFormData,
                            chargeIndex
                          );

                          setFormData({ ...tempFormData });
                        }}
                      />
                    </FormField>
                  </Box>
                </Box>
              );
            })}
            <Box flex align="center">
              <Button
                label="Add Disc / Charge / Comm Entry"
                onClick={(var1, var2) => {
                  //console.log({ var1, var2, changeLog, formData });

                  let otherChargeDetail = {
                    chargeId:
                      "charge" +
                      (Number(formData.otherChargesArray.length || 0) + 1),
                  };

                  formData.otherChargesArray.push(otherChargeDetail);
                  setFormData(formData);

                  let changeArray = [];
                  if (changeLog.otherChangesArray) {
                    changeArray = changeLog.otherChagesArray;
                  }
                  changeArray.push(otherChargeDetail);

                  setChangeLog({
                    ...changeLog,
                    changeArray,
                  });
                }}
              ></Button>
            </Box>
            <FormField label={<strong>Total Disc / Comm / Charges</strong>}>
              <TextInput
                // prefix="₹ "
                // thousandsSeparatorSymbol=","
                // decimalSymbol="."
                // decimals={2}
                // addIcon={<AddCircle />}
                // subtractIcon={<SubtractCircle />}
                type="number"
                textAlign="end"
                name="grandTotals.grandTotalOtherChargesAmount"
                value={Number(
                  formData.grandTotals.grandTotalOtherChargesAmount || 0
                )}
                onChange={(inputAmt) => {
                  let tempFormData = formData;
                  tempFormData.grandTotals.grandTotalOtherChargesAmount =
                    Number(inputAmt.target.value.replace(/[₹ ,]/g, "") || 0);
                  tempFormData = calculateGrandTotals(tempFormData);
                  //console.log({ tempFormData });
                  setFormData({ ...tempFormData });
                }}
                onBlur={(val) => {
                  //console.log(val);
                  let tempFormData = formData;
                  tempFormData.grandTotals.grandTotalOtherChargesAmount =
                    Number(
                      formData.grandTotals.grandTotalOtherChargesAmount.toFixed(
                        2
                      ) || 0
                    );
                  setFormData({ ...tempFormData });
                }}
              />
            </FormField>
            <hr />
            <FormField label={<strong>Total Gross Amount</strong>}>
              <TextInput
                // prefix="₹ "
                // thousandsSeparatorSymbol=","
                // decimalSymbol="."
                // decimals={2}
                // addIcon={<AddCircle />}
                // subtractIcon={<SubtractCircle />}
                name="grandTotals.grandTotalGrossAmount"
                textAlign="end"
                value={Number(formData.grandTotals.grandTotalGrossAmount || 0)}
                onChange={(inputAmt) => {
                  let tempFormData = formData;
                  tempFormData.grandTotals.grandTotalGrossAmount =
                    inputAmt.target.value.replace(/[₹ ,]/g, "");
                  //console.log({ tempFormData });
                  tempFormData.grandTotals.grandTotalTaxAmount = Number(
                    (
                      (Number(
                        tempFormData.grandTotals.grandTotalGrossAmount || 0
                      ) *
                        Number(tempFormData.grandTotals.grandTaxRate || 0)) /
                      100
                    ).toFixed(2)
                  );
                  tempFormData.grandTotals.grandTotalInvoiceAmount = Math.round(
                    Number(
                      tempFormData.grandTotals.grandTotalGrossAmount || 0
                    ) +
                      Number(tempFormData.grandTotals.grandTotalTaxAmount || 0)
                  );
                  tempFormData = calculateCommission(tempFormData);
                  //console.log({ tempFormData });
                  setFormData({ ...tempFormData });
                }}
                onBlur={(val) => {
                  //console.log(val);
                  let tempFormData = formData;
                  tempFormData.grandTotals.grandTotalGrossAmount = Number(
                    formData.grandTotals.grandTotalGrossAmount.toFixed(2) || 0
                  );
                  setFormData({ ...tempFormData });
                }}
              />
            </FormField>
            <Box flex direction="row">
              <FormField label="GST %" width="small" margin="xsmall">
                <TextInput
                  type="number"
                  name="grandTotals.grandTaxRate"
                  placeholder="%"
                  value={Number(formData.grandTotals.grandTaxRate || 0).toFixed(
                    2
                  )}
                  onChange={(inputRate) => {
                    let tempFormData = formData;
                    tempFormData.grandTotals.grandTaxRate = Number(
                      inputRate.target.value || 0
                    );
                    tempFormData.grandTotals.grandTotalTaxAmount = Number(
                      (
                        (Number(
                          tempFormData.grandTotals.grandTotalGrossAmount || 0
                        ) *
                          Number(tempFormData.grandTotals.grandTaxRate || 0)) /
                        100
                      ).toFixed(2)
                    );
                    tempFormData.grandTotals.grandTotalInvoiceAmount = Number(
                      Math.round(
                        Number(
                          tempFormData.grandTotals.grandTotalGrossAmount || 0
                        ) +
                          Number(
                            tempFormData.grandTotals.grandTotalTaxAmount || 0
                          )
                      )
                    );
                    setFormData({ ...tempFormData });
                  }}
                />
              </FormField>
              <FormField label="GST Amount" margin="xsmall">
                <TextInput
                  // prefix="₹ "
                  // thousandsSeparatorSymbol=","
                  // decimalSymbol="."
                  // decimals={2}
                  // addIcon={<AddCircle />}
                  // subtractIcon={<SubtractCircle />}
                  type="number"
                  name="grandTotals.grandTotalTaxAmount"
                  textAlign="end"
                  value={Number(formData.grandTotals.grandTotalTaxAmount || 0)}
                  onChange={(inputAmt) => {
                    let tempFormData = formData;
                    tempFormData.grandTotals.grandTotalTaxAmount = Number(
                      inputAmt.target.value.replace(/[₹ ,]/g, "") || 0
                    );
                    tempFormData.grandTotals.grandTotalInvoiceAmount = Number(
                      Math.round(
                        Number(
                          tempFormData.grandTotals.grandTotalGrossAmount || 0
                        ) + Number(tempFormData.grandTotals.grandTotalTaxAmount)
                      )
                    );
                    tempFormData = calculateCommission(tempFormData);
                    setFormData({ ...tempFormData });
                  }}
                  onBlur={(val) => {
                    //console.log(val);
                    let tempFormData = formData;
                    tempFormData.grandTotals.grandTotalTaxAmount = Number(
                      formData.grandTotals.grandTotalTaxAmount.toFixed(2) || 0
                    );
                    setFormData({ ...tempFormData });
                  }}
                />
              </FormField>
            </Box>
            <FormField
              label={<strong>Total Invoice Amount</strong>}
              name="grandTotals.grandTotalInvoiceAmount"
              validate={[
                (amount) => {
                  if (amount === "") {
                    ("Invoice Amount can't be empty");
                  }

                  if (amount === 0) {
                    return "Invoice Amount can't be zero";
                  }
                  return undefined;
                },
              ]}
            >
              <TextInput
                textAlign="end"
                name="grandTotals.grandTotalInvoiceAmount"
                value={Number(
                  Math.round(
                    Number(formData.grandTotals.grandTotalInvoiceAmount || 0)
                  )
                )}
                onChange={(inputAmt) => {
                  let tempFormData = formData;
                  tempFormData.grandTotals.grandTotalInvoiceAmount = Math.round(
                    inputAmt.target.value.replace(/[₹ ,]/g, "")
                  );
                  tempFormData = calculateCommission(tempFormData);
                  setFormData({ ...tempFormData });
                }}
                required
              />
            </FormField>
            <hr />
            <Box>
              <Text>Due Date Settings</Text>
              <Box direction="row" align="center">
                <Box width="xsmall">
                  <TextInput
                    type="number"
                    name="dueNumeral"
                    value={formData.invoiceDue.dueNumeral}
                    onChange={(inputDueNumeral) => {
                      let tempFormData = formData;
                      //console.log({ tempFormData });
                      tempFormData.invoiceDue.dueNumeral =
                        inputDueNumeral.target.value;

                      tempFormData =
                        handleInvoiceDueNumeralChange(tempFormData);
                      //console.log({ tempFormData });
                      setFormData({ ...tempFormData });
                    }}
                  />
                </Box>
                <Box width="small" margin={{ horizontal: "xsmall" }}>
                  <Select options={["Days"]} value="Days" name="dueMetric" />
                </Box>
                <Text>from</Text>
                <Select
                  name="calculateFrom"
                  options={["Invoice Date", "Dispatch Date"]}
                  value={formData.invoiceDue.calculateFrom}
                  margin={{ left: "xsmall" }}
                  onChange={(inputVal) => {
                    let tempFormData = formData;
                    tempFormData.invoiceDue.calculateFrom =
                      inputVal.target.value;
                    tempFormData = handleInvoiceDueNumeralChange(tempFormData);
                    setFormData({ ...tempFormData });
                  }}
                />
              </Box>
              <FormField label="Due Date">
                <DateInput
                  name="dueDate"
                  format="dd/mm/yyyy"
                  value={formData.invoiceDue.dueDate}
                  onChange={(inputDate) => {
                    //console.log({ inputDate });
                    let tempFormData = formData;
                    tempFormData.invoiceDue.dueDate = inputDate.value;

                    let newDate = new Date(inputDate.value);
                    let calculateFromDate;

                    switch (tempFormData.invoiceDue.calculateFrom) {
                      case "Invoice Date":
                        calculateFromDate = new Date(tempFormData.invoiceDate);
                        break;
                      case "Dispatch Date":
                        calculateFromDate = new Date(
                          tempFormData.deliveryDetails.dispatchDate
                        );
                        break;
                      case "Delivery Date":
                        calculateFromDate = new Date(
                          tempFormData.deliveryDetails.dispatchDate
                        );
                        break;
                      case "Invoice Receive Date":
                        calculateFromDate = new Date(
                          tempFormData.deliveryDetails.dispatchDate
                        );
                        break;
                    }

                    let diff_in_time =
                      newDate.getTime() - calculateFromDate.getTime();
                    let diff_in_days = Math.round(
                      diff_in_time / (1000 * 3600 * 24)
                    );

                    tempFormData.invoiceDue.dueNumeral = diff_in_days;

                    setFormData({ ...formData });
                  }}
                  open={open}
                  dropProps={{ width: "medium" }}
                  required
                />
              </FormField>
            </Box>
            <hr />
            <Box>
              <Text>Comm %</Text>

              <Box flex direction="row">
                <FormField name="commission.percentage" width="xsmall">
                  <TextInput
                    type="number"
                    name="commission.percentage"
                    placeholder="%"
                    value={
                      formData.commission && formData.commission.percentage
                    }
                    onChange={(inputPercent) => {
                      let tempFormData = formData;
                      tempFormData.commission.percentage =
                        inputPercent.target.value;
                      if (
                        tempFormData.commission.calculateOn ==
                        "Total Inv Amount"
                      ) {
                        tempFormData.commission.amount = Number(
                          Math.round(
                            (Number(
                              tempFormData.grandTotals
                                .grandTotalInvoiceAmount || 0
                            ) *
                              Number(tempFormData.commission.percentage || 0)) /
                              100
                          )
                        );
                      } else {
                        tempFormData.commission.amount = Math.round(
                          (Number(
                            tempFormData.grandTotals.grandTotalGrossAmount || 0
                          ) *
                            Number(tempFormData.commission.percentage || 0)) /
                            100
                        );
                      }
                      setFormData({ ...formData });
                    }}
                  />
                </FormField>
                <Text margin="small">Collect From</Text>
                <FormField name="commission.collectFrom" width="small">
                  <Select
                    name="commission.collectFrom"
                    options={["Buyer", "Seller"]}
                    value={
                      formData.commission && formData.commission.collectFrom
                    }
                    onChange={handleChange}
                    size="small"
                  />
                </FormField>
              </Box>
              <FormField label="Total Commission Amount">
                <TextInput
                  // prefix="₹ "
                  // thousandsSeparatorSymbol=","
                  // decimalSymbol="."
                  // decimals={2}
                  // addIcon={<AddCircle />}
                  // subtractIcon={<SubtractCircle />}
                  type="number"
                  name="formData.commission.amount"
                  textAlign="end"
                  value={Math.round(formData.commission.amount || 0)}
                  onChange={(inputAmt) => {
                    let tempFormData = formData;
                    tempFormData.commission.amount = Math.round(
                      inputAmt.target.value.replace(/[₹ ,]/g, "")
                    );
                    setFormData({ ...tempFormData });
                  }}
                  onBlur={(val) => {
                    //console.log(val);
                    let tempFormData = formData;
                    tempFormData.commission.amount = Math.round(
                      Number(formData.commission.amount || 0)
                    );
                    setFormData({ ...tempFormData });
                  }}
                />
              </FormField>
            </Box>
            <FormField name="notes" label="Notes">
              <TextArea
                name="notes"
                value={formData.notes}
                onChange={handleChange}
                placeholder="Notes here"
              />
            </FormField>
            <Button
              primary
              fill="horizontal"
              label="Save"
              bottom={0}
              type="submit"
            />
          </Form>
        </Box>
      </Box>
    );
  }
}

export default AddNewInvoice;
