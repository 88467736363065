import React, { useState } from 'react';

import { MaskedInput } from 'grommet';

export const PanInput = (props) => {

  const panMask = [
    {
      length:5,
      regexp: /[A-Za-z]/,
      placeholder: 'AAAAA',
    },
    // { fixed: ' - ' },
    {
      length: 4,
      regexp: /^[0-9]{1,4}$/,
      placeholder: '1111',
    },
    // { fixed: ' - ' },
    {
      length:1,
      regexp: /[A-Za-z]/,
      placeholder: 'A',
    }
  ];

  return (
          <MaskedInput
            name={props.name}
            mask={panMask}
            value={props.value}
            onChange={props.onChange}
          />
  );
};


export default PanInput;