import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Form,
  FormField,
  Header,
  Select,
  Text,
  TextArea,
  TextInput,
  RadioButtonGroup,
} from "grommet";
import { BackBox, NotificationsBox } from "../../components/Header/Header";
import getMasterDB from "../../database/getMasterDB";
import getRecords from "../../database/getRecords";
import { FormUp, FormDown, Trash } from "grommet-icons";
import {
  gstNumberValidation,
  panValidation,
  userCodeValidation,
} from "../../database/inputValidations";
import PaymentPeriod from "../../components/PaymentPeriod/PaymentPeriod";
import { termsOptions } from "../../constants";
import { EmailInput } from "grommet-controls";
import PhoneNumber from "../../components/PhoneNumber/PhoneNumber";
import Address from "../../components/Address/Address";
import BankDetails from "../../components/Bankdetails/BankDetails";
import AmountField from "../../components/AmountField/AmountField";
import PanInput from "../../components/formComponents/PanInput/PanInput";
import GstnInput from "../../components/formComponents/GstnInput/GstnInput";
import pincodeDirectory from "india-pincode-lookup";

import {
  generateUserCode,
  generatePanFromGst,
} from "../../database/generateCodes";
import { navigate } from "@reach/router";

const AddCompany = (props) => {
  const { short, major, part } = props;
  console.log({ short, major, part, props });

  const [masterDB, setMasterDB] = useState();
  const [originalData, setOriginalData] = useState({
    allLists: {},
    data: {},
  });
  const [formData, setFormData] = useState({
    creditLimit: { currency: "INR" },
  });
  const [changeLog, setChangeLog] = useState({
    formCategoryOptions: ["Buyer", "Seller", "Both - Buyer & Seller"],
  });
  const [moreDetails, setMoreDetails] = useState(false);

  let groupCodesList = [];

  if (part === "Company" && originalData.allLists.bcgList) {
    groupCodesList =
      major === "client"
        ? originalData.allLists.bcgList.concat(
            originalData.allLists.scgList.concat(originalData.allLists.bscgList)
          )
        : originalData.allLists.sagList;
  }

  console.log(groupCodesList);

  useEffect(async () => {
    if (!masterDB) {
      let tempMasterDB = await getMasterDB();
      setMasterDB(tempMasterDB);
    }


    // console.log({props, originalData})
    if (masterDB && props.type !== "edit" && !originalData.allLists._id) {
      // console.log("Getting only All Lists");
      let tempOriginalData = await getRecords({ db: masterDB, allLists: true });
      // console.log.bind(tempOriginalData);

      let groupOptions = await
        masterDB.find({
          selector:{
            type: {$eq: "clientCompany"},
            status: {$ne: "Deleted"},
            groupName:{$exists: true}
          },
          fields:['groupName']
        })
      console.log({groupOptions});

      let newGroupOptions = [];
      
      groupOptions.docs.map((pres) => {
        if (newGroupOptions.includes(pres.groupName)){
          return null ;
        } else {
          return newGroupOptions.push(pres.groupName);
        }
      }, []);
      console.log({newGroupOptions});

      tempOriginalData.groupOptions = newGroupOptions;
      tempOriginalData.groupOptionsDisplay = newGroupOptions;
      setOriginalData(tempOriginalData);
    }

    if (masterDB && props.type === "edit" && !originalData.allLists._id) {
      console.log("Inside getting records for edit");
      let dbtype = major + part; //eg: "client" + "Group" = "clientGroup"

      let tempOriginalData = await getRecords({
        db: masterDB,
        allLists: true,
        type: dbtype,
        code: props.editUriCode,
      });

      let groupOptions = await
        masterDB.find({
          selector:{
            type: {$eq: "clientCompany"},
            status: {$ne: "Deleted"},
            groupName:{$exists: true}
          },
          fields:['groupName']
        })
      console.log({groupOptions});

      let newGroupOptions = [];
      
      groupOptions.docs.map((pres) => {
        if (newGroupOptions.includes(pres.groupName)){
          return null ;
        } else {
          return newGroupOptions.push(pres.groupName);
        }
      }, []);
      console.log({newGroupOptions});

      tempOriginalData.groupOptions = newGroupOptions;
      tempOriginalData.groupOptionsDisplay = newGroupOptions;
      setOriginalData(tempOriginalData);
    }

    if (masterDB && props.type === "edit" && !formData.code) {
      setFormData(originalData.data);
    }
    console.log({ originalData, formData });
  }, [masterDB, originalData]);

  const updateObjProp = (
    changeTemp,
    formTemp,
    value,
    propPath,
    tempOriginalData
  ) => {
    const [head, ...rest] = propPath.split(".");
    // console.log({formTemp, value, propPath, head, rest});

    if (Array.isArray(formTemp[head])) {
      // console.log(`${head} is Array.`)
      return { error: `${head} is Array.` };
    }

    if (formTemp.error) {
      return { error: `Handle Array.` };
    }

    if (!rest.length) {
      // console.log(formTemp[head]);
      changeTemp[head] = tempOriginalData[head];
      formTemp[head] = value;
    } else {
      formTemp[head] = formTemp[head] || {};
      changeTemp[head] = changeTemp[head] || {};
      tempOriginalData[head] = tempOriginalData[head] || {};

      updateObjProp(
        changeTemp[head],
        formTemp[head],
        value,
        rest.join("."),
        tempOriginalData
      );
    }

    return { formTemp, changeTemp };
  };

  const handleChange = async (inputValue) => {
    console.log(inputValue);
    console.log(formData);
    let newValue = inputValue.value || inputValue.target.value;
    let fieldName = inputValue.currentTarget.name || inputValue.target.name;
    let changedData = updateObjProp(
      changeLog,
      formData,
      newValue,
      fieldName,
      originalData
    );

    console.log({ changedData });

    if (changedData.error) {
      alert(changedData.error);
    } else {
      //Generate user code when the user name changes only in Add-My-Company
      if (props.type !== "edit" && fieldName === "name") {
        // console.log("Inside update code.")
        changedData.formTemp.code = generateUserCode(newValue);
        setFormData({
          ...changedData.formTemp,
        });
      }
      //handle change for credit  limit amount
      else if (fieldName === "creditLimit.amount") {
        changedData.formTemp = formData;
        changedData.formTemp.creditLimit.amount = Number(
          Math.round(newValue * 100) / 100
        );
        console.log("changed amount", changedData.formTemp);
        setFormData({
          ...changedData.formTemp,
        });
        console.log(formData);
      }
      //Handling change of part-of-group
      else if (fieldName === "parentCode") {
        //Ask user if they want to update all fields from the group.
        if (confirm("Load details from Group?")) {
          console.log("Load Details - Yes Selected");

          let groupData = await getRecords({
            db: masterDB,
            allLists: false,
            type: `${major}Group`,
            code: newValue,
          });
          groupData = groupData.data;
          console.log(groupData);

          let listOfFieldsToUpdate = [
            ["parentCode", groupData.code],
            ["primarySubAgent", groupData.primarySubAgent],
            ["referenceBy", groupData.referenceBy],
            [
              "creditLimit.currency",
              groupData.creditLimit && groupData.creditLimit.currency,
            ],
            [
              "creditLimit.amount",
              groupData.creditLimit && groupData.creditLimit.amount,
            ],
            ["paymentTerms", groupData.paymentTerms],
            [
              "paymentPeriod.unit",
              groupData.paymentPeriod && groupData.paymentPeriod.unit,
            ],
            [
              "paymentPeriod.numerals",
              groupData.paymentPeriod && groupData.paymentPeriod.numerals,
            ],
            ["email", groupData.email],
            [
              "phoneNumber.isdCode",
              groupData.phoneNumber && groupData.phoneNumber.isdCode,
            ],
            [
              "phoneNumber.phoneNumber",
              groupData.phoneNumber && groupData.phoneNumber.phoneNumber,
            ],
            [
              "addressDetails.country",
              groupData.addressDetails && groupData.addressDetails.country,
            ],
            [
              "addressDetails.pinCode",
              groupData.addressDetails && groupData.addressDetails.pinCode,
            ],
            [
              "addressDetails.state",
              groupData.addressDetails && groupData.addressDetails.state,
            ],
            [
              "addressDetails.city",
              groupData.addressDetails && groupData.addressDetails.city,
            ],
            [
              "addressDetails.address",
              groupData.addressDetails && groupData.addressDetails.address,
            ],
            [
              "bankDetails.bank1.name",
              groupData.bankDetails && groupData.bankDetails.bank1.name,
            ],
            [
              "bankDetails.bank1.branch",
              groupData.bankDetails && groupData.bankDetails.bank1.branch,
            ],
            [
              "bankDetails.bank1.ifscCode",
              groupData.bankDetails && groupData.bankDetails.bank1.ifscCode,
            ],
            [
              "bankDetails.bank1.accountNumber",
              groupData.bankDetails &&
                groupData.bankDetails.bank1.accountNumber,
            ],
          ];
          // console.log({listOfFieldsToUpdate});

          for (let i = 0; i < listOfFieldsToUpdate.length; i++) {
            // console.log({changedData});
            if (listOfFieldsToUpdate[i][1]) {
              changedData = updateObjProp(
                changedData.changeTemp,
                changedData.formTemp,
                listOfFieldsToUpdate[i][1],
                listOfFieldsToUpdate[i][0],
                originalData
              );
            }
            // console.log({changedData});
          }

          // console.log(changedData);

          if (groupData.category === "Buyer") {
            // console.log("Inside Category definition, Buyer");
            changedData.changeTemp.formCategoryOptions = ["Buyer"];
            changedData.formTemp.category = "Buyer";
          } else if (groupData.category === "Seller") {
            changedData.changeTemp.formCategoryOptions = ["Seller"];
            changedData.formTemp.category = "Seller";
          } else if (groupData.category == "Both - Buyer & seller") {
            changedData.changeTemp.formCategoryOptions = [
              "Buyer",
              "Seller",
              "Both - Buyer & Seller",
            ];
            changedData.changeTemp.category = "Both - Buyer & Seller";
          }

          setFormData(changedData.formTemp);
          setChangeLog(() => ({
            ...changedData.changeTemp,
          }));
        } else {
          setFormData(changedData.formTemp);
          setChangeLog(() => ({
            ...changedData.changeTemp,
          }));
        }
      }
      //update PAN when GSTN changes.
      else if (inputValue.target.name === "gstNumber") {
        let tempGstNum = changedData.formTemp.gstNumber.toUpperCase();
        let tempPAN = generatePanFromGst(tempGstNum);
        console.log({ tempPAN });
        changedData.formTemp.gstNumber = tempGstNum;
        changedData.changeTemp.gstNumber = tempGstNum;
        changedData.formTemp.panNumber = tempPAN;
        changedData.changeTemp.panNumber = tempPAN;
        setFormData(changedData.formTemp);
        setChangeLog(() => ({
          ...changedData.changeTemp,
        }));
      }
      //handle default
      else {
        setFormData(changedData.formTemp);
        setChangeLog(() => ({
          ...changedData.changeTemp,
        }));
      }
    }
    console.log({ formData, changeLog });
  };

  const handleSubmit = async (formInputs) => {
    try {
      // console.log({formData, formInputs});
      formInputs.preventDefault();

      /* Update or Create the Master - Client Group Database */
      let latestDoc;

      if (props.type !== "edit") {
        latestDoc = formData;
        let RandomID = Math.random().toString(36).substr(2, 3);
        let ownerGroupID = localStorage.getItem("ownerid");

        if (part === "Group") {
          latestDoc.parentCode = formData.code;
        }

        latestDoc.type = `${major}${part}`;
        latestDoc._id = `${major}${part}:${ownerGroupID}_${formData.code}_${RandomID}`;
        latestDoc.status = "Active";
        latestDoc.updateLogs = [];
        latestDoc.latestOrderHideTill = new Date().toISOString();
        latestDoc.latestPaymentHideTill = new Date().toISOString();
        latestDoc.updateLogs.unshift({
          updateTime: new Date().toISOString(),
          userID: localStorage.getItem("userid"),
          userCode: localStorage.getItem("usercode"),
          updates: `${part} with id: ${latestDoc._id} created`,
        });
      } else {
        latestDoc = await masterDB.get(formData._id);

        if (formData._rev !== latestDoc._rev) {
          alert(
            `Data recently updated by ${latestDoc.updateLogs[0].userCode} on ${latestDoc.updateLogs[0].updateTime}. Please sync your database & try again.`
          );
          return undefined;
        }

        if (part === "Group") {
          latestDoc.parentCode = formData.code;
        }
        latestDoc = formData;
        latestDoc.updateLogs.unshift({
          updateTime: new Date().toISOString(),
          userID: localStorage.getItem("userid"),
          userCode: localStorage.getItem("usercode"),
          updates: changeLog,
        });
      }

      console.log(latestDoc);
      //  save the form entry into the database
      await masterDB.put(latestDoc);

      /*Update the All Lists Database if new entry or code or category has changed*/
      if (!props.type || changeLog.code || changeLog.category) {
        //Get latest version of All Lists doc
        let latestAllLists = await masterDB.get(originalData.allLists._id);

        //if group code is changed, delete the old value from company list
        if (props.type === "edit" && (changeLog.code || changeLog.category)) {
          // console.log("Inside AllLists editor - Edit is true.");
          switch (originalData.category) {
            case "Buyer":
              latestAllLists[`b${short}List`] = latestAllLists[
                `b${short}List`
              ].filter((record) => record !== originalData[`code`]);
              break;
            case "Seller":
              latestAllLists[`s${short}List`] = latestAllLists[
                `s${short}List`
              ].filter((record) => record !== originalData[`code`]);
              break;
            case "Both - Buyer & Seller":
              latestAllLists[`bs${short}List`] = latestAllLists[
                `bs${short}List`
              ].filter((record) => record !== originalData[`code`]);
              break;
          }
        }

        //Add or Update the Group details in respective list
        if (props.type !== "edit" || changeLog.code || changeLog.category) {
          // console.log("Inside AllLists editor - New Client Group");
          switch (formData.category) {
            case "Buyer":
              latestAllLists[`b${short}List`] =
                latestAllLists[`b${short}List`] || [];
              latestAllLists[`b${short}List`].push(formData.code);
              break;
            case "Seller":
              latestAllLists[`s${short}List`] =
                latestAllLists[`s${short}List`] || [];
              latestAllLists[`s${short}List`].push(formData.code);
              break;
            case "Both - Buyer & Seller":
              latestAllLists[`bs${short}List`] =
                latestAllLists[`bs${short}List`] || [];
              latestAllLists[`bs${short}List`].push(formData.code);
              break;
            //Default for Agent Groups & companies which have no category defined.
            default:
              latestAllLists[`${short}List`] =
                latestAllLists[`${short}List`] || [];
              latestAllLists[`${short}List`].push(formData.code);
          }

          await masterDB.put(latestAllLists);
        }
      }
      console.log("Client Group Record Created.");

      //Refresh the form.
      if (major == "agent") {
        navigate("/sub-agents-list");
      } else {
        navigate("/my-clients-list");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleDelete = async () => {
    let clientDoc = await masterDB.get(formData._id);
    let latestAllListDoc = await masterDB.get(originalData.allLists._id);
    let itemIndexToRemoved;
    switch (clientDoc.category) {
      case "Buyer":
        itemIndexToRemoved = latestAllListDoc[`b${short}List`].indexOf(
          formData.code
        );
        latestAllListDoc[`b${short}List`].splice(itemIndexToRemoved, 1);
        break;
      case "Seller":
        itemIndexToRemoved = latestAllListDoc[`s${short}List`].indexOf(
          formData.code
        );
        latestAllListDoc[`s${short}List`].splice(itemIndexToRemoved, 1);

        break;
      case "Both - Buyer & Seller":
        itemIndexToRemoved = latestAllListDoc[`bs${short}List`].indexOf(
          formData.code
        );
        latestAllListDoc[`bs${short}List`].splice(itemIndexToRemoved, 1);
        break;
      //Default for Agent Groups & companies which have no category defined.
      default:
        itemIndexToRemoved = latestAllListDoc[`${short}List`].indexOf(
          formData.code
        );
        latestAllListDoc[`${short}List`].splice(itemIndexToRemoved, 1);
    }
    console.log("=====latesetDoc after removing name ======");
    console.log(latestAllListDoc);
    if (confirm("Are you sure you want to delete client Company?")) {
      // Save it!
      clientDoc.status = "Deleted";
      var result = await masterDB.put(clientDoc);
      await masterDB.put(latestAllListDoc);
      console.log("======result====");
      console.log(result);
      if (major == "agent") {
        navigate("/sub-agents-list");
      } else {
        navigate("/my-clients-list");
      }
      alert("Client Company Deleted");
    } else {
      // Do nothing!
    }
  };

  return (
    <Box>
      <Header background="brand" pad="small">
        <BackBox />
        <Box>
          <Text>{`${props.type ? "Edit" : "Add"} ${
            major[0].toUpperCase() + major.slice(1)
          } ${part[0].toUpperCase() + part.slice(1)}`}</Text>
        </Box>
        <NotificationsBox />
      </Header>
      <Box pad="small">
        <Form
          validate="change"
          onSubmit={handleSubmit}
          id="add-client-group"
        >
          <FormField
            name={`name`}
            label={`${major[0].toUpperCase() + major.slice(1)} ${
              part[0].toUpperCase() + part.slice(1)
            } Name*`}
            validate={[
              (name) => {
                if (!name) {
                  return `Please Enter ${
                    major[0].toUpperCase() + major.slice(1)
                  } ${part[0].toUpperCase() + part.slice(1)} name`;
                }

                return undefined;
              },
            ]}
          >
            <TextInput
              name={`name`}
              value={formData[`name`]}
              onChange={handleChange}
              required
            />
          </FormField>
          {major==="client" && 
            <FormField
              name="groupName"
              label="Group Name"
            >
              <Select
                name="groupName"
                options={originalData.groupOptionsDisplay || []}
                value={formData.groupName}
                onChange={selectedVal => {
                  let tempFormData = formData;
                  let regex= /^Create:*/
                  console.log(regex.test(selectedVal.target.value));
                  let finalValue = selectedVal.target.value;
                  if(regex.test(selectedVal.target.value)){
                    finalValue = selectedVal.target.value.slice(8);
                    
                    let tempOriginalData = originalData;
                    tempOriginalData.groupOptionsDisplay.pop();
                    tempOriginalData.groupOptionsDisplay.push(finalValue);
                    setOriginalData(tempOriginalData);
                  }
                  tempFormData.groupName = finalValue;
                  setFormData({...tempFormData});

                  
                }}
                onSearch={searchInput => {
                  console.log({originalData, searchInput});
                  let orgGroupOptions = originalData.groupOptions;
                  const len = orgGroupOptions.length;
                  // console.log(orgGroupOptions[len-1], orgGroupOptions[len-1] && orgGroupOptions[len-1].includes(searchInput));
                  if(orgGroupOptions[0] && orgGroupOptions[len-1].includes('Create')) {
                    orgGroupOptions.pop();
                  }
                  orgGroupOptions.push(`Create: ${searchInput}`);
                  // The line below escapes regular expression special characters:
                    // [ \ ^ $ . | ? * + ( )
                    const escapedText = searchInput.replace(
                      /[-\\^$*+?.()|[\]{}]/g,
                      "\\$&"
                  );
  
                  // Create the regular expression with modified value which
                  // handles escaping special characters. Without escaping special
                  // characters, errors will appear in the console
                  const exp = new RegExp(escapedText, "i");
                  let tempOptions = orgGroupOptions.filter(o => exp.test(o));
                  setOriginalData({
                    ...originalData,
                    groupOptionsDisplay: tempOptions
                  })
                }}
              />
            </FormField>
          }
          {major !== "agent" && (
            <Box>
              <FormField
                name={`category`}
                label={`${major[0].toUpperCase() + major.slice(1)} ${
                  part[0].toUpperCase() + part.slice(1)
                } Category*`}
                validate={[
                  (name) => {
                    if (!name) {
                      return `Please Select the Category`;
                    }

                    return undefined;
                  },
                ]}
              >
                <Select
                  name={`category`}
                  options={changeLog.formCategoryOptions}
                  value={formData.category}
                  onChange={handleChange}
                  required
                />
              </FormField>
              <FormField name="primarySubAgent" label="Primary Sub-Agent">
                <Select
                  name="primarySubAgent"
                  options={
                    originalData.allLists && originalData.allLists.sagList
                      ? originalData.allLists.sagList.concat(
                          originalData.allLists.sacList
                        )
                      : []
                  }
                  value={formData.primarySubAgent}
                  onChange={handleChange}
                />
              </FormField>
            </Box>
          )}
          <PhoneNumber
            label="Phone Number"
            name="phoneNumber"
            onChange={handleChange}
            isdCodeValue={formData.phoneNumber && formData.phoneNumber.isdCode}
            phoneNumberValue={
              formData.phoneNumber && formData.phoneNumber.phoneNumber
            }
          />
          <FormField name="referenceBy" label="Reference By">
            <TextInput
              name="referenceBy"
              value={formData.referenceBy}
              onChange={handleChange}
            />
          </FormField>
          <Box>
            <Text>Commission %</Text>

            <Box flex direction="row">
              <FormField name="commissionPercentage" width="xsmall">
                <TextInput
                  name="commissionPercentage"
                  type="number"
                  placeholder="%"
                  value={formData.commissionPercentage}
                  onChange={(inputName) => {
                    let tempFormData = formData;
                    tempFormData.commissionPercentage = Number(
                      Math.round(inputName.target.value * 100) / 100
                    );
                    setFormData({ ...tempFormData });
                  }}
                />
              </FormField>
              <Text margin="small">On</Text>
              <FormField name="commissionAmtOption" width="small">
                <Select
                  name="commissionAmtOption"
                  options={["Gross Amt", "Total Amt"]}
                  value={formData.commissionAmtOption}
                  onChange={handleChange}
                  size="small"
                />
              </FormField>
            </Box>
          </Box>

          <Box
            margin="small"
            direction="row"
            justify="center"
            onClick={() => setMoreDetails((moreDetails) => !moreDetails)}
          >
            <Text color="brand">Add More Details</Text>
            {!moreDetails && <FormDown color="brand" />}
            {moreDetails && <FormUp color="brand" />}
          </Box>
          {moreDetails && (
            <Box>
              <FormField
                name={`code`}
                label={`${major[0].toUpperCase() + major.slice(1)} ${
                  part[0].toUpperCase() + part.slice(1)
                } Code*`}
                validate={(fieldInput, formInputs) => {
                  return userCodeValidation(fieldInput, formInputs);
                }}
              >
                <TextInput
                  name={`code`}
                  value={formData[`code`]}
                  onChange={handleChange}
                  required
                />
              </FormField>
              <AmountField
                name="creditLimit"
                label="Credit Limit"
                currencyValue={
                  formData.creditLimit && formData.creditLimit.currency
                }
                numeralsValue={
                  formData.creditLimit && formData.creditLimit.amount
                }
                onChange={handleChange}
              />
              <FormField name="paymentTerms" label="Payment Terms">
                <Select
                  name="paymentTerms"
                  options={termsOptions}
                  value={formData.paymentTerms}
                  onChange={handleChange}
                />
              </FormField>
              <PaymentPeriod
                name="paymentPeriod"
                label="Payment Terms - Period"
                numeralsValue={
                  formData.paymentPeriod && formData.paymentPeriod.numerals
                }
                unitValue={
                  formData.paymentPeriod && formData.paymentPeriod.unit
                }
                onChange={handleChange}
              />
              <FormField name="email" label="Email">
                <EmailInput
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </FormField>

              {part === "Company" && (
                <Box>
                  <FormField
                    name={`gstNumber`}
                    label="GST Number"
                    validate={[
                      {
                        regexp:
                          /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                      },
                    ]}
                  >
                    <GstnInput
                      name={`gstNumber`}
                      value={formData.gstNumber}
                      onChange={handleChange}
                    />
                  </FormField>
                  <FormField
                    name={`panNumber`}
                    label="PAN Number"
                    validate={[
                      {
                        regexp: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/gm,
                      },
                    ]}
                  >
                    <PanInput
                      name={`panNumber`}
                      value={formData.panNumber}
                      onChange={handleChange}
                    />
                  </FormField>
                </Box>
              )}
              <Address
                name="addressDetails"
                countryValue={
                  formData.addressDetails && formData.addressDetails.country
                }
                pinCodeValue={
                  formData.addressDetails && formData.addressDetails.pinCode
                }
                cityValue={
                  formData.addressDetails && formData.addressDetails.city
                }
                addressValue={
                  formData.addressDetails && formData.addressDetails.address
                }
                stateValue={
                  formData.addressDetails && formData.addressDetails.state
                }
                onChange={handleChange}
                onBlur={(inputVal) => {
                  console.log({ inputVal });
                  let tempFormData = formData;
                  if(tempFormData.address){
                    const data = pincodeDirectory.lookup(
                      inputVal.target.value
                    )[0];
                    tempFormData.addressDetails.state = data
                      ? data.stateName
                      : "";
                    tempFormData.addressDetails.city = data
                      ? data.districtName
                      : "";
                    setFormData({ ...tempFormData });
                  }
                }}
              />
              <BankDetails
                name={`bankDetails.bank1`}
                onChange={handleChange}
                nameValue={
                  formData.bankDetails && formData.bankDetails.bank1.name
                }
                branchValue={
                  formData.bankDetails && formData.bankDetails.bank1.branch
                }
                ifscCodeValue={
                  formData.bankDetails && formData.bankDetails.bank1.ifscCode
                }
                accountNumberValue={
                  formData.bankDetails &&
                  formData.bankDetails.bank1.accountNumber
                }
              />
              <FormField name="notes" label="Notes">
                <TextArea
                  name="notes"
                  value={formData.value}
                  onChange={handleChange}
                />
              </FormField>
            </Box>
          )}
        </Form>
      </Box>
      {props.type === "edit" && props.major !== "agent" ? (
        <Box margin="medium">
          <Box direction="row" justify="center" onClick={handleDelete}>
            <Trash color="#BE2625" />
            <Text color="#BE2625">&nbsp; Delete Company</Text>
          </Box>
        </Box>
      ) : null}
      <Button
        primary
        position="fixed"
        fill="horizontal"
        label="Save"
        bottom="0"
        type="submit"
        form="add-client-group"
      />
    </Box>
  );
};

export default AddCompany;
