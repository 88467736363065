import styled from 'styled-components';
import {Box, Text} from 'grommet';

export const OfflineBox = styled(Box)`
  height: 2rem;
  overflow: hidden;
`;

export const Emoji = styled(Text)`
  margin-right: 5px;
`;