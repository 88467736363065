import React from 'react'
import { Box, Spinner, Text } from "grommet"

export const LoadingBox = () => {

    return (
        <Box margin="auto">
            <Spinner />
            <Text>Loading...</Text>
        </Box>
    )
} 