import React, { useEffect, useState } from "react";
import {
  Anchor,
  Box,
  Button,
  CheckBoxGroup,
  Header,
  InfiniteScroll,
  Text,
} from "grommet";
import {
  BackBox,
  HeaderFilter,
  NavBox,
  NotificationsBox,
  SyncButton,
} from "../../components/Header/Header";
import NavFooter from "../../components/navFooter/navFooter";
import getMasterDB from "../../database/getMasterDB";
import { navigate } from "@reach/router";

const NotificationPage = () => {
  const [masterDB, setMasterDB] = useState();
  const [allNotification, setAllNotification] = useState();
  const [notificationDB, setNotificationDB] = useState();
  let today = new Date();
  let oneMonth = new Date().setDate(today.getDate() - 30);
  oneMonth = new Date(oneMonth).toISOString();
  useEffect(async () => {
    if (!masterDB) {
      let tempMasterDB = await getMasterDB();
      setMasterDB(tempMasterDB);
    }
    if (masterDB && !allNotification) {
      let tempAllNotification = await masterDB.find({
        selector: {
          type: {
            $eq: "notification",
          },
        },
      });

      tempAllNotification = tempAllNotification.docs[0];

      let filterNotification = [];
      tempAllNotification.notification.map((data) => {
        if (data.timestamp >= oneMonth) {
          filterNotification.push(data);
        }
      });
      // console.log(filterNotification);
      setNotificationDB(tempAllNotification);
      setAllNotification(filterNotification);
    }
  }, [masterDB]);
  const navigationFunc = (invoiceId) => {
    navigate(`/invoice/edit-invoice/`, { state: { invoiceId: invoiceId } });
  };
  const lastActionUpdate = async () => {
    let now = new Date().toISOString();
    if (notificationDB) {
      notificationDB.actionTaken = notificationDB.actionTaken || [];
      notificationDB.actionTaken.unshift(now);
      await masterDB.put(notificationDB);
    }
  };
  return (
    <Box>
      <Header background="brand" pad="small">
        <BackBox />
        <Box align="center">
          <Text>Notification</Text>
        </Box>
        <SyncButton />
      </Header>
      {allNotification && (
        <InfiniteScroll items={allNotification}>
          {(notifData, i) => {
            return (
              <Box key={i} margin="small" elevation="medium" pad="medium">
                <Box flex direction="row">
                  <Text>{notifData.statusIcon}</Text>
                  <Text size="medium" weight="bold">
                    {new Date(notifData.timestamp).toLocaleDateString("en-IN", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                    })}
                  </Text>
                  {notifData.invoiceID ? (
                    <Box
                      background="brand"
                      alignSelf="end"
                      justify="end"
                      round="small"
                      margin={{ left: "large" }}
                    >
                      <Button
                        margin="xsmall"
                        color="white"
                        onClick={() => {
                          navigationFunc(notifData.invoiceID);

                          lastActionUpdate();
                        }}
                      >
                        View Invoice
                      </Button>
                    </Box>
                  ) : (
                    ""
                  )}
                </Box>
                <Text size="small" margin={{ top: "xsmall" }}>
                  {notifData.data}
                </Text>
              </Box>
            );
          }}
        </InfiniteScroll>
      )}
      <NavFooter />
    </Box>
  );
};

export default NotificationPage;
