import getMasterDB from "./getMasterDB";

const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const getWithTypeOnly = async (masterDB, type) => {
  let data;
  // console.log("Inside Type Only", {masterDB, type});

  for (let i = 0; i <= 10; i++) {
    await wait(i * 2000);

    data = await masterDB.find({
      selector: {
        type: { $eq: type },
      },
    });

    data = data.docs && data.docs[0];
    // console.log({i, data});
    if (data) {
      break;
    }
  }
  return data;
};

const getWithTypeAndCode = async (masterDB, type, code) => {
  let data;

  // console.log("Inside Type & Code", {type, code});

  for (let i = 0; i <= 10; i++) {
    await wait(i * 2000);
    if (type === "order") {
      data = await masterDB.find({
        selector: {
          type: { $eq: type },
          orderNumber: { $eq: code },
        },
      });
    } else if (type === "invoice") {
      data = await masterDB.find({
        selector: {
          type: { $eq: type },
          invoiceNumber: { $eq: code },
        },
      });
    } else {
      data = await masterDB.find({
        selector: {
          type: { $eq: type },
          code: { $eq: code },
        },
      });
    }

    data = data.docs && data.docs[0];
    // console.log({i, data});
    if (data) {
      break;
    }
  }
  return data;
};

const getRecords = async (input) => {
  //Inputs accepted example --> {db: masterDB, allList:true, type:"clientGroup", code:props.userCode}

  // console.log("Getting Records", { input });
  let records = {};
  if (input.allLists) {
    records.allLists = await getWithTypeOnly(input.db, "allLists");
  }
  // console.log("Passed All lists", {records});

  if (input.type && input.code && input.code != "none") {
    records.data = await getWithTypeAndCode(input.db, input.type, input.code);
  } else if (input.type) {
    console.log("Inside Only Type");
    records.data = await getWithTypeOnly(input.db, input.type);
    console.log({ records });
  }
  // console.log("Fetched Data", {records});

  return records;
};

export default getRecords;
